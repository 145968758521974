export const StreamChartData = [
  {
    "Theressa Webb": 176,
    "Ronalds Rechards": 132,
    "Bessie Cooper": 109,
    "Brroklys Symons": 119,
    "Savannah Nguyan": 157,
    "Floyad Mines": 80,
  },
  {
    "Theressa Webb": 155,
    "Ronalds Rechards": 56,
    "Bessie Cooper": 91,
    "Brroklys Symons": 45,
    "Savannah Nguyan": 59,
    "Floyad Mines": 147,
  },
  {
    "Theressa Webb": 116,
    "Ronalds Rechards": 114,
    "Bessie Cooper": 176,
    "Brroklys Symons": 12,
    "Savannah Nguyan": 53,
    "Floyad Mines": 127,
  },
  {
    "Theressa Webb": 135,
    "Ronalds Rechards": 196,
    "Bessie Cooper": 131,
    "Brroklys Symons": 125,
    "Savannah Nguyan": 107,
    "Floyad Mines": 57,
  },
  {
    "Theressa Webb": 66,
    "Ronalds Rechards": 62,
    "Bessie Cooper": 153,
    "Brroklys Symons": 135,
    "Savannah Nguyan": 158,
    "Floyad Mines": 140,
  },
  {
    "Theressa Webb": 60,
    "Ronalds Rechards": 113,
    "Bessie Cooper": 140,
    "Brroklys Symons": 88,
    "Savannah Nguyan": 167,
    "Floyad Mines": 111,
  },
  {
    "Theressa Webb": 0,
    "Ronalds Rechards": 107,
    "Bessie Cooper": 142,
    "Brroklys Symons": 78,
    "Savannah Nguyan": 162,
    "Floyad Mines": 199,
  },
  {
    "Theressa Webb": 161,
    "Ronalds Rechards": 157,
    "Bessie Cooper": 101,
    "Brroklys Symons": 124,
    "Savannah Nguyan": 118,
    "Floyad Mines": 158,
  },
  {
    "Theressa Webb": 128,
    "Ronalds Rechards": 147,
    "Bessie Cooper": 60,
    "Brroklys Symons": 165,
    "Savannah Nguyan": 71,
    "Floyad Mines": 89,
  },
]

export const StreamChartParameters = {
  keys: [
    "Theressa Webb",
    "Ronalds Rechards",
    "Bessie Cooper",
    "Brroklys Symons",
    "Savannah Nguyan",
    "Floyad Mines",
  ],
  margin: {
    top: 50,
    right: 30,
    bottom: 50,
    left: 30,
  },
  axisTop: "",
  axisRight: "",
  axisBottom: {
    orient: "bottom",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "",
    legendOffset: 36,
    truncateTickAt: 0,
  },
  axisLeft: {
    orient: "left",
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legend: "",
    legendOffset: -40,
    truncateTickAt: 0,
    tickTextColor: "#ffffff",
  },
  enableGridX: "true",
  enableGridY: "false",
  offsetType: "silhouette",
  colors: {
    scheme: "set3",
  },
  fillOpacity: [0.85],
  borderColor: {
    theme: "background",
  },
  defs: [
    {
      id: "dots",
      type: "patternDots",
      background: "inherit",
      color: "#2c998f",
      size: 4,
      padding: 2,
      stagger: true,
    },
    {
      id: "squares",
      type: "patternSquares",
      background: "inherit",
      color: "#e4c912",
      size: 6,
      padding: 2,
      stagger: true,
    },
  ],
  fill: [
    {
      match: {
        id: "Eggs",
      },
      id: "dots",
    },
    {
      match: {
        id: "Carrots",
      },
      id: "squares",
    },
  ],
  dotSize: [8],
  dotColor: {
    from: "color",
  },
  dotBorderWidth: [2],
  dotBorderColor: {
    from: "color",
    modifiers: [["darker", 0.7]],
  },
  legends: [
    {
      anchor: "bottom",
      direction: "row",
      translateY: 50,
      itemWidth: 80,
      itemHeight: 20,
      itemTextColor: "#999999",
      symbolSize: 12,
      symbolShape: "circle",
      effects: [
        {
          on: "hover",
          style: {
            itemTextColor: "#fff",
          },
        },
      ],
    },
  ],
}
