import { ResponsiveBar } from "@nivo/bar"
import { useTheme } from "../../../../../contexts/theme"
import CustomTooltip from "../../../../CustomTooltip"
import VerifiedIcon from "@mui/icons-material/Verified"
import NewReleasesIcon from "@mui/icons-material/NewReleases"
import Box from "@mui/material/Box"
import { colorPalette, days } from "../../constant/fallbackConfigs"

const BodySection = ({ data = [], config }) => {
  const { theme } = useTheme()
  const {
    keys,
    indexBy,
    margin,
    padding,
    borderRadius,
    axisTop,
    colors,
    axisRight,
    axisBottom,
    axisLeft,
    enableLabel,
    chartContainerDimensions = {
      height: "350px",
    },
    layout,
    performerFlag = { icon: true, legend: true },
    groupMode,
    chartValue,
    colorFlag,
    showTooltipPrevious,
  } = config
  const itemTextColor = theme.palette.mode === "dark" ? "#fff" : "#333333"
  let stackedKeys = []

  if (groupMode === "stacked") {
    let finalIndexBy = [...new Set(data?.map((item) => item[indexBy]))]
    stackedKeys = [...new Set(data?.map((item) => item[keys]))]
    let finalOut = []
    finalIndexBy.forEach((element) => {
      const output = { [indexBy]: element }
      data?.forEach((data) => {
        if (data[indexBy] === element) {
          output[data[keys]] = data[chartValue]
          output.tooltipFields = output.tooltipFields || []
          output.tooltipFields.push(data.tooltipData)
        }
      })

      finalOut.push(output)
    })

    data = finalOut
  }

  const customTheme = {
    text: {
      fill: itemTextColor,
    },
    tooltip: {
      container: {
        background: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
      },
    },
    grid: {
      line: {
        stroke: "rgba(29, 71, 206, 0.3)",
        strokeWidth: 0.93,
        top: 359.59,
      },
    },
    axis: {
      domain: {
        line: {
          stroke: "rgba(29, 71, 206, 0.3)",
          strokeWidth: 1,
        },
      },
      ticks: {
        text: {
          fontSize: 10,
          fill: itemTextColor,
          outlineWidth: 0,
          outlineColor: "transparent",
        },
      },
    },
  }

  const ids = [...data].map((item) => item[indexBy])

  let sorteddata = [...data]

  days.forEach((day) => {
    if (ids.includes(day)) {
      sorteddata = [...data].sort((a, b) => {
        const dayAIndex = days.indexOf(a[indexBy])
        const dayBIndex = days.indexOf(b[indexBy])

        return dayBIndex - dayAIndex
      })
    }
  })

  const getColor = (data) => {
    const isDarkMode = theme.palette.mode === "dark"

    if (colorFlag === "single") {
      return isDarkMode ? "#A4D0E9" : "#bebada"
    } else {
      return colorPalette[data.index % colorPalette.length]
    }
  }

  const HighestPoint = ({ bars, yScale, xScale }) => {
    if (!bars) return null
    const highestValue = Math.max(
      ...data?.map(
        (d) =>
          d["Quantity"] ||
          d["Gross Sales"] ||
          d["Product Discount"] ||
          d["TotalCustomers"] ||
          d["Margin"]
      )
    )
    const highestBar = bars.find((bar) => bar.data.value === highestValue)

    if (!highestBar) return null

    let x = 0
    let y = 0

    if (!!layout && layout === "vertical") {
      x = highestBar.x + highestBar.width / 2
      y = yScale(highestValue)
    } else if (!!layout && layout === "horizontal") {
      x = xScale(highestValue)
      y = highestBar.y + highestBar.height / 2
    } else return

    const colors = {
      dark: {
        fill: "#23BF6F",
        stroke: "#FFFFFF",
      },
      light: {
        fill: "#FFFFFF",
        stroke: "#23BF6F",
      },
    }

    return (
      performerFlag.icon && (
        <>
          <circle cx={x} cy={y} r={12} fill="#FFFFFF" />
          <VerifiedIcon
            height={24}
            width={24}
            x={x - 12}
            y={y - 12}
            sx={{ fill: colors.dark.fill }}
          />
        </>
      )
    )
  }

  const LowestPoint = ({ bars, yScale, xScale }) => {
    if (!bars) return null
    const lowestValue = Math.min(
      ...data?.map(
        (d) =>
          d["Quantity"] ||
          d["Gross Sales"] ||
          d["Product Discount"] ||
          d["TotalCustomers"] ||
          d["Margin"]
      )
    )
    const lowestBar = bars.find((bar) => bar.data.value === lowestValue)

    if (!lowestBar) return null

    let x = 0
    let y = 0

    if (!!layout && layout === "vertical") {
      x = lowestBar.x + lowestBar.width / 2
      y = yScale(lowestValue)
    } else if (!!layout && layout === "horizontal") {
      x = xScale(lowestValue)
      y = lowestBar.y + lowestBar.height / 2
    } else return

    const colors = {
      dark: {
        fill: "#EC2D30",
        stroke: "#FFFFFF",
      },
      light: {
        fill: "#FFFFFF",
        stroke: "#EC2D30",
      },
    }

    return (
      performerFlag.icon && (
        <>
          <circle cx={x} cy={y} r={12} fill="#FFFFFF" />
          <NewReleasesIcon
            height={24}
            width={24}
            x={x - 12}
            y={y - 12}
            sx={{ fill: colors.dark.fill }}
          />
        </>
      )
    )
  }

  return (
    <Box sx={{ ...chartContainerDimensions }}>
      <ResponsiveBar
        data={sorteddata}
        animate={false}
        motionConfig="none"
        theme={customTheme}
        keys={groupMode === "stacked" ? stackedKeys : keys}
        indexBy={indexBy}
        margin={margin}
        padding={padding}
        borderRadius={borderRadius}
        enableLabel={enableLabel}
        colors={groupMode === "stacked" ? colors : getColor}
        axisTop={axisTop}
        axisRight={axisRight}
        axisBottom={axisBottom}
        axisLeft={axisLeft}
        layout={layout ?? "vertical"}
        layers={["grid", "axes", "bars", LowestPoint, HighestPoint]}
        groupMode={groupMode}
        tooltip={(point) => {
          const isStacked = groupMode === "stacked"
          const tooltipData = isStacked
            ? (() => {
              const Id = point?.id
              const dataArray = point?.data?.tooltipFields || []

              return dataArray.find((item) =>
                Object.values(item?.current || {}).some(
                  (field) => field?.value === Id
                )
              )
            })()
            : point.data

          const CustomtooltipData =
            isStacked && tooltipData
              ? {
                tooltipData: {
                  current: tooltipData.current,
                  previous: tooltipData.previous,
                },
              }
              : tooltipData

          return (
            <CustomTooltip
              data={CustomtooltipData}
              showCurrentPrevDate={true}
              showTooltipPrevious={showTooltipPrevious}
            />
          )
        }}
      />
    </Box>
  )
}

export default BodySection
