import { ResponsiveFunnel } from "@nivo/funnel"
import Box from "@mui/material/Box"
import { useEffect, useRef, useState } from "react"
import { useTheme } from "../../../../../contexts/theme"
import CustomTooltip from "../../../../CustomTooltip"
import { useOrdinalColorScale } from "@nivo/colors"

const BodySection = ({ data = [{}], config, showTooltipPrevious }) => {
  const { theme } = useTheme()
  const chartContainerRef = useRef(null)
  const [chartWidth, setChartWidth] = useState(0)

  useEffect(() => {
    if (chartContainerRef.current) {
      setChartWidth(chartContainerRef.current.offsetWidth)
    }
  }, [chartContainerRef])

  const {
    chartContainerDimensions = {
      height: "600px",
    },
  } = config

  const itemTextColor = theme.palette.mode === "dark" ? "#fff" : "#333333"

  const PartLabel = ({ part }) => {
    const fixedHorizontalOffset = 30
    const maxLabelPosition = chartWidth - 210

    const labelXOffset = Math.max(
      0,
      Math.min(
        maxLabelPosition - part.width - 2 * fixedHorizontalOffset,
        fixedHorizontalOffset
      )
    )

    const verticalMargin = 10

    return (
      <g
        transform={`translate(${part.x + labelXOffset + part.width / 2}, ${part.y + part.height / 2 - verticalMargin
          })`}
      >
        <text
          textAnchor="start"
          dominantBaseline="middle"
          style={{
            fill: itemTextColor,
            pointerEvents: "none",
            fontSize: "16px",
          }}
        >
          {part.data.id}
        </text>
      </g>
    )
  }

  const Labels = (props) => {
    return props.parts.map((part) => (
      <PartLabel key={part.data.id} part={part} chartWidth={chartWidth} />
    ))
  }
  const getColor = useOrdinalColorScale({ scheme: "set3" }, "id")
  return (
    <Box sx={{ ...chartContainerDimensions }}>
      <ResponsiveFunnel
        animate={false}
        motionConfig="none"
        data={data}
        margin={{ top: 20, right: 150, bottom: 20, left: 80 }}
        spacing={0}
        valueFormat=">-.4r"
        layers={["separators", "parts", Labels, "annotations"]}
        colors={getColor}
        borderWidth={20}
        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
        labelColor={{ from: "color", modifiers: [["darker", 3]] }}
        enableBeforeSeparators={false}
        beforeSeparatorLength={100}
        beforeSeparatorOffset={20}
        enableAfterSeparators={false}
        afterSeparatorLength={100}
        afterSeparatorOffset={20}
        currentPartSizeExtension={10}
        currentBorderWidth={40}
        tooltip={(point) => (
          <CustomTooltip
            data={point?.part?.data || {}}
            showCurrentPrevDate={true}
            showTooltipPrevious={showTooltipPrevious}
          />
        )}
      />
    </Box>
  )
}

export default BodySection
