import { useState } from "react"
import { useNavigate } from "react-router-dom"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import CustomPaper from "../../components/CustomPaper.jsx"
import { useTheme } from "../../contexts/theme.jsx"
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs.jsx"
import ArrowRise from "../../assets/svgs/ArrowRise.jsx"
import Table from "../../components/datagrid/Table.jsx"
import {
  PastCampaignData,
  PastCampaignParameters,
} from "../../constants/chartsData.js"
import Grid from "@mui/material/Grid"
import InfoToolTip from "../../components/InfoToolTip.jsx"
import InfoLogoSvg from "../../assets/svgs/InfoLogoSvg.jsx"
import {
  BarChartData,
  BarChartParameters,
} from "../../components/charts/bar/constant/fallbackData.js"
import BarChartContainer from "../../components/charts/bar/BarChartContainer.jsx"

const CampaignCustomerDetails = () => {
  const navigate = useNavigate()
  const { theme } = useTheme()
  const ThemeMode = theme.palette.mode

  const items = [
    "Pizza Margherita",
    "Chicken Caesar Salad",
    "Cheeseburger",
    "Spaghetti Balognesa",
    "Vegetable Pad Thai",
  ]

  const renderDetails = (details) =>
    Object.entries(details).map(([key, value]) => (
      <Stack
        key={key}
        rowGap="5px"
        minWidth={{
          sx: "200px",
          sm: "200px",
          md: "200px",
          lg: "280px",
        }}
      >
        <Typography
          variant="body2"
          color="inherit"
          sx={{
            opacity: "70%",
            fontSize: "0.85rem",
          }}
        >
          {value.label}
        </Typography>
        <Typography variant="body1" sx={{ fontSize: "1.1rem" }}>
          {value.value}
        </Typography>
      </Stack>
    ))

  const PersonalDetails = {
    title: {
      label: "Name",
      value: "Dianne Russll",
    },
    PhoneNumber: {
      label: "Phone Number",
      value: "(307) 555-0133",
    },
    EmailAddress: {
      label: "Email Address",
      value: "diannerussll@example.com",
    },
    streetAddress: {
      label: "Street Address",
      value: "123 Main Street",
    },
    Apartment: {
      label: "Apartment or Suite Number ",
      value: "Apt 4B",
    },
    City: {
      label: "City",
      value: "Anytown",
    },
    state: {
      label: "State",
      value: "New York",
    },
    zipcode: {
      label: "ZIP Code",
      value: "12345",
    },
    lastvisit: {
      label: "Last Visited on",
      value: "16th Oct 2024",
    },
    totalItem: {
      label: "Total Number if Items bought",
      value: "242",
    },
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        padding="30px 30px 15px 30px"
      >
        <CustomBreadcrumbs
          breadcrumbItems={[
            {
              text: "Marketing Portal",
              clickHandler: () => {
                navigate("/submenu/marketing")
              },
            },
            {
              text: "Customer Details",
              isActive: true,
            },
          ]}
        />
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        padding="15px 30px 15px 30px"
      >
        <Typography variant="h6" color="inherit">
          Customer Details
        </Typography>
      </Box>

      <Stack padding="15px 30px 15px 30px" rowGap="20px">
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <CustomPaper customStyles={{ height: "100%" }}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                flexWrap="wrap"
                borderBottom="2px solid #091743"
                mb="15px"
              >
                <Typography mb="10px" variant="body1" color="inherit">
                  Personal Information
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                gap="25px"
              >
                {renderDetails(PersonalDetails)}
              </Box>
            </CustomPaper>
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomPaper
              customStyles={{
                backgroundColor: "transparent",
                border:
                  ThemeMode === "dark"
                    ? "1px solid rgba(13, 34, 102, 1)"
                    : "none",
              }}
            >
              <Box>
                <Typography
                  component="div"
                  variant="subtitle2"
                  sx={{
                    color:
                      ThemeMode === "dark"
                        ? "rgba(179, 179, 179, 1)"
                        : " rgba(115, 115, 115, 1)",
                    textAlign: "left",
                  }}
                >
                  Over all Rating
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Typography
                    component="div"
                    variant="h4"
                    sx={{
                      color:
                        ThemeMode === "dark"
                          ? "rgba(255, 255, 255, 1)"
                          : " rgba(0, 0, 0, 1)",
                      textAlign: "left",
                    }}
                  >
                    <strong>4.3</strong>
                  </Typography>
                  <svg
                    width="28"
                    height="26"
                    viewBox="0 0 28 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      marginTop: "5px",
                    }}
                  >
                    <path
                      d="M13.0489 0.927047C13.3483 0.00573683 14.6517 0.00573993 14.9511 0.927051L17.3677 8.36475C17.5016 8.77677 17.8855 9.05573 18.3188 9.05573H26.1392C27.1079 9.05573 27.5107 10.2953 26.727 10.8647L20.4001 15.4615C20.0496 15.7161 19.903 16.1675 20.0369 16.5795L22.4535 24.0172C22.7529 24.9385 21.6984 25.7047 20.9147 25.1353L14.5878 20.5385C14.2373 20.2839 13.7627 20.2839 13.4122 20.5385L7.08533 25.1353C6.30162 25.7047 5.24714 24.9385 5.54649 24.0172L7.96315 16.5795C8.09702 16.1675 7.95036 15.7161 7.59987 15.4615L1.27299 10.8647C0.489277 10.2953 0.892056 9.05573 1.86078 9.05573H9.68123C10.1145 9.05573 10.4984 8.77677 10.6323 8.36475L13.0489 0.927047Z"
                      fill="#F2CD4A"
                    />
                  </svg>
                </Box>
              </Box>
              <Box sx={{ width: "24vw", marginTop: "1rem" }}>
                <Typography
                  component="div"
                  variant="subtitle2"
                  sx={{
                    color:
                      ThemeMode === "dark"
                        ? "rgba(179, 179, 179, 1)"
                        : " rgba(115, 115, 115, 1)",
                    textAlign: "left",
                  }}
                >
                  Amount Spent
                </Typography>
                <Typography
                  component="div"
                  variant="h4"
                  sx={{
                    color: " rgba(80, 205, 137, 1)",
                    textAlign: "left",
                  }}
                >
                  <strong>$3,0546.43</strong>
                  <span
                    style={{
                      marginLeft: "10px",
                      fontSize: "18px",
                      color:
                        ThemeMode === "dark"
                          ? "rgba(255, 255, 255, 1)"
                          : " rgba(0, 0, 0, 1)",
                    }}
                  >
                    +11.01%
                  </span>{" "}
                  <ArrowRise />
                </Typography>
              </Box>
              <Box sx={{ width: "24vw", marginTop: "1rem" }}>
                <Typography
                  component="div"
                  variant="subtitle2"
                  sx={{
                    color:
                      ThemeMode === "dark"
                        ? "rgba(179, 179, 179, 1)"
                        : " rgba(115, 115, 115, 1)",
                    textAlign: "left",
                  }}
                >
                  Top 5 bought item
                </Typography>

                <ul
                  style={{
                    margin: "4px 0 0 0",
                    paddingLeft: "12px",
                    listStyleType: "none",
                  }}
                >
                  {items.map((item, index) => (
                    <li key={index}>
                      {index + 1}. {item}
                    </li>
                  ))}
                </ul>
              </Box>
              <Box>
                <Typography
                  component="div"
                  variant="subtitle2"
                  sx={{
                    color:
                      ThemeMode === "dark"
                        ? "rgba(179, 179, 179, 1)"
                        : " rgba(115, 115, 115, 1)",

                    textAlign: "left",
                    mt: "1rem",
                  }}
                >
                  Review
                </Typography>
                <Typography
                  component="div"
                  variant="h6"
                  sx={{
                    color:
                      ThemeMode === "dark"
                        ? "rgba(255, 255, 255, 1)"
                        : " rgba(0, 0, 0, 1)",
                    textAlign: "left",
                    mt: "0.3rem",
                  }}
                >
                  "The Service was impeccable. The staff was attentive, friendly
                  , and made us feel welcome."
                </Typography>
              </Box>
            </CustomPaper>
          </Grid>
        </Grid>

        <Table
          title="Past Campaign"
          showExportButton={false}
          StaticData={PastCampaignData}
          StaticParameters={PastCampaignParameters}
        />

        <CustomPaper>
          <Box display="flex" alignItems="center">
            <Typography color="inherit">Spend Trend</Typography>
            <InfoToolTip title="Spend Trend">
              <span style={{ height: "16px", cursor: "pointer" }}>
                <InfoLogoSvg svgColor={theme.palette.accentSecondary} />
              </span>
            </InfoToolTip>
          </Box>

          <BarChartContainer
            chartInput={BarChartData}
            parameters={BarChartParameters}
          />
        </CustomPaper>
      </Stack>
    </>
  )
}

export default CampaignCustomerDetails
