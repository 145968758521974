import { memo, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchData } from "./services/dataService"
import {
  transformChartData,
  transformDataPDFExport,
  transformDataSheetExport,
} from "./services/transformService"

import Stack from "@mui/material/Stack"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import { generateCacheKey } from "../../../utils/utils"

import HeaderSection from "./components/widget/HeaderSection"
import MacroMetricsSection from "./components/widget/MacroMetricsSection"
import BodySection from "./components/widget/BodySection"
import FooterSection from "./components/widget/FooterSection"
import Message from "../../Message"
import {
  calculateDaysInRange,
  getDefaultOption,
  rangeOptions,
} from "../../viewBy_re_re/utils"
import { getlegendsData } from "./services/configService"
import {
  chartContainerDimentionsFallbackConfig,
  macroMetricsFallbackConfig,
  PDFFieldsFallbackConfig,
  tooltipDataFallbackConfig,
  tooltipDataFallbackConfigCustomers,
} from "./constant/fallbackConfigs"
import { BarChartData, staticApiResponse } from "./constant/fallbackData"

const BarChartContainer = memo(
  ({
    title,
    description,
    route,
    setChartData,
    postUrl,
    reportUid,
    configurations,
    extApiResponse,
    extLoading = false,
    extError = null,
  }) => {
    const dispatch = useDispatch()
    const chartRef = useRef(null)

    const dateRangeData = useSelector((state) => state.calendar)
    const comparisonPeriod = useSelector((state) => state.period.selectedValue)
    const isComparedBy = comparisonPeriod.selectedValue === "Compared By"
    let transformedConfigs = extractKeyValuePairs(configurations)

    let reportState =
      useSelector((state) => {
        return state[route]?.reports?.find(
          (report) => report.report_uid === reportUid
        )
      }) || {}

    const cacheKey = generateCacheKey(
      reportUid,
      dateRangeData.fromDate,
      dateRangeData.toDate,
      comparisonPeriod.previousFromDate,
      comparisonPeriod.previousToDate,
      reportState.viewBy
    )

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const configOverrides = [
      {
        key: "chartContainerDimensions",
        fallback: chartContainerDimentionsFallbackConfig,
      },

      {
        key: "tooltip_data",
        fallback: (() => {
          switch (reportUid) {
            case "16B1F146-AE15-4622-8D58-FEEF9B8AEEF0":
              return tooltipDataFallbackConfigCustomers
            default:
              return tooltipDataFallbackConfig
          }
        })(),
      },
      { key: "macro-metrics", fallback: macroMetricsFallbackConfig(reportUid) },
      { key: "pdfFields", fallback: PDFFieldsFallbackConfig },
      {
        key: "showTooltipPrevious",
        fallback:
          transformedConfigs.showTooltipPrevious === undefined
            ? true
            : transformedConfigs.showTooltipPrevious,
      },
      {
        key: "colorFlag",
        fallback:
          {
            "D0EEA0A1-9DB6-4C50-9C4A-78E684E243D8": "multi",
          }[reportUid] || "single",
      },
    ]

    configOverrides.forEach(({ key, fallback }) => {
      transformedConfigs[key] = transformedConfigs[key] || fallback
    })

    const daysInRange = dateRangeData
      ? calculateDaysInRange(dateRangeData.fromDate, dateRangeData.toDate)
      : 0

    const visibleViewByOptions =
      rangeOptions.find(
        (range) => daysInRange >= range.min && daysInRange <= range.max
      )?.options || []

    const defaultViewByOption = getDefaultOption(daysInRange)

    const viewBy = visibleViewByOptions.includes(reportState.viewBy)
      ? reportState.viewBy
      : defaultViewByOption

    useEffect(() => {
      if (["static", "external"].includes(reportUid)) return
      if (reportState.cacheKey === cacheKey) return
      const payload = {
        entityDb: localStorage.getItem("entityDb"),
        fromDate: dateRangeData.fromDate,
        toDate: dateRangeData.toDate,
        reportInstanceId: reportUid,
        comparedBy: {
          previousFromDate: comparisonPeriod.previousFromDate,
          previousToDate: comparisonPeriod.previousToDate,
        },
      }
      if (!!transformedConfigs.view_by_period) {
        payload.viewBy = viewBy
      }
      setLoading(true)
      fetchData(postUrl, payload)
        .then((apiResponse) => {
          dispatch(
            setChartData({
              reportUid,
              response: apiResponse,
              apiResponse,
              transformedChartData: transformChartData(
                apiResponse,
                isComparedBy,
                transformedConfigs
              ),
              viewBy,
              cacheKey,
            })
          )
          setLoading(false)
        })
        .catch((err) => {
          console.log("BarChartContainer API Error", err)
          setError("Something went wrong while fetching the data...")
          setLoading(false)
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      dateRangeData.fromDate,
      dateRangeData.toDate,
      comparisonPeriod.previousFromDate,
      comparisonPeriod.previousToDate,
      reportState.cacheKey,
      reportState.viewBy,
    ])

    if (reportUid === "static") {
      reportState.apiResponse = staticApiResponse
      reportState.transformedChartData = BarChartData
    }

    if (reportUid === "external") {
      reportState.apiResponse = extApiResponse
      reportState.transformedChartData = transformChartData(
        extApiResponse,
        true,
        transformedConfigs
      )
    }

    return (
      <Stack gap={1} ref={chartRef}>
        <HeaderSection
          route={route}
          reportUid={reportUid}
          title={title}
          description={description}
          setChartData={setChartData}
          children={null}
          config={transformedConfigs}
          reportStateViewBy={reportState.viewBy}
          dateRangeData={dateRangeData}
          parentLoading={loading}
          parentError={error}
          transformedChartData={reportState.transformedChartData}
          apiResponse={reportState.apiResponse}
          chartRef={chartRef}
          pdfFields={transformedConfigs}
          pdfTransformFunction={transformDataPDFExport}
          transformDataSheetExport={transformDataSheetExport}
          visibleViewByOptions={visibleViewByOptions}
          defaultViewByOption={defaultViewByOption}
        />

        {extLoading || loading ? (
          <Message type="loading" />
        ) : extError || error ? (
          <Message message={extError || error} type="error" />
        ) : (
          <>
            {reportUid === "static" ? (
              ""
            ) : (
              <MacroMetricsSection
                config={transformedConfigs["macro-metrics"]}
                data={
                  comparisonPeriod.selectedValue === "Compared By"
                    ? reportState?.apiResponse
                    : reportState?.apiResponse?.reportDataCurrent
                }
                reportUid={reportUid}
              />
            )}
            <BodySection
              config={transformedConfigs}
              data={reportState.transformedChartData}
            />
            {reportUid === "static" ? (
              ""
            ) : (
              <FooterSection
                legendsData={getlegendsData(
                  comparisonPeriod.selectedValue === "Compared By"
                    ? reportState?.apiResponse
                    : reportState?.apiResponse?.reportDataCurrent
                )}
                title={title}
                apiResponse={reportState.apiResponse || []}
              />
            )}
          </>
        )}
      </Stack>
    )
  }
)

export default BarChartContainer
