import React, { useState } from "react"

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import FormControl from "@mui/material/FormControl"
import OutlinedInput from "@mui/material/OutlinedInput"
import Typography from "@mui/material/Typography"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import { useTheme } from "../../contexts/theme"

const Filter = () => {
  const { theme } = useTheme()

  const [amountSpent, setAmountSpent] = useState({
    minValue: "",
    maxValue: "",
  })
  const handleAmountSpentChange = (e) => {
    const { name, value } = e.target
    setAmountSpent({ ...amountSpent, [name]: value })
  }

  const [recipientType, setRecipientType] = useState([
    { name: "all", checked: false },
    { name: "email", checked: false },
    { name: "sms", checked: false },
  ])
  const handleRecipientTypeChange = (recipient) => {
    setRecipientType((prevState) =>
      prevState.map((r) =>
        r.name === recipient.name ? { ...r, checked: !r.checked } : r
      )
    )
  }
  const [expanded, setExpanded] = useState({
    panel1: true,
    panel2: true,
    panel3: true,
  })

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded({ ...expanded, [panel]: isExpanded })
  }
  const [customers, setCustomers] = useState([
    { name: "all", checked: false },
    { name: "topCustomers", checked: false },
    { name: "lowCustomers", checked: false },
    { name: "maxValue", checked: false },
    { name: "lowValue", checked: false },
    { name: "mostViewed", checked: false },
    { name: "lowViewed", checked: false },
  ])
  const handleCustomerChange = (customer) => {
    setCustomers((prevState) =>
      prevState.map((c) =>
        c.name === customer.name ? { ...c, checked: !c.checked } : c
      )
    )
  }

  const handleClearAll = () => {
    setAmountSpent({
      minValue: "",
      maxValue: "",
    })
    setRecipientType([
      { name: "all", checked: false },
      { name: "email", checked: false },
      { name: "sms", checked: false },
    ])
    setCustomers([
      { name: "all", checked: false },
      { name: "topCustomers", checked: false },
      { name: "lowCustomers", checked: false },
      { name: "maxValue", checked: false },
      { name: "lowValue", checked: false },
      { name: "mostViewed", checked: false },
      { name: "lowViewed", checked: false },
    ])
  }

  return (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        padding="0px 20px 20px 20px"
        justifyContent="space-between"
      >
        <Typography color="inherit">Filter</Typography>
        <Button
          variant="text"
          onClick={handleClearAll}
          sx={{
            textTransform: "none",
            color: theme.palette.accent,
            p: 0,
          }}
        >
          Clear All
        </Button>
      </Box>
      <Typography padding="0px 20px 20px 20px" color="inherit">
        20 of 100 results
      </Typography>
      {/* Amount Spent */}
      <Accordion
        expanded={expanded.panel1}
        onChange={handleAccordionChange("panel1")}
        sx={{
          background: "unset",
          borderTop: 1,
          boxShadow: "unset",
          borderColor: "#1C45C9",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "#A4B5E9" }} />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography>Amount Spent</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" justifyContent="space-between" gap={1}>
            <FormControl>
              <Typography htmlFor="minValue">
                Min Value{" "}
                <span
                  style={{
                    color: "#EE000C",
                  }}
                >
                  *
                </span>
              </Typography>
              <OutlinedInput
                id="minValue"
                name="minValue"
                value={amountSpent.minValue}
                onChange={handleAmountSpentChange}
                placeholder="Enter Values"
                sx={{ border: "1px solid #1A44CB", borderRadius: "10px" }}
              />
            </FormControl>
            <FormControl>
              <Typography htmlFor="maxValue">
                Max Value{" "}
                <span
                  style={{
                    color: "#EE000C",
                  }}
                >
                  *
                </span>
              </Typography>
              <OutlinedInput
                id="maxValue"
                name="maxValue"
                value={amountSpent.maxValue}
                onChange={handleAmountSpentChange}
                placeholder="Enter Values"
                sx={{ border: "1px solid #1A44CB", borderRadius: "10px" }}
              />
            </FormControl>
          </Box>
        </AccordionDetails>
      </Accordion>
      {/* Recipient Type */}
      <Accordion
        expanded={expanded.panel2}
        onChange={handleAccordionChange("panel2")}
        sx={{
          background: "unset",
          borderTop: 1,
          boxShadow: "unset",
          borderColor: "#1C45C9",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "#A4B5E9" }} />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <Typography>Recipient Type</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {recipientType.map((recipient, index) => (
            <Button
              key={index}
              style={{
                border: `1px solid ${
                  recipient.checked ? theme.palette.accent : "#6382E9"
                }`,
                padding: "6px 20px",
                margin: "5px",
                borderRadius: "10px",
                backgroundColor: "background.paper",
                color: "inherit",
              }}
              onClick={() => handleRecipientTypeChange(recipient)}
              startIcon={
                recipient.checked ? (
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5 10C20.5 15.5228 16.0228 20 10.5 20C4.97714 20 0.5 15.5228 0.5 10C0.5 4.47714 4.97714 0 10.5 0C16.0228 0 20.5 4.47714 20.5 10ZM9.34327 15.2949L16.7626 7.87556C17.0146 7.62363 17.0146 7.21512 16.7626 6.96318L15.8503 6.0508C15.5983 5.79883 15.1898 5.79883 14.9378 6.0508L8.8871 12.1015L6.06213 9.27656C5.8102 9.0246 5.40169 9.0246 5.14972 9.27656L4.23734 10.189C3.9854 10.4409 3.9854 10.8494 4.23734 11.1013L8.43089 15.2949C8.68286 15.5468 9.09131 15.5468 9.34327 15.2949Z"
                      fill={theme.palette.accent}
                    />
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.2"
                      d="M20 10C20 15.5228 15.5228 20 10 20C4.47714 20 0 15.5228 0 10C0 4.47714 4.47714 0 10 0C15.5228 0 20 4.47714 20 10ZM8.84327 15.2949L16.2626 7.87556C16.5146 7.62363 16.5146 7.21512 16.2626 6.96318L15.3503 6.0508C15.0983 5.79883 14.6898 5.79883 14.4378 6.0508L8.3871 12.1015L5.56213 9.27656C5.3102 9.0246 4.90169 9.0246 4.64972 9.27656L3.73734 10.189C3.4854 10.4409 3.4854 10.8494 3.73734 11.1013L7.93089 15.2949C8.18286 15.5468 8.59131 15.5468 8.84327 15.2949Z"
                      fill={theme.palette.accent}
                    />
                  </svg>
                )
              }
            >
              <Typography
                sx={{ fontSize: "0.8rem", paddingLeft: "2px" }}
                color="inherit"
              >
                {recipient.name}
              </Typography>
            </Button>
          ))}
        </AccordionDetails>
      </Accordion>
      {/* Customers */}
      <Accordion
        expanded={expanded.panel3}
        onChange={handleAccordionChange("panel3")}
        sx={{
          background: "unset",
          borderTop: 1,
          boxShadow: "unset",
          borderColor: "#1C45C9",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "#A4B5E9" }} />}
          aria-controls="panel3-content"
          id="panel3-header"
        >
          <Typography>Customers</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {customers.map((customer, index) => (
            <Button
              key={index}
              style={{
                border: `1px solid ${
                  customer.checked ? theme.palette.accent : "#6382E9"
                  // 1px #6382E9 solid
                }`,
                padding: "6px 20px",
                margin: "5px",
                borderRadius: "10px",
                backgroundColor: "background.paper",
                color: "inherit",
              }}
              onClick={() => handleCustomerChange(customer)}
              startIcon={
                customer.checked ? (
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5 10C20.5 15.5228 16.0228 20 10.5 20C4.97714 20 0.5 15.5228 0.5 10C0.5 4.47714 4.97714 0 10.5 0C16.0228 0 20.5 4.47714 20.5 10ZM9.34327 15.2949L16.7626 7.87556C17.0146 7.62363 17.0146 7.21512 16.7626 6.96318L15.8503 6.0508C15.5983 5.79883 15.1898 5.79883 14.9378 6.0508L8.8871 12.1015L6.06213 9.27656C5.8102 9.0246 5.40169 9.0246 5.14972 9.27656L4.23734 10.189C3.9854 10.4409 3.9854 10.8494 4.23734 11.1013L8.43089 15.2949C8.68286 15.5468 9.09131 15.5468 9.34327 15.2949Z"
                      fill={theme.palette.accent}
                    />
                  </svg>
                ) : (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.2"
                      d="M20 10C20 15.5228 15.5228 20 10 20C4.47714 20 0 15.5228 0 10C0 4.47714 4.47714 0 10 0C15.5228 0 20 4.47714 20 10ZM8.84327 15.2949L16.2626 7.87556C16.5146 7.62363 16.5146 7.21512 16.2626 6.96318L15.3503 6.0508C15.0983 5.79883 14.6898 5.79883 14.4378 6.0508L8.3871 12.1015L5.56213 9.27656C5.3102 9.0246 4.90169 9.0246 4.64972 9.27656L3.73734 10.189C3.4854 10.4409 3.4854 10.8494 3.73734 11.1013L7.93089 15.2949C8.18286 15.5468 8.59131 15.5468 8.84327 15.2949Z"
                      fill={theme.palette.accent}
                    />
                  </svg>
                )
              }
            >
              <Typography
                sx={{ fontSize: "0.8rem", paddingLeft: "2px" }}
                color="inherit"
              >
                {customer.name}
              </Typography>
            </Button>
          ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default Filter
