export const tooltipDataFallbackConfig = [
  {
    name: "value",
    format: "Currency",
    color: "",
  },
  {
    name: "Customers",
    format: "Number",
    color: "",
  },
  {
    name: "Transactions",
    format: "Number",
    color: "",
  },
  {
    name: "Returns",
    format: "Currency",
    color: "",
  },
  {
    name: "Discounts",
    format: "Currency",
    color: "",
  },
]
export const tooltipDataFallbackConfigInventoryDetails = [
  {
    name: "Total Units Sold",
    format: "Currency",
    color: "",
  },
  {
    name: "Total Revenue",
    format: "Currency",
    color: "",
  },
  {
    name: "Total Profit",
    format: "Currency",
    color: "",
  },
  {
    name: "Total Discount",
    format: "Currency",
    color: "",
  },
  {
    name: "Return Quantity",
    format: "Number",
    color: "",
  },
]
export const tooltipDataFallbackConfigCustomerDetails = [
  {
    name: "Total Amount",
    format: "Currency",
    color: "",
  },
  {
    name: "Total Transactions",
    format: "Number",
    color: "",
  },
  {
    name: "Return Amount",
    format: "Currency",
    color: "",
  },
  {
    name: "Order Discount",
    format: "Currency",
    color: "",
  },
]

export const tooltipDataFallbackConfigCustmers = [
  {
    name: "Customer",
    format: "Number",
    color: "",
  },
  {
    name: "Transactions",
    format: "Number",
    color: "",
  },
  {
    name: "Returns",
    format: "Currency",
    color: "",
  },
  {
    name: "Discounts",
    format: "Currency",
    color: "",
  },
]
export const PDFFieldsFallbackConfig = [
  {
    name: "value",
    format: "Currency",
    color: "",
  },
  {
    name: "Customers",
    format: "Number",
    color: "",
  },
  {
    name: "Transactions",
    format: "Number",
    color: "",
  },
  {
    name: "Returns",
    format: "Currency",
    color: "",
  },
  {
    name: "Discounts",
    format: "Currency",
    color: "",
  },
]

export const chartContainerDimensionsFallBack = {
  height: "600px",
  minHeight: "350px",
  maxHeight: "unset",
}

export const defaultColor = [
  "#fbe4d4", // Light peach
  "#e8c1a0", // Original peach
  "#f49b81", // Light coral
  "#f47560", // Original coral
  "#d65a4b", // Dark coral
]
export const defaultColorCustomers = [
  "#e0f7ff",
  "#b3eaff",
  "#4dceff",
  "#1abfff",
  "#0099cc",
]

export const getResolvedDefaultColor = (reportUid) => {
  return reportUid === "E45C3D13-0FE7-43E6-8C96-A13346DB66AE"
    ? defaultColorCustomers
    : defaultColor
}

export const macroMetricsFallbackConfig = (reportUid, route) => [
  ["16B1F146-AE15-4622-8D58-FEEF9B8AEEF0"].includes(reportUid)
    ? null
    : {
        name: "highest",
        helpText: "Highest datapoint",
        firstRow: { value: "", operation: "aggregate" },
        secondRow: { value: "{day}", operation: "templateString" },
        thirdRow: { value: "Star Performer", operation: null },
        aggregateFunc: "MAX",
        targetField: "value",
        format: route === "customerDetails" ? "Number" : "Currency",
        color: "#23BF6F",
        icon: "VerifiedIcon",
      },
  {
    name: "lowest",
    helpText: "Lowest datapoint",
    firstRow: { value: "", operation: "aggregate" },
    secondRow: { value: "{day}", operation: "templateString" },
    thirdRow: { value: "Low Performer", operation: null },
    aggregateFunc: "MIN",
    targetField: "value",
    format: route === "customerDetails" ? "Number" : "Currency",
    color: "#EC2D30",
    icon: "NewReleasesIcon",
  },
]
