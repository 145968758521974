import dayjs from "dayjs"

const calculatePreviousPeriod = (fromDate, toDate) => {
  if (!fromDate || !toDate) {
    return null
  }

  const from = dayjs(fromDate, "YYYYMMDD")
  const to = dayjs(toDate, "YYYYMMDD")

  if (!from.isValid() || !to.isValid() || to.isBefore(from)) {
    return null
  }

  // Check if the range spans almost the entire year
  const isFullYear =
    from.month() === 0 &&
    from.date() === 1 && // Starts on Jan 1
    to.month() === 11

  if (isFullYear) {
    // Calculate the same range for the previous year
    const prevFrom = from.subtract(1, "year").format("YYYYMMDD")
    const prevTo = to.subtract(1, "year").format("YYYYMMDD")

    return {
      selectedValue: "Previous Period",
      previousFromDate: prevFrom,
      previousToDate: prevTo,
    }
  }

  // Check if the range spans multiple months
  const isFullQuarter =
    from.date() === 1 &&
    to.date() === to.daysInMonth() &&
    to.diff(from, "month") === 2

  if (isFullQuarter) {
    // Handle full quarter ranges
    const prevFrom = from
      .subtract(3, "month")
      .startOf("month")
      .format("YYYYMMDD")
    const prevTo = to.subtract(3, "month").endOf("month").format("YYYYMMDD")

    return {
      selectedValue: "Previous Period",
      previousFromDate: prevFrom,
      previousToDate: prevTo,
    }
  }

  const isFullMonth = from.date() === 1 && to.date() === to.daysInMonth()

  if (isFullMonth) {
    // Handle full month ranges
    const prevMonthStart = from
      .subtract(1, "month")
      .startOf("month")
      .format("YYYYMMDD")
    const prevMonthEnd = to
      .subtract(1, "month")
      .endOf("month")
      .format("YYYYMMDD")

    return {
      selectedValue: "Previous Period",
      previousFromDate: prevMonthStart,
      previousToDate: prevMonthEnd,
    }
  }

  // Handle partial ranges
  const prevFrom = from.subtract(1, "month").format("YYYYMMDD")
  const prevTo = to.subtract(1, "month").format("YYYYMMDD")

  return {
    selectedValue: "Previous Period",
    previousFromDate: prevFrom,
    previousToDate: prevTo,
  }
}

const calculatePreviousYear = (fromDate, toDate) => {
  if (fromDate && toDate) {
    // Parse the fromDate and toDate strings into dayjs objects
    const from = dayjs(fromDate, "YYYYMMDD")
    const to = dayjs(toDate, "YYYYMMDD")

    if (!from.isValid() || !to.isValid()) {
      return null
    }

    const prevFrom = from.subtract(1, "year")
    const prevTo = to.subtract(1, "year")

    // Handle leap year case (e.g., Feb 29 to Feb 28/29)
    if (prevFrom.date() !== from.date()) {
      prevFrom.date(from.date())
    }
    if (prevTo.date() !== to.date()) {
      prevTo.date(to.date())
    }

    // Ensure that fromDate is not after toDate
    if (prevFrom.isAfter(prevTo)) {
      return null
    }

    return {
      selectedValue: "Previous Year",
      previousFromDate: prevFrom.format("YYYYMMDD"),
      previousToDate: prevTo.format("YYYYMMDD"),
    }
  } else {
    return null
  }
}

const formatDate = (date, format = getDateFormatFromLocalStorage()) => {
  let dayjsDate = dayjs(date)

  if (!dayjsDate.isValid()) {
    dayjsDate = dayjs(date.getTime())
  }

  return dayjsDate.format(format)
}

const getDateFormatFromLocalStorage = () => {
  return localStorage.getItem("dateFormat") || "MM-DD-YYYY"
}

export {
  calculatePreviousPeriod,
  calculatePreviousYear,
  formatDate,
  getDateFormatFromLocalStorage,
}
