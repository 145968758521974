import { useState, useEffect, useRef, memo } from "react"
import { useSelector, useDispatch } from "react-redux"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import { Stack } from "@mui/material"
import { fetchData } from "./services/dataServices"
import {
  transformDataComparedBy,
  transfromChartData,
  LegendData,
  transformDataPDFExport,
  transformDataSheetExport,
} from "./services/transformServices"
import { generateCacheKey } from "../../../utils/utils"
import BodySection from "./components/widget/BodySection"
import FooterSection from "./components/widget/FooterSection"
import HeaderSection from "./components/widget/HeaderSection"
import {
  chartContainerDimentionsFallbackConfig,
  tooltipDataFallbackConfig,
  tooltipDataFallbackConfigCustomerPage,
} from "./constant/fallbackConfigs"
import Message from "../../Message"
import {
  calculateDaysInRange,
  getDefaultOption,
  rangeOptions,
} from "../../viewBy_re_re/utils"
import { PieapiResponse } from "./constant/dataFallback"

const PieChartContainer = memo(
  ({
    title,
    description,
    route,
    setChartData,
    postUrl,
    reportUid,
    configurations,
    chartConfig,
    chartInput,
  }) => {
    const dispatch = useDispatch()
    const chartRef = useRef(null)

    const dateRangeData = useSelector((state) => state.calendar)
    const comparisonPeriod = useSelector((state) => state.period.selectedValue)
    let transformedConfigs = extractKeyValuePairs(configurations) || {}

    let reportState =
      useSelector((state) => {
        return state[route]?.reports?.find(
          (report) => report.report_uid === reportUid
        )
      }) || {}

    const cacheKey = generateCacheKey(
      reportUid,
      dateRangeData.fromDate,
      dateRangeData.toDate,
      comparisonPeriod.previousFromDate,
      comparisonPeriod.previousToDate,
      reportState.viewBy
    )

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const configOverrides = [
      {
        key: "chartContainerDimensions",
        fallback: chartContainerDimentionsFallbackConfig,
      },
      { key: "tooltip_data", fallback: tooltipDataFallbackConfig },
      {
        key: "pdfFields",
        fallback:
          transformedConfigs?.tooltip_data ||
          tooltipDataFallbackConfigCustomerPage(reportUid),
      },
      {
        key: "showPrevious",
        fallback:
          transformedConfigs.showPrevious === undefined
            ? true
            : transformedConfigs.showPrevious,
      },
    ]

    configOverrides.forEach(({ key, fallback }) => {
      transformedConfigs[key] = transformedConfigs[key] || fallback
    })

    const daysInRange = dateRangeData
      ? calculateDaysInRange(dateRangeData.fromDate, dateRangeData.toDate)
      : 0

    const visibleViewByOptions =
      rangeOptions.find(
        (range) => daysInRange >= range.min && daysInRange <= range.max
      )?.options || []

    const defaultViewByOption = getDefaultOption(daysInRange)

    const viewBy = visibleViewByOptions.includes(reportState.viewBy)
      ? reportState.viewBy
      : defaultViewByOption

    useEffect(() => {
      if (reportState.cacheKey === cacheKey) return
      if (reportUid === "static") return
      const payload = {
        entityDb: localStorage.getItem("entityDb"),
        fromDate: dateRangeData.fromDate,
        toDate: dateRangeData.toDate,
        reportInstanceId: reportUid,
        comparedBy: {
          previousFromDate: comparisonPeriod.previousFromDate,
          previousToDate: comparisonPeriod.previousToDate,
        },
      }
      if (!!transformedConfigs.view_by_period) {
        payload.viewBy = viewBy
      }
      setLoading(true)
      fetchData(postUrl, payload)
        .then((apiResponse) => {
          dispatch(
            setChartData({
              reportUid,
              response: apiResponse,
              apiResponse,
              transformedChartData:
                comparisonPeriod === true
                  ? transformDataComparedBy(
                      apiResponse,
                      transformedConfigs.tooltip_data
                    )
                  : transfromChartData(
                      apiResponse,
                      transformedConfigs.tooltip_data
                    ),
              viewBy,
              cacheKey,
            })
          )
          setLoading(false)
        })
        .catch((err) => {
          console.warn(err)
          setError("Failed to fetch data")
          setLoading(false)
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      dateRangeData.fromDate,
      dateRangeData.toDate,
      comparisonPeriod.previousFromDate,
      comparisonPeriod.previousToDate,
      reportState.cacheKey,
      reportState.viewBy,
    ])

    if (reportUid === "static") {
      reportState.apiResponse = PieapiResponse
      reportState.transformedChartData = chartInput
      transformedConfigs = chartConfig
    }

    return (
      <Stack spacing={1} ref={chartRef}>
        <HeaderSection
          route={route}
          reportUid={reportUid}
          title={title}
          description={description}
          setChartData={setChartData}
          children={null}
          config={transformedConfigs}
          reportStateViewBy={reportState.viewBy}
          dateRangeData={dateRangeData}
          parentLoading={loading}
          parentError={error}
          transformedChartData={reportState.transformedChartData}
          apiResponse={reportState.apiResponse}
          chartRef={chartRef}
          pdfFields={transformedConfigs.pdfFields}
          pdfTransformFunction={transformDataPDFExport}
          transformDataSheetExport={transformDataSheetExport}
          visibleViewByOptions={visibleViewByOptions}
          defaultViewByOption={defaultViewByOption}
        />

        {loading ? (
          <Message type="loading" />
        ) : error ? (
          <Message message={error} type="error" />
        ) : (
          <>
            <BodySection
              config={transformedConfigs}
              data={reportState.transformedChartData}
              TableLegendData={reportState.apiResponse}
              reportUid={reportUid}
            />
            <FooterSection
              legendsData={LegendData(reportState)}
              title={title}
              apiResponse={reportState.apiResponse}
            />
          </>
        )}
      </Stack>
    )
  }
)

export default PieChartContainer
