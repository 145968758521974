import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Rating from "@mui/material/Rating"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import {
  SentimentReviewsList,
  SentimentReviewsParameters,
} from "../../constants/chartsData"
import DatePicker from "../../components/DatePicker"
import RatingCard from "./customerSenti/RatingCard"
import { useSelector } from "react-redux"
import Table from "../../components/datagrid/Table"
import CustomPaper from "../../components/CustomPaper"
import { useTheme } from "../../contexts/theme"
import CompareBy from "../../components/CompareBy"
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs"
import { useNavigate } from "react-router-dom"
import GoogleInsights from "./customerSenti/GoogleInsight"
import YelpInsight from "./customerSenti/YelpInsight"

const CustomerSentiment = () => {
  const navigate = useNavigate()
  const { theme } = useTheme()
  const dateRangeData = useSelector((state) => state.calendar)

  return (
    <>
      <Box padding="30px 30px 15px 30px">
        <CustomBreadcrumbs
          breadcrumbItems={[
            {
              text: "All Customers",
              clickHandler: () => {
                navigate("/customers/allcustomers")
              },
            },
            {
              text: "Customers Sentiments Dashboard",
              isActive: true,
            },
          ]}
        />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          paddingY="15px"
        >
          <Typography component="div" variant="subtitle1">
            Sentiments Dashboard
          </Typography>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            maxWidth="auto"
            gap={2}
          >
            <CompareBy dateRangeData={dateRangeData} />
            <DatePicker />
          </Box>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6} sx={{ height: "100%" }}>
            <GoogleInsights />
          </Grid>
          <Grid item xs={12} md={6} sx={{ height: "100%" }}>
            <YelpInsight />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default CustomerSentiment