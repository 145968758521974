import { useTheme } from "../../contexts/theme"

const GoogleLogo = () => {
  const { theme } = useTheme()
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100px"
      height="50px"
      viewBox="0 0 272 119"
      enable-background="new 0 0 272 119"
    >
      <path
        fill={theme.palette.mode === "dark" ? "#000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M183.000000,120.000000 
	C122.000015,120.000000 61.500031,120.000000 1.000032,120.000000 
	C1.000022,80.333359 1.000022,40.666714 1.000011,1.000054 
	C91.666626,1.000036 182.333252,1.000036 272.999908,1.000018 
	C272.999939,40.666611 272.999939,80.333221 272.999969,119.999916 
	C243.166672,120.000000 213.333328,120.000000 183.000000,120.000000 
M189.449173,84.354187 
	C189.499924,84.545013 189.550690,84.735840 189.357391,85.538879 
	C191.667465,86.092636 193.977554,86.646385 196.475784,87.245247 
	C196.763641,85.613037 196.971954,84.979546 196.973022,84.345711 
	C196.998901,69.059212 197.018417,53.772621 196.967117,38.486290 
	C196.964340,37.659206 196.335037,36.834225 195.494965,35.832893 
	C195.320541,35.865238 195.146133,35.897587 194.264236,35.721962 
	C192.661804,35.721962 191.059372,35.721962 189.604004,35.721962 
	C189.604004,52.068779 189.604004,67.925591 189.449173,84.354187 
M41.656246,34.194813 
	C30.309940,36.972706 22.841885,43.855492 20.070398,55.276947 
	C17.586193,65.514503 21.320957,73.966843 28.730556,80.875374 
	C37.157097,88.732079 50.932671,90.091316 60.809322,84.366714 
	C69.760887,79.178314 73.801750,69.455559 71.416374,58.514404 
	C63.417709,58.514404 55.374645,58.514404 47.372414,58.514404 
	C47.372414,61.096916 47.372414,63.313576 47.372414,66.078766 
	C53.163425,66.078766 58.548008,66.078766 65.168076,66.078766 
	C63.434696,69.418243 62.570347,72.150124 60.865841,74.174652 
	C54.874493,81.290855 42.906891,82.215729 35.514500,76.413811 
	C27.274576,69.946724 25.368181,58.842068 30.984646,50.027660 
	C36.175823,41.880688 47.859692,38.608128 55.999916,43.827286 
	C60.631775,46.797031 62.783020,45.320045 64.930099,41.437523 
	C60.285210,35.363518 51.747971,32.573421 41.656246,34.194813 
M181.253052,96.637344 
	C182.321793,92.328522 184.050583,88.060005 184.316711,83.702171 
	C184.813431,75.568680 184.480423,67.383224 184.453354,59.219521 
	C184.436874,54.248955 182.857483,53.092014 178.040405,54.177017 
	C176.611679,54.498825 174.903152,54.852524 173.628525,54.375256 
	C166.008163,51.521935 158.857025,53.433517 154.112076,59.858383 
	C149.242477,66.452065 149.465500,75.560989 154.645477,81.643387 
	C159.911697,87.827057 167.355652,89.248825 174.846466,85.533493 
	C175.336731,85.290329 175.941406,85.277855 176.868286,85.076523 
	C176.704849,89.719048 175.819580,93.561279 171.611603,95.460022 
	C167.956009,97.109489 163.867371,96.778595 161.502274,93.634048 
	C158.192886,89.233994 155.630768,91.090363 152.544205,93.213753 
	C157.403931,104.988098 171.837555,106.923187 181.253052,96.637344 
M200.328903,68.790756 
	C200.387833,78.741676 204.546478,84.992004 212.484543,87.060318 
	C220.466644,89.140114 227.435333,86.504669 231.422684,79.667702 
	C229.687973,78.548630 227.941925,77.422249 226.346130,76.392792 
	C218.238174,82.417290 212.789963,82.115883 210.078735,75.387291 
	C217.316315,72.303009 224.571014,69.211433 231.758408,66.148537 
	C229.366913,57.080975 223.594803,52.637287 215.193878,53.061237 
	C207.396912,53.454708 201.851852,59.033813 200.328903,68.790756 
M112.695801,68.868942 
	C113.956711,73.063797 114.315575,77.874481 116.669838,81.322891 
	C121.303513,88.110092 131.348206,89.613480 138.734070,85.670151 
	C145.804153,81.895409 149.216812,73.249977 146.880478,65.032478 
	C144.718872,57.429440 137.586639,52.710003 128.829971,53.088348 
	C120.417564,53.451817 114.299690,59.186741 112.695801,68.868942 
M74.484390,69.623398 
	C74.845901,71.742935 74.948685,73.947929 75.615372,75.966675 
	C78.024544,83.261681 83.422752,86.940643 90.855499,87.702507 
	C97.862572,88.420738 104.506004,84.646683 107.769051,78.380112 
	C111.001091,72.173080 110.262733,64.536537 105.709267,59.175125 
	C100.915146,53.530350 94.593040,51.767357 87.646759,53.533726 
	C79.937393,55.494148 75.650940,60.928520 74.484390,69.623398 
z"
      />
      <path
        fill="#4989EB"
        opacity="1.000000"
        stroke="none"
        d="
M42.063400,34.135250 
	C51.747971,32.573421 60.285210,35.363518 64.930099,41.437523 
	C62.783020,45.320045 60.631775,46.797031 55.999916,43.827286 
	C47.859692,38.608128 36.175823,41.880688 30.984646,50.027660 
	C25.368181,58.842068 27.274576,69.946724 35.514500,76.413811 
	C42.906891,82.215729 54.874493,81.290855 60.865841,74.174652 
	C62.570347,72.150124 63.434696,69.418243 65.168076,66.078766 
	C58.548008,66.078766 53.163425,66.078766 47.372414,66.078766 
	C47.372414,63.313576 47.372414,61.096916 47.372414,58.514404 
	C55.374645,58.514404 63.417709,58.514404 71.416374,58.514404 
	C73.801750,69.455559 69.760887,79.178314 60.809322,84.366714 
	C50.932671,90.091316 37.157097,88.732079 28.730556,80.875374 
	C21.320957,73.966843 17.586193,65.514503 20.070398,55.276947 
	C22.841885,43.855492 30.309940,36.972706 42.063400,34.135250 
z"
      />
      <path
        fill="#4888EB"
        opacity="1.000000"
        stroke="none"
        d="
M181.053268,96.948166 
	C171.837555,106.923187 157.403931,104.988098 152.544205,93.213753 
	C155.630768,91.090363 158.192886,89.233994 161.502274,93.634048 
	C163.867371,96.778595 167.956009,97.109489 171.611603,95.460022 
	C175.819580,93.561279 176.704849,89.719048 176.868286,85.076523 
	C175.941406,85.277855 175.336731,85.290329 174.846466,85.533493 
	C167.355652,89.248825 159.911697,87.827057 154.645477,81.643387 
	C149.465500,75.560989 149.242477,66.452065 154.112076,59.858383 
	C158.857025,53.433517 166.008163,51.521935 173.628525,54.375256 
	C174.903152,54.852524 176.611679,54.498825 178.040405,54.177017 
	C182.857483,53.092014 184.436874,54.248955 184.453354,59.219521 
	C184.480423,67.383224 184.813431,75.568680 184.316711,83.702171 
	C184.050583,88.060005 182.321793,92.328522 181.053268,96.948166 
M177.008881,72.987015 
	C177.043823,71.666100 177.184296,70.337975 177.094711,69.025558 
	C176.774353,64.332710 173.629181,60.592400 169.310104,60.056435 
	C164.614014,59.473682 161.389786,61.718246 159.407898,65.887886 
	C156.864258,71.239418 159.182007,77.782661 164.437561,80.140633 
	C169.460709,82.394341 173.927353,80.120110 177.008881,72.987015 
z"
      />
      <path
        fill="#E34B3C"
        opacity="1.000000"
        stroke="none"
        d="
M200.363678,68.369141 
	C201.851852,59.033813 207.396912,53.454708 215.193878,53.061237 
	C223.594803,52.637287 229.366913,57.080975 231.758408,66.148537 
	C224.571014,69.211433 217.316315,72.303009 210.078735,75.387291 
	C212.789963,82.115883 218.238174,82.417290 226.346130,76.392792 
	C227.941925,77.422249 229.687973,78.548630 231.422684,79.667702 
	C227.435333,86.504669 220.466644,89.140114 212.484543,87.060318 
	C204.546478,84.992004 200.387833,78.741676 200.363678,68.369141 
M222.630676,62.511833 
	C219.643158,59.093719 216.105225,58.940365 212.413742,61.058372 
	C209.450348,62.758629 207.423019,65.126923 208.389984,69.386528 
	C211.728256,68.098755 214.795670,66.993240 217.795380,65.727364 
	C219.434509,65.035652 220.959122,64.072517 222.630676,62.511833 
z"
      />
      <path
        fill="#F3BC14"
        opacity="1.000000"
        stroke="none"
        d="
M112.766357,68.474632 
	C114.299690,59.186741 120.417564,53.451817 128.829971,53.088348 
	C137.586639,52.710003 144.718872,57.429440 146.880478,65.032478 
	C149.216812,73.249977 145.804153,81.895409 138.734070,85.670151 
	C131.348206,89.613480 121.303513,88.110092 116.669838,81.322891 
	C114.315575,77.874481 113.956711,73.063797 112.766357,68.474632 
M136.822235,62.615826 
	C136.290390,62.223755 135.798843,61.754723 135.220352,61.451702 
	C130.248657,58.847408 125.552475,59.735905 122.402321,63.843857 
	C119.550797,67.562393 119.854553,74.163589 123.037743,77.652771 
	C126.124840,81.036621 130.986710,81.834137 134.766052,79.576630 
	C139.159195,76.952477 141.097656,71.539421 139.285141,66.620110 
	C138.836975,65.403709 137.995667,64.332153 136.822235,62.615826 
z"
      />
      <path
        fill="#E34A3B"
        opacity="1.000000"
        stroke="none"
        d="
M74.504120,69.218155 
	C75.650940,60.928520 79.937393,55.494148 87.646759,53.533726 
	C94.593040,51.767357 100.915146,53.530350 105.709267,59.175125 
	C110.262733,64.536537 111.001091,72.173080 107.769051,78.380112 
	C104.506004,84.646683 97.862572,88.420738 90.855499,87.702507 
	C83.422752,86.940643 78.024544,83.261681 75.615372,75.966675 
	C74.948685,73.947929 74.845901,71.742935 74.504120,69.218155 
M95.505028,60.614639 
	C90.780571,58.932922 86.957626,60.387779 84.172867,64.302689 
	C81.526497,68.023026 81.494942,72.149338 83.835045,76.012253 
	C85.970726,79.537727 89.258934,81.375336 93.503532,80.715347 
	C97.918076,80.028931 100.749725,77.204346 101.653152,73.027733 
	C102.766998,67.878342 101.230354,63.507046 95.505028,60.614639 
z"
      />
      <path
        fill="#40AB5D"
        opacity="1.000000"
        stroke="none"
        d="
M195.997391,36.008263 
	C196.335037,36.834225 196.964340,37.659206 196.967117,38.486290 
	C197.018417,53.772621 196.998901,69.059212 196.973022,84.345711 
	C196.971954,84.979546 196.763641,85.613037 196.475784,87.245247 
	C193.977554,86.646385 191.667465,86.092636 189.703537,85.152634 
	C189.901123,84.438385 189.752563,84.110390 189.604004,83.782394 
	C189.604004,67.925591 189.604004,52.068779 189.604004,35.721962 
	C191.059372,35.721962 192.661804,35.721962 194.706207,35.998451 
	C195.431244,36.186050 195.714310,36.097160 195.997391,36.008263 
z"
      />
      <path
        fill="#22A245"
        opacity="1.000000"
        stroke="none"
        d="
M195.746185,35.920578 
	C195.714310,36.097160 195.431244,36.186050 195.059937,36.102440 
	C195.146133,35.897587 195.320541,35.865238 195.746185,35.920578 
z"
      />
      <path
        fill="#22A245"
        opacity="1.000000"
        stroke="none"
        d="
M189.526581,84.068291 
	C189.752563,84.110390 189.901123,84.438385 189.825577,84.846527 
	C189.550690,84.735840 189.499924,84.545013 189.526581,84.068291 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M176.947906,73.394470 
	C173.927353,80.120110 169.460709,82.394341 164.437561,80.140633 
	C159.182007,77.782661 156.864258,71.239418 159.407898,65.887886 
	C161.389786,61.718246 164.614014,59.473682 169.310104,60.056435 
	C173.629181,60.592400 176.774353,64.332710 177.094711,69.025558 
	C177.184296,70.337975 177.043823,71.666100 176.947906,73.394470 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M222.583023,62.871803 
	C220.959122,64.072517 219.434509,65.035652 217.795380,65.727364 
	C214.795670,66.993240 211.728256,68.098755 208.389984,69.386528 
	C207.423019,65.126923 209.450348,62.758629 212.413742,61.058372 
	C216.105225,58.940365 219.643158,59.093719 222.583023,62.871803 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M137.078644,62.904919 
	C137.995667,64.332153 138.836975,65.403709 139.285141,66.620110 
	C141.097656,71.539421 139.159195,76.952477 134.766052,79.576630 
	C130.986710,81.834137 126.124840,81.036621 123.037743,77.652771 
	C119.854553,74.163589 119.550797,67.562393 122.402321,63.843857 
	C125.552475,59.735905 130.248657,58.847408 135.220352,61.451702 
	C135.798843,61.754723 136.290390,62.223755 137.078644,62.904919 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M95.876671,60.741886 
	C101.230354,63.507046 102.766998,67.878342 101.653152,73.027733 
	C100.749725,77.204346 97.918076,80.028931 93.503532,80.715347 
	C89.258934,81.375336 85.970726,79.537727 83.835045,76.012253 
	C81.494942,72.149338 81.526497,68.023026 84.172867,64.302689 
	C86.957626,60.387779 90.780571,58.932922 95.876671,60.741886 
z"
      />
    </svg>
  )
}
export default GoogleLogo
