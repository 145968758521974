import { GridFooterContainer, GridPagination } from "@mui/x-data-grid"
import CustomPagination from "./CustomPagination"
import { useTheme } from "../../contexts/theme"

function Footer({ palette, hidePageNumbers }) {
  const { theme } = useTheme()
  return (
    <GridFooterContainer sx={{ display: "flex", flexWrap: "wrap" }}>
      <GridPagination
        ActionsComponent={"a"}
        sx={{
          fontSize: "0.8rem",
          color: theme.palette.mode === "dark" ? "#FFFFFFA0" : "#000000A0",
          "& .MuiPaginationItem-root": {
            "&:hover": {
              background: "unset",
              borderBottom: 1,
              borderColor: "accent",
            },
          },
          "& .MuiToolbar-gutters": {
            paddingLeft: "1.2rem",
          },
          "& .MuiPagination-button": {
            display: "none",
          },
          ".MuiTablePagination-selectLabel": {
            fontSize: "0.8rem",
            color: theme.palette.mode === "dark" ? "#FFFFFFA0" : "#000000A0",
          },
          ".MuiTablePagination-displayedRows": {
            fontSize: "0.8rem",
            color: theme.palette.mode === "dark" ? "#FFFFFFA0" : "#000000A0",
          },
          ".MuiTablePagination-select": {
            border: `1px solid ${palette.accent}`,
            borderRadius: "20px",
            marginRight: "-3px",
            color: `${palette.text.primary} !important`,
            padding: "6px 10px",
            "&:focus": {
              borderRadius: "20px",
              color: palette.accent,
            },
          },
          ".MuiTablePagination-selectIcon": {
            color: palette.accent,
          },
          ".MuiTablePagination-input": {
            margin: 0,
          },
          ".MuiTablePagination-toolbar": {
            display: "flex",
            gap: "20px",
            minWidth: "300px",
          },
          ".MuiTablePagination-spacer": {
            display: "none",
          },
        }}
      />
      <CustomPagination hidePageNumbers={hidePageNumbers} />
    </GridFooterContainer>
  )
}
export default Footer
