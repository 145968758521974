import { ResponsiveLine } from "@nivo/line"
import { useTooltip } from "@nivo/tooltip"

import Box from "@mui/material/Box"
import CustomTooltip from "../../../../CustomTooltip"
import { useTheme } from "../../../../../contexts/theme"

const BodySection = ({ data = [], config, maxValue }) => {
  const { theme } = useTheme()
  const itemTextColor = theme.palette.mode === "dark" ? "#fff" : "#333333"
  let {
    margin,
    lineWidth,
    pointSize,
    curve,
    enableGridX,
    enableGridY,
    axisLeft,
    axisBottom,
    chartContainerDimensions = {
      height: "350px",
    },
    showTooltipPrevious,
  } = config

  const customTheme = {
    text: {
      fill: itemTextColor,
    },
    tooltip: {
      container: {
        background: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
      },
    },
    grid: {
      line: {
        stroke: "rgba(29, 71, 206, 0.3)",
        strokeWidth: 0.93,
        top: 359.59,
      },
    },
    axis: {
      ticks: {
        text: {
          fontSize: 10,
          fill: itemTextColor,
          outlineWidth: 0,
          outlineColor: "transparent",
        },
      },
    },
  }

  const DashedSolidLine = ({ series, lineGenerator, xScale, yScale }) => {
    const { showTooltipFromEvent, hideTooltip } = useTooltip()

    function renderTip(event, pointData, color, id, viewBy) {
      return showTooltipFromEvent(
        <CustomTooltip
          data={{ ...pointData.data, id }}
          showCurrentPrevDate={true}
          dataDatum={{ color }}
          showLegendName={false}
          firstcolumName="Actual"
          secondcolumName="Forecasted"
          showCurrentPrevColors={{
            current: "#F19004",
            previous: theme.palette.mode === "light" ? "#1D54C8" : "#A4D0E9",
          }}
          viewBy={viewBy}
          showTooltipPrevious={showTooltipPrevious}
        />,
        event
      )
    }

    return (
      <>
        {series.map(({ id, data, color }, index) => (
          <g key={id}>
            <path
              d={lineGenerator(
                data.map((d) => ({
                  x: xScale(d.data.x),
                  y: yScale(d.data.y),
                }))
              )}
              fill="none"
              stroke={color}
              style={
                index % 2 === 0
                  ? {
                    strokeWidth: 3,
                  }
                  : {
                    strokeDasharray: "8, 5",
                    strokeWidth: 3,
                  }
              }
            />
            {data.map((d) =>
              index % 2 === 0 ? (
                <circle
                  key={`${d.data.x}`}
                  cx={xScale(d.data.x)}
                  cy={yScale(d.data.y)}
                  r={5}
                  onMouseEnter={
                    index % 2 === 0
                      ? (event) => renderTip(event, d, color, id)
                      : hideTooltip
                  }
                  onMouseMove={
                    index % 2 === 0
                      ? (event) => renderTip(event, d, color, id)
                      : hideTooltip
                  }
                  onMouseLeave={hideTooltip}
                  fill={
                    theme.palette.mode === "light"
                      ? "#ffffff"
                      : index % 2 === 0
                        ? "#F19004"
                        : "#1D54C8"
                  }
                  stroke={
                    theme.palette.mode === "dark"
                      ? "#ffffff"
                      : index % 2 === 0
                        ? "#F19004"
                        : "#A4D0E9"
                  }
                  strokeWidth={3}
                />
              ) : null
            )}
          </g>
        ))}
      </>
    )
  }

  const Color = (data) => {
    return data.id === "Actual"
      ? "#F19004"
      : theme.palette.mode === "light"
        ? "#1D54C8"
        : "#A4D0E9"
  }

  return (
    <Box sx={{ ...chartContainerDimensions }}>
      <ResponsiveLine
        animate={false}
        motionConfig="none"
        data={data}
        margin={margin}
        xScale={{
          type: "point",
          min: 0,
          max: "auto",
        }}
        yScale={{
          type: "linear",
          min: 0,
          max: maxValue || "auto",
        }}
        axisBottom={axisBottom}
        axisLeft={axisLeft}
        colors={Color}
        lineWidth={lineWidth}
        pointSize={pointSize}
        enableCrosshair={false}
        curve={curve}
        enableGridX={enableGridX}
        enableGridY={enableGridY}
        layers={["grid", "axes", DashedSolidLine]}
        theme={customTheme}
      />
    </Box>
  )
}

export default BodySection
