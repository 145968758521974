import { useTheme } from "../../contexts/theme"


const PercentIcon = () => {
    const { theme } = useTheme()
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_8601_21250)">
                <path d="M7.5 13L12.5 7" stroke={theme.palette.accent} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M12.4951 12.2891H12.5026" stroke={theme.palette.accent} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M7.49512 7.71094H7.5026" stroke={theme.palette.accent} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                <path opacity="0.5" d="M10.0003 18.3307C14.6027 18.3307 18.3337 14.5998 18.3337 9.9974C18.3337 5.39502 14.6027 1.66406 10.0003 1.66406C5.39795 1.66406 1.66699 5.39502 1.66699 9.9974C1.66699 14.5998 5.39795 18.3307 10.0003 18.3307Z" stroke={theme.palette.accent} stroke-width="2" />
            </g>
            <defs>
                <clipPath id="clip0_8601_21250">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>


    )
}

export default PercentIcon