import { Box } from "@mui/material"
import Star from "../../../../../assets/svgs/Star"

export const ViewCampaignData = {
  totalRows: 10,
  pageNumber: 1,
  pageSize: 10,
  searchText: "",
  data: [
    {
      id: 1,
      Date: "10th Dec 2024",
      CampaignName: "Festive Sale",
      Channels: 2,
      TargetAudience: "VIP Customers",
      Members: 20000,
    },
    {
      id: 2,
      Date: "11th Dec 2024",
      CampaignName: "Cart Abandonment Reminder",
      Channels: 2,
      TargetAudience: "Cart Abandonment",
      Members: 20000,
    },
    {
      id: 3,
      Date: "12th Dec 2024",
      CampaignName: "New Launch - Product X",
      Channels: 2,
      TargetAudience: "Recent Purchasers",
      Members: 20000,
    },
    {
      id: 4,
      Date: "13th Dec 2024",
      CampaignName: "Weekend Flash Sale",
      Channels: 2,
      TargetAudience: "Custom: High Spenders",
      Members: 20000,
    },
    {
      id: 5,
      Date: "14th Dec 2024",
      CampaignName: "Monthly Newsletter",
      Channels: 2,
      TargetAudience: "Newsletter Subscribers",
      Members: 20000,
    },
    {
      id: 6,
      Date: "15th Dec 2024",
      CampaignName: "Back-to-School Offers",
      Channels: 2,
      TargetAudience: "Parents & Teachers",
      Members: 20000,
    },
    {
      id: 7,
      Date: "16th Dec 2024",
      CampaignName: "Limited Time Discount",
      Channels: 2,
      TargetAudience: "Recent Purchasers",
      Members: 20000,
    },
    {
      id: 8,
      Date: "17th Dec 2024",
      CampaignName: "Mid-Year Clearance Sale",
      Channels: 2,
      TargetAudience: "Loyal Customers",
      Members: 20000,
    },
    {
      id: 9,
      Date: "18th Dec 2024",
      CampaignName: "Exclusive Early Access",
      Channels: 2,
      TargetAudience: "First-Time Buyers",
      Members: 20000,
    },
    {
      id: 10,
      Date: "19th Dec 2024",
      CampaignName: "New Customer Welcome",
      Channels: 2,
      TargetAudience: "Custom: High Spenders",
      Members: 20000,
    },
  ],
}

export const ViewCampaignParameters = {
  reportParameterTypes: [
    {
      parameterType: "COLUMNS",
      reportParameters: [
        {
          parameterName: "columns",
          parameterValue:
            '[{"field":"Date","headerName":"Date","flex":1,"minWidth":150,"headerClassName":"datagrid-header"},{"field":"CampaignName","headerName":"Campaign Name","flex":1.5,"minWidth":200,"headerClassName":"datagrid-header"},{"field":"Channels","headerName":"Channels","flex":1,"minWidth":100,"headerClassName":"datagrid-header"},{"field":"TargetAudience","headerName":"Target Audience","flex":1.5,"minWidth":200,"headerClassName":"datagrid-header"},{"field":"Members","headerName":"Members","flex":1,"minWidth":100,"headerClassName":"datagrid-header"}]',
        },
      ],
    },
  ],
  columns: [
    {
      field: "Date",
      headerName: "Date",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
    },
    {
      field: "CampaignName",
      headerName: "Campaign Name",
      flex: 1.5,
      minWidth: 200,
      headerClassName: "datagrid-header",
    },
    {
      field: "Channels",
      headerName: "Channels",
      flex: 1,
      minWidth: 100,
      headerClassName: "datagrid-header",
    },
    {
      field: "TargetAudience",
      headerName: "Target Audience",
      flex: 1.5,
      minWidth: 200,
      headerClassName: "datagrid-header",
    },
    {
      field: "Members",
      headerName: "Members",
      flex: 1,
      minWidth: 100,
      headerClassName: "datagrid-header",
    },
  ],
}

export const TargetAudiencesData = {
  totalRows: 7,
  pageNumber: 1,
  pageSize: 10,
  searchText: "",
  data: [
    {
      id: 1,
      Audience: "Loyal Customer",
      CriteriaFilters: "Age: 25-34, Gender: Male",
      CreatedDate: "2023-12-20",
      TotalAudience: 5000,
      EngagementRate: "75%",
      ConversionRate: "10%",
      Notes: "High engagement group",
    },
    {
      id: 2,
      Audience: "New Sign Ups",
      CriteriaFilters: "Location: US, Interests: Tech",
      CreatedDate: "2023-12-18",
      TotalAudience: 8000,
      EngagementRate: "60%",
      ConversionRate: "8%",
      Notes: "Potential for upsell",
    },
    {
      id: 3,
      Audience: "Inactive Customers",
      CriteriaFilters: "Age: 18-24, Gender: Female",
      CreatedDate: "2023-12-15",
      TotalAudience: 3000,
      EngagementRate: "85%",
      ConversionRate: "12%",
      Notes: "Ideal For Retention",
    },
    {
      id: 4,
      Audience: "Holiday Shoppers",
      CriteriaFilters: "Age: 18-24, Gender: Female",
      CreatedDate: "2023-12-15",
      TotalAudience: 3000,
      EngagementRate: "85%",
      ConversionRate: "12%",
      Notes: "Ideal For Retention",
    },
    {
      id: 5,
      Audience: "Premium Customers",
      CriteriaFilters: "Age: 18-24, Gender: Female",
      CreatedDate: "2023-12-15",
      TotalAudience: 3000,
      EngagementRate: "85%",
      ConversionRate: "12%",
      Notes: "Ideal For Retention",
    },
    {
      id: 6,
      Audience: "Social Media Followers",
      CriteriaFilters: "Age: 18-24, Gender: Female",
      CreatedDate: "2023-12-15",
      TotalAudience: 3000,
      EngagementRate: "85%",
      ConversionRate: "12%",
      Notes: "Ideal For Retention",
    },
    {
      id: 7,
      Audience: "Inactive Customers",
      CriteriaFilters: "Age: 18-24, Gender: Female",
      CreatedDate: "2023-12-15",
      TotalAudience: 3000,
      EngagementRate: "85%",
      ConversionRate: "12%",
      Notes: "Ideal For Retention",
    },
  ],
}

export const TargetAudiencesParameters = {
  reportParameterTypes: [
    {
      parameterType: "COLUMNS",
      reportParameters: [
        {
          parameterName: "columns",
          parameterValue:
            '[{"field":"Audience","headerName":"Audience","flex":1,"minWidth":200,"headerClassName":"datagrid-header"},{"field":"CriteriaFilters","headerName":"Criteria/Filters","flex":1,"minWidth":300,"headerClassName":"datagrid-header"},{"field":"CreatedDate","headerName":"Created Date","flex":1,"minWidth":150,"headerClassName":"datagrid-header"},{"field":"TotalAudience","headerName":"Total Audience","flex":1,"minWidth":150,"headerClassName":"datagrid-header"},{"field":"EngagementRate","headerName":"Engagement Rate","flex":1,"minWidth":150,"headerClassName":"datagrid-header"},{"field":"ConversionRate","headerName":"Conversion Rate","flex":1,"minWidth":150,"headerClassName":"datagrid-header"},{"field":"Notes","headerName":"Notes","flex":1,"minWidth":200,"headerClassName":"datagrid-header"}]',
        },
      ],
    },
  ],
  columns: [
    {
      field: "Audience",
      headerName: "Audience",
      flex: 1,
      minWidth: 200,
      headerClassName: "datagrid-header",
    },
    {
      field: "CriteriaFilters",
      headerName: "Criteria/Filters",
      flex: 1,
      minWidth: 300,
      headerClassName: "datagrid-header",
    },
    {
      field: "CreatedDate",
      headerName: "Created Date",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
    },
    {
      field: "TotalAudience",
      headerName: "Total Audience",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
    },
    {
      field: "EngagementRate",
      headerName: "Engagement Rate",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
    },
    {
      field: "ConversionRate",
      headerName: "Conversion Rate",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
    },
    {
      field: "Notes",
      headerName: "Notes",
      flex: 1,
      minWidth: 200,
      headerClassName: "datagrid-header",
    },
  ],
}

export const ViewTemplatesData = {
  totalRows: 7,
  pageNumber: 1,
  pageSize: 10,
  searchText: "",
  data: [
    {
      id: 1,
      TemplateName: "Welcome Message",
      Chaneel: 2,
      ContentPreview: "Welocme to Our Store",
    },
    {
      id: 2,
      TemplateName: "Flash Sale Announcement",
      Chaneel: 1,
      ContentPreview: "Hurry!Flash sale end today..! Shop Now",
    },
    {
      id: 3,
      TemplateName: "Product launch update",
      Chaneel: 4,
      ContentPreview: "Discover our new collection now",
    },
    {
      id: 4,
      TemplateName: "VIP Rewards Invitation",
      Chaneel: 2,
      ContentPreview: "Exclusive Rewards for our VIPs",
    },
    {
      id: 5,
      TemplateName: "Weekend Offers",
      Chaneel: 2,
      ContentPreview: "Enjoy special weekend offers!",
    },
    {
      id: 6,
      TemplateName: "Newsletter August 2024",
      Chaneel: 2,
      ContentPreview: "Stay updated with our August news.",
    },
    {
      id: 7,
      TemplateName: "Birthday Wishes Template",
      Chaneel: 2,
      ContentPreview: "Happy Birthday! Here’s a gift for you.",
    },
    {
      id: 8,
      TemplateName: "Limited Stock Alert",
      Chaneel: 2,
      ContentPreview: "Items are running out fast, shop now!",
    },
    {
      id: 9,
      TemplateName: "Festive Greetings Email",
      Chaneel: 2,
      ContentPreview: "Wishing you joy this festive season.",
    },
  ],
}
export const ViewTemplatesParameters = {
  reportParameterTypes: [
    {
      parameterType: "COLUMNS",
      reportParameters: [
        {
          parameterName: "columns",
          parameterValue:
            '[{"field":"TemplateName","headerName":"Template Name","flex":1,"minWidth":200,"headerClassName":"datagrid-header"},{"field":"Chaneel","headerName":"Channel","flex":1,"minWidth":150,"headerClassName":"datagrid-header"},{"field":"ContentPreview","headerName":"Content Preview","flex":1.5,"minWidth":300,"headerClassName":"datagrid-header"}]',
        },
      ],
    },
  ],
  columns: [
    {
      field: "TemplateName",
      headerName: "Template Name",
      flex: 1,
      minWidth: 200,
      headerClassName: "datagrid-header",
    },
    {
      field: "Chaneel",
      headerName: "Channel",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
    },
    {
      field: "ContentPreview",
      headerName: "Content Preview",
      flex: 1.5,
      minWidth: 300,
      headerClassName: "datagrid-header",
    },
  ],
}

export const summeryTableData = {
  data: {
    mail: {
      TotalCampaigns: { value: "15", change: "" },
      MessagesSent: { value: "4,000", change: "" },
      OpenRate: { value: "50%", change: "up" },
      ClickThroughRate: { value: "30%", change: "up" },
      AvgCostPerCampaign: { value: "Free", change: "" },
    },

    SMS: {
      TotalCampaigns: { value: "20", change: "" },
      MessagesSent: { value: "5,000", change: "" },
      OpenRate: { value: "40%", change: "up" },
      ClickThroughRate: { value: "28%", change: "up" },
      AvgCostPerCampaign: { value: "$50", change: "" },
    },
  },
}

export const TemplatesData = {
  totalRows: 7,
  pageNumber: 1,
  pageSize: 10,
  searchText: "",
  data: [
    {
      id: 1,
      TemplateName: "Welcome Message",
      Chaneel: 2,
      ContentPreview: "Welcome to our store! Enjoy 10% off!",
      UsageCount: "2",
      LastUpdatedDate: "10th Dec 2024",
    },
    {
      id: 2,
      TemplateName: "Flash Sale Announcement",
      Chaneel: 3,
      ContentPreview: "Hurry! Flash Sale ends today. Shop now!",
      UsageCount: "2",
      LastUpdatedDate: "10th Dec 2024",
    },
    {
      id: 3,
      TemplateName: "Product Launch Update",
      Chaneel: 4,
      ContentPreview: "Discover our new collection now!",
      UsageCount: "2",
      LastUpdatedDate: "10th Dec 2024",
    },
    {
      id: 4,
      TemplateName: "VIP Rewards Invitation",
      Chaneel: 2,
      ContentPreview: "Exclusive rewards for our VIPs!",
      UsageCount: "2",
      LastUpdatedDate: "10th Dec 2024",
    },
    {
      id: 5,
      TemplateName: "Weekend Offers",
      Chaneel: 2,
      ContentPreview: "Enjoy special weekend offers!",
      UsageCount: "2",
      LastUpdatedDate: "10th Dec 2024",
    },
    {
      id: 6,
      TemplateName: "Newsletter August 2024",
      Chaneel: 1,
      ContentPreview: "Stay updated with our August news.",
      UsageCount: "2",
      LastUpdatedDate: "10th Dec 2024",
    },
    {
      id: 7,
      TemplateName: "Early Access Promotion",
      Chaneel: 3,
      ContentPreview: "Get early access to our top products!",
      UsageCount: "2",
      LastUpdatedDate: "10th Dec 2024",
    },
    {
      id: 8,
      TemplateName: "Birthday Wishes Template",
      Chaneel: 4,
      ContentPreview: "Happy Birthday! Here’s a gift for you.",
      UsageCount: "2",
      LastUpdatedDate: "10th Dec 2024",
    },
    {
      id: 9,
      TemplateName: "Limited Stock Alert",
      Chaneel: 2,
      ContentPreview: "Items are running out fast, shop now!",
      UsageCount: "2",
      LastUpdatedDate: "10th Dec 2024",
    },
    {
      id: 10,
      TemplateName: "Festive Greetings Email",
      Chaneel: 3,
      ContentPreview: "Wishing you joy this festive season.",
      UsageCount: "2",
      LastUpdatedDate: "10th Dec 2024",
    },
  ],
}

export const TemplatesParameters = {
  reportParameterTypes: [
    {
      parameterType: "COLUMNS",
      reportParameters: [
        {
          parameterName: "columns",
          parameterValue:
            '[{"field":"TemplateName","headerName":"Template Name","flex":1,"minWidth":200,"headerClassName":"datagrid-header"},{"field":"Chaneel","headerName":"Channel","flex":1,"minWidth":150,"headerClassName":"datagrid-header"},{"field":"ContentPreview","headerName":"Content Preview","flex":1.5,"minWidth":300,"headerClassName":"datagrid-header"},{"field":"UsageCount","headerName":"Usage Count","flex":0.5,"minWidth":100,"headerClassName":"datagrid-header"},{"field":"LastUpdatedDate","headerName":"Last Updated Date","flex":1,"minWidth":150,"headerClassName":"datagrid-header"}]',
        },
      ],
    },
  ],
  columns: [
    {
      field: "TemplateName",
      headerName: "Template Name",
      flex: 1,
      minWidth: 200,
      headerClassName: "datagrid-header",
    },
    {
      field: "Chaneel",
      headerName: "Channel",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
    },
    {
      field: "ContentPreview",
      headerName: "Content Preview",
      flex: 1.5,
      minWidth: 300,
      headerClassName: "datagrid-header",
    },
    {
      field: "UsageCount",
      headerName: "Usage Count",
      flex: 0.5,
      minWidth: 100,
      headerClassName: "datagrid-header",
    },
    {
      field: "LastUpdatedDate",
      headerName: "Last Updated Date",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
    },
  ],
}

export const TargetAudience = {
  totalRows: 7,
  pageNumber: 1,
  pageSize: 10,
  searchText: "",
  data: [
    {
      id: 1,
      Audience: "Loyal Customers",
      CriteriaFilters: "5+ purchases, high spending",
      CreatedDate: "10th Oct 2024",
      TotalAudience: "2,500",
      LastUpdatedDate: "10th Dec 2024",
    },
    {
      id: 2,
      Audience: "New Sign-Ups",
      CriteriaFilters: "Signed up in last 30 days",
      CreatedDate: "10th Oct 2024",
      TotalAudience: "2,500",
      LastUpdatedDate: "10th Dec 2024",
    },
    {
      id: 3,
      Audience: "Inactive Customers",
      CriteriaFilters: "No activity in 90 days",
      CreatedDate: "10th Oct 2024",
      TotalAudience: "2,500",
      LastUpdatedDate: "10th Dec 2024",
    },
    {
      id: 4,
      Audience: "Holiday Shoppers",
      CriteriaFilters: "Shopped last holiday season",
      CreatedDate: "10th Oct 2024",
      TotalAudience: "2,500",
      LastUpdatedDate: "10th Dec 2024",
    },
    {
      id: 5,
      Audience: "Premium Customers",
      CriteriaFilters: "Avg. purchase > $500",
      CreatedDate: "10th Oct 2024",
      TotalAudience: "2,500",
      LastUpdatedDate: "10th Dec 2024",
    },
    {
      id: 6,
      Audience: "Social Media Followers",
      CriteriaFilters: "Instagram & Facebook engaged",
      CreatedDate: "10th Oct 2024",
      TotalAudience: "2,500",
      LastUpdatedDate: "10th Dec 2024",
    },
  ],
}
export const TargetAudienceParameters = {
  reportParameterTypes: [
    {
      parameterType: "COLUMNS",
      reportParameters: [
        {
          parameterName: "columns",
          parameterValue:
            '[{"field":"Audience","headerName":"Audience","flex":1,"minWidth":200,"headerClassName":"datagrid-header"},{"field":"CriteriaFilters","headerName":"Criteria Filters","flex":1,"minWidth":150,"headerClassName":"datagrid-header"},{"field":"CreatedDate","headerName":"Created Date","flex":1.5,"minWidth":200,"headerClassName":"datagrid-header"},{"field":"TotalAudience","headerName":"Total Audience","flex":1,"minWidth":150,"headerClassName":"datagrid-header"},{"field":"LastUpdatedDate","headerName":"Last Updated Date","flex":1,"minWidth":150,"headerClassName":"datagrid-header"}]',
        },
      ],
    },
  ],
  columns: [
    {
      field: "Audience",
      headerName: "Audience",
      flex: 1,
      minWidth: 200,
      headerClassName: "datagrid-header",
    },
    {
      field: "CriteriaFilters",
      headerName: "Criteria/Filters",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
    },
    {
      field: "CreatedDate",
      headerName: "Created Date",
      flex: 1.5,
      minWidth: 200,
      headerClassName: "datagrid-header",
    },
    {
      field: "TotalAudience",
      headerName: "Total Audience",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
    },
    {
      field: "LastUpdatedDate",
      headerName: "Last Updated Date",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
    },
  ],
}
