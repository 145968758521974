import { ResponsiveCalendar } from "@nivo/calendar"
import Box from "@mui/material/Box"
import CustomTooltip from "../../../../CustomTooltip"
import { useTheme } from "../../../../../contexts/theme"

const BodySection = ({ data = [], config, minValue, maxValue }) => {
  const { theme } = useTheme()
  const {
    chartContainerDimensions,
    defaultColor,
    showTooltipPrevious,
    colors,
  } = config

  const fromDate = "2022-01-01"
  const toDate = "2024-12-01"

  const itemTextColor = theme?.palette?.mode === "dark" ? "#fff" : "#333333"

  const customTheme = {
    text: {
      fill: itemTextColor,
    },
    tooltip: {
      container: {
        background: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
      },
    },
    labels: {
      text: {
        fill: itemTextColor,
      },
    },
    legends: {
      text: {
        fontSize: 12,
      },
    },
  }

  return (
    <Box sx={{ ...chartContainerDimensions, height: "900px" }}>
      <ResponsiveCalendar
        // TODO: ResponsiveCalendar does no support animate and motionConfig props, find alternative to disbale animations
        // animate={false}
        // motionConfig="none"
        data={data}
        margin={{ top: 40, right: 0, bottom: 60, left: 20 }}
        theme={customTheme}
        minValue={minValue}
        maxValue={maxValue}
        from={fromDate}
        to={toDate}
        emptyColor="#eeeeee"
        colors={defaultColor ? defaultColor : colors}
        yearSpacing={-350}
        monthSpacing={10}
        yearLegendOffset={10}
        monthLegendOffset={18}
        monthLegendPosition={"after"}
        monthBorderColor="#ffffff"
        dayBorderWidth={2}
        dayBorderColor="#ffffff"
        tooltip={(n) => (
          <CustomTooltip
            data={n.data}
            showCurrentPrevDate={true}
            showTooltipPrevious={showTooltipPrevious}
          />
        )}
        // legends={[
        //   {
        //     anchor: "bottom-right", // Position of the legend
        //     direction: "row", // Layout direction
        //     justify: false,
        //     translateX: -30, // Adjust x position
        //     translateY: -50, // Adjust y position
        //     itemsSpacing: 10, // Spacing between legend items
        //     itemWidth: 100, // Width of each legend item
        //     itemHeight: 20, // Height of each legend item
        //     itemDirection: "left-to-right", // Direction of the items
        //     itemOpacity: 0.85,
        //     symbolSize: 12, // Size of the symbol in the legend
        //     symbolShape: "circle", // Shape of the symbol
        //     itemTextColor: itemTextColor, // Text color
        //     itemCount: 4,
        //   },
        // ]}
      />
    </Box>
  )
}

export default BodySection
