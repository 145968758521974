import Box from "@mui/material/Box"
import { ResponsiveRadialBar } from "@nivo/radial-bar"
import { useTheme } from "../../../../../contexts/theme"
import CustomTooltip from "../../../../CustomTooltip"
import { colorScheme } from "../../constant/configFallBack"

const BodySection = ({
  data = [],
  showTooltipPrevious,
  chartContainerDimensions = {
    height: "350px",
  },
}) => {
  const { theme } = useTheme()
  const itemTextColor = theme.palette.mode === "dark" ? "#fff" : "#333333"
  const customTheme = {
    text: {
      fill: itemTextColor,
    },
    tooltip: {
      container: {
        background: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
      },
    },
    grid: {
      line: {
        stroke: "rgba(29, 71, 206, 0.3)",
        strokeWidth: 0.93,
        top: 359.59,
      },
    },
    axis: {
      domain: {
        line: {
          stroke: "rgba(29, 71, 206, 0.3)",
          strokeWidth: 1,
        },
      },
      ticks: {
        text: {
          fontSize: 10,
          fill: itemTextColor,
          outlineWidth: 0,
          outlineColor: "transparent",
        },
      },
    },
  }

  return (
    <Box sx={{ ...chartContainerDimensions }}>
      <ResponsiveRadialBar
        animate={false}
        motionConfig="none"
        data={data}
        theme={customTheme}
        valueFormat=">-.2f"
        innerRadius={0.5}
        padding={0.1}
        cornerRadius={2}
        margin={{ top: 40, right: 120, bottom: 40, left: 40 }}
        radialAxisStart={null}
        colors={colorScheme}
        circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0 }}
        labelsSkipAngle={29}
        labelsRadiusOffset={1.1}
        tooltip={(point) => (
          <CustomTooltip
            data={point.bar.data}
            showCurrentPrevDate={true}
            showLegendName={true}
            dataDatum={point.bar}
            label={point.bar.data}
            showTooltipPrevious={showTooltipPrevious}
          />
        )}
      />
    </Box>
  )
}

export default BodySection
