import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import "./styles.scss"
import { useNavigate, useParams } from "react-router-dom"
import CustomPaper from "../../../components/CustomPaper"
import CustomBreadcrumbs from "../../../components/CustomBreadcrumbs"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import ProgressBarContainer from "../../../components/charts/progressBar/ProgressBarContainer"
import { setChartData } from "../../../redux/slices/customerSlice"
import { Grid } from "@mui/material"
import CalendarContainer from "../../../components/charts/calendar/CalendarContainer"
import { CustomerBarChartParameters } from "../../../constants/CustomerBarChartParameters"
import { postData } from "../../../api/api"
import { useTheme } from "../../../contexts/theme"
import Message from "../../../components/Message"
import BarChartContainer from "../../../components/charts/bar/BarChartContainer"
import { ProductBarChartTooltip } from "../../../constants/ProductBarChartTooltip"
import CompareBy from "../../../components/CompareBy"
import DatePicker from "../../../components/DatePicker"

const InventoryDetails = () => {
  const { theme } = useTheme()

  let { id } = useParams()

  const [productDetailsLoading, setProductDetailsLoading] = useState(false)
  const [productDetailsError, setProductDetailsError] = useState(null)
  const [productDetails, setProductDetails] = useState([])

  const fetchPersonalDetails = async (id) => {
    setProductDetailsLoading(true)
    try {
      let fetchedPersonalDetails = await postData("inventory/inventoryDetail", {
        entityDb: localStorage.getItem("entityDb"),
        productId: id,
      })
      fetchedPersonalDetails = Object.entries(fetchedPersonalDetails)
      setProductDetails(fetchedPersonalDetails)
    } catch (error) {
      setProductDetailsError(error.Message)
      console.log(error)
    } finally {
      setProductDetailsLoading(false)
    }
  }

  const [transactionalDetailsLoading, setTransactionalDetailsLoading] =
    useState(false)
  const [transactionalDetailsError, setTransactionalDetailsError] =
    useState(null)
  const [transactionalDetails, setTransactionalDetails] = useState([])

  const fetchTransactionalDetails = async (id) => {
    setTransactionalDetailsLoading(true)
    try {
      let fetchedTransactionalDetails = await postData(
        "inventory/transactionalInsights",

        {
          entityDb: localStorage.getItem("entityDb"),
          productId: id,
        }
      )

      fetchedTransactionalDetails = Object.entries(fetchedTransactionalDetails)

      setTransactionalDetails(fetchedTransactionalDetails)
    } catch (error) {
      console.log(error)
      setTransactionalDetailsError(error.Message)
    } finally {
      setTransactionalDetailsLoading(false)
    }
  }

  const [historicalVisitLoading, setHistoricalVisitLoading] = useState(false)
  const [historicalVisitError, setHistoricalVisitError] = useState(null)
  const [historicalVisitData, setHistoricalVisitData] = useState([])

  const fetchHistoricalVisitData = async (id) => {
    setHistoricalVisitLoading(true)
    try {
      let fechedHistoricalVisitData = await postData(
        "/inventory/historicalSales",
        {
          entityDb: localStorage.getItem("entityDb"),
          productId: id,
          fromDate: "20220101",
          toDate: "20241231",
        }
      )

      setHistoricalVisitData(fechedHistoricalVisitData)
    } catch (error) {
      console.log(error)
      setHistoricalVisitError(error.message)
    } finally {
      setHistoricalVisitLoading(false)
    }
  }

  const [topCustomersLoading, setTopCustomersLoading] = useState(false)
  const [topCustomersError, setTopCustomersError] = useState(null)
  const [topCustomers, setTopCustomers] = useState([])

  const fetchTopCustomers = async (id) => {
    setTopCustomersLoading(true)
    try {
      let fetchedTopCustomers = await postData("/inventory/top10Customer", {
        entityDb: localStorage.getItem("entityDb"),
        productId: id,
      })
      setTopCustomers(fetchedTopCustomers)
    } catch (error) {
      console.log(error)
      setTopCustomersError(error.message)
    } finally {
      setTopCustomersLoading(false)
    }
  }

  const [salesTrendLoading, setsalesTrendLoading] = useState(false)
  const [salesTrendError, setsalesTrendError] = useState(null)
  const [salesTrendData, setsalesTrendData] = useState([])
  const dateRangeData = useSelector((state) => state.calendar)

  const fetchsalesTrendData = async (id) => {
    setsalesTrendLoading(true)
    try {
      let fechedSalesTrend = await postData("/inventory/salesTrends", {
        entityDb: localStorage.getItem("entityDb"),
        productId: id,
        fromDate: dateRangeData.fromDate,
        toDate: dateRangeData.toDate,
      })

      setsalesTrendData(fechedSalesTrend)
    } catch (error) {
      console.log(error)
      setsalesTrendError(error.message)
    } finally {
      setsalesTrendLoading(false)
    }
  }

  const [profitabilityTrendsLoading, setprofitabilityTrendsLoading] =
    useState(false)
  const [profitabilityTrendsError, setprofitabilityTrendsError] = useState(null)
  const [profitabilityTrendsData, setprofitabilityTrendsData] = useState([])

  const fetchprofitabilityTrendsData = async (id) => {
    setprofitabilityTrendsLoading(true)
    try {
      let fechedProfitabilityTrends = await postData(
        "/inventory/profitabilityTrends",
        {
          entityDb: localStorage.getItem("entityDb"),
          productId: id,
          fromDate: dateRangeData.fromDate,
          toDate: dateRangeData.toDate,
        }
      )

      setprofitabilityTrendsData(fechedProfitabilityTrends)
    } catch (error) {
      console.log(error)
      setprofitabilityTrendsError(error.message)
    } finally {
      setprofitabilityTrendsLoading(false)
    }
  }

  useEffect(() => window.scrollTo(0, 0), [])

  useEffect(() => {
    fetchPersonalDetails(id)
    fetchTransactionalDetails(id)
    fetchHistoricalVisitData(id)
    fetchTopCustomers(id)
    fetchsalesTrendData(id)
    fetchprofitabilityTrendsData(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dateRangeData])

  const ThemeMode = theme.palette.mode
  const navigate = useNavigate()

  const comparisonPeriod = useSelector((state) => state.period.selectedValue)
  return (
    <>
      <Box position="relative">
        <Box p="30px 30px 0px 30px">
          <CustomBreadcrumbs
            breadcrumbItems={[
              {
                text: "All Product",
                // isActive: false,
                clickHandler: () => {
                  navigate("/employees/allemployees")
                },
              },
              {
                text: "Product Details",
                isActive: true,
              },
            ]}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          p="15px 30px 15px 30px"
        >
          <Typography component="div" variant="subtitle1">
            Product Details
          </Typography>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            maxWidth="auto"
            gap={2}
          >
            <CompareBy dateRangeData={dateRangeData} />
            <DatePicker />
          </Box>
        </Box>

        <Stack gap="20px" px="30px" pb="20px">
          <CustomPaper>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
              borderBottom="2px solid #091743"
            >
              <Typography mb="10px" color="inherit">
                Product Details
              </Typography>
            </Box>
            {/* Product Details  */}
            {/* <JobInformation data={productDetails} /> */}
            {productDetailsLoading ? (
              <Message type="loading" />
            ) : productDetailsError ? (
              <Message message={productDetailsError} type="error" />
            ) : (
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                gap="45px"
                mt={2}
              >
                {productDetails?.map(([key, value]) => (
                  <Stack
                    key={key}
                    rowGap="5px"
                    minWidth={{
                      sx: "200px",
                      sm: "200px",
                      md: "200px",
                      lg: "280px",
                    }}
                  >
                    <Typography variant="subtitle2" sx={{ color: "gray" }}>
                      {key}
                    </Typography>
                    <Typography
                      color="inherit"
                      sx={{
                        fontSize: "18px",
                      }}
                    >
                      {value || "No records"}
                    </Typography>
                  </Stack>
                ))}
              </Box>
            )}
          </CustomPaper>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} sx={{ height: "100%" }}>
              <CustomPaper customStyles={{ background: "#1b4cf00a" }}>
                <Typography sx={{ mb: 1 }}>Transactional Insights</Typography>
                <Grid container spacing={2}>
                  {transactionalDetailsLoading ? (
                    <Message type="loading" />
                  ) : transactionalDetailsError ? (
                    <Message message={transactionalDetailsError} type="error" />
                  ) : (
                    transactionalDetails?.map(([key, value]) => (
                      <Grid item xs={12} md={6} sx={{ height: "100%" }}>
                        <CustomPaper customStyles={{ height: "100%" }}>
                          <Stack
                            key={key}
                            rowGap="5px"
                            minWidth={{
                              sx: "200px",
                              sm: "200px",
                              md: "200px",
                              lg: "280px",
                            }}
                          >
                            <Typography
                              className="KPICardhoverEffect"
                              variant="subtitle2"
                              sx={{ color: "gray" }}
                            >
                              {key}
                            </Typography>
                            <Typography
                              className="KPICardhoverEffect"
                              color="inherit"
                              sx={{
                                fontSize: "18px",
                              }}
                            >
                              {value || "No records"}
                            </Typography>
                          </Stack>
                        </CustomPaper>
                      </Grid>
                    ))
                  )}
                </Grid>
              </CustomPaper>
            </Grid>

            <Grid item xs={12} md={6} sx={{ height: "100%" }}>
              <CustomPaper
                innerCustomStyles={{
                  border: 0,
                }}
              >
                <ProgressBarContainer
                  title="Top 10 Customers"
                  description="Top 10 Customers who bought this product."
                  route="customer"
                  setChartData={setChartData}
                  // comparisonPeriod={comparisonPeriod}
                  postUrl="customer/data"
                  reportUid="external"
                  extApiResponse={topCustomers}
                  extLoading={topCustomersLoading}
                  extError={topCustomersError}
                  configurations={[
                    {
                      parameterType: "POINTS",
                      reportParameters: [
                        {
                          parameterName: "enableNavigation",
                          parameterValue: "false",
                        },
                        {
                          parameterName: "showBubble",
                          parameterValue: "true",
                        },
                        {
                          parameterName: "showTooltipPrevious",
                          parameterValue: "false",
                        },
                        {
                          parameterName: "fieldMap",
                          parameterValue: {
                            name: "Customer Name",
                            key: "Total Quantity Purchased",
                          },
                        },
                        {
                          parameterName: "tooltip_data",
                          parameterValue: [
                            { name: "Customer Name", format: false, color: "" },
                            {
                              name: "Total Quantity Purchased",
                              format: "Number",
                              color: "",
                            },
                          ],
                        },
                      ],
                    },
                  ]}
                />
              </CustomPaper>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} sx={{ height: "100%" }}>
              <CustomPaper key={1} customStyles={{ overflow: "visible" }}>
                <BarChartContainer
                  title="Sales Trends"
                  description="Sales Trends."
                  route="customer"
                  setChartData={setChartData}
                  postUrl="customer/data"
                  reportUid="external"
                  extApiResponse={salesTrendData}
                  extLoading={salesTrendLoading}
                  extError={salesTrendError}
                  configurations={CustomerBarChartParameters}
                />
              </CustomPaper>
            </Grid>

            <Grid item xs={12} md={6} sx={{ height: "100%" }}>
              <CustomPaper key={1} customStyles={{ overflow: "visible" }}>
                <BarChartContainer
                  title="Profitability TrendsData"
                  description="Profitability TrendsData."
                  route="customer"
                  setChartData={setChartData}
                  postUrl="customer/data"
                  reportUid="external"
                  extApiResponse={profitabilityTrendsData}
                  extLoading={profitabilityTrendsLoading}
                  extError={profitabilityTrendsError}
                  configurations={ProductBarChartTooltip}
                />
              </CustomPaper>
            </Grid>
          </Grid>
          <CustomPaper key={1} customStyles={{ overflow: "visible" }}>
            <CalendarContainer
              route="inventoryDetails"
              setChartData={setChartData}
              title={"Historical Sales"}
              description={"Historical Sales"}
              postUrl="customer/data"
              reportUid={"external"}
              extApiResponse={historicalVisitData}
              extLoading={historicalVisitLoading}
              extError={historicalVisitError}
            />
          </CustomPaper>
        </Stack>
      </Box>
    </>
  )
}

export default InventoryDetails
