import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import { useTheme } from "../../contexts/theme"
import { styled } from "@mui/material"
import ComingSoon from "./children/ComingSoon"
import GlobalSearch from "./children/GlobalSearch"
import AnalyticaInfoIcon from "../../assets/svgs/AnalyticaInfoIcon"

export const sidebarWidth = 550

export const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  flexGrow: 1,
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginRight: 0,
  variants: [
    {
      props: ({ open }) => open,
      style: {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0 + sidebarWidth,
      },
    },
  ],
}))

export const SideBar = ({
  sidebarState,
  sidebarOpen,
  handleSideBarOpen,
  handleSideBarToggle,
  handleSideBarClose,
}) => {
  const { theme } = useTheme()
  const { title, data } = sidebarState

  return (
    <Drawer
      sx={{
        width: sidebarWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: sidebarWidth,
          boxSizing: "border-box",
        },
      }}
      PaperProps={{
        style: {
          padding: "1.2rem",
          backgroundColor:
            theme.palette.mode === "dark" ? "#071339" : "#FBFBFE",
          backgroundImage: "unset",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: sidebarWidth,
            boxSizing: "border-box",
          },
          zIndex: 2,
        },
      }}
      variant="persistent"
      anchor="right"
      open={sidebarOpen}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignContent="center"
        alignItems="center"
      >
        <Typography variant="h5" color="inherit">
          {{
            AnalyticaInfoIcon: <AnalyticaInfoIcon mode={theme.palette.mode} />,
          }[title] || title}
        </Typography>
        <IconButton onClick={handleSideBarClose} color="accent">
          <CloseIcon
            sx={{
              color: theme.palette.accent,
            }}
          />
        </IconButton>
      </Box>

      {data?.type === "comingSoon" && <ComingSoon />}
      {data?.type === "globalSearch" && (
        <GlobalSearch
          sidebarState={sidebarState}
          sidebarOpen={sidebarOpen}
          handleSideBarOpen={handleSideBarOpen}
          handleSideBarToggle={handleSideBarToggle}
          handleSideBarClose={handleSideBarClose}
        />
      )}
    </Drawer>
  )
}
