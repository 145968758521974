import { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import CircularProgress from "@mui/material/CircularProgress"
import ArrowRise from "../assets/svgs/ArrowRise"
import ArrowFall from "../assets/svgs/ArrowFall"
import Analytica from "./Analytica"
import { generateCacheKey, getFormattedValue } from "../utils/utils"
import { useTheme } from "../contexts/theme"
import extractKeyValuePairs from "../utils/extractKeyValuePairs"
import { useDispatch, useSelector } from "react-redux"
import { postData } from "../api/api"

const KPICard = ({
  title,
  description,
  route,
  setChartData,
  postUrl,
  reportUid,
  configurations,
  extApiResponse,
  extLoading = false,
  extError = null,
  extComparisonPeriod,
}) => {
  const { theme } = useTheme()
  const dispatch = useDispatch()

  const dateRangeData = useSelector((state) => state.calendar)
  let reduxComparisonPeriod = useSelector((state) => state.period.selectedValue)
  const comparisonPeriod = extComparisonPeriod ?? reduxComparisonPeriod
  const isComparedBy = comparisonPeriod.selectedValue === "Compared By"

  let transformedConfigs = extractKeyValuePairs(configurations)

  let reportState =
    useSelector((state) => {
      return state[route]?.reports?.find(
        (report) => report.report_uid === reportUid
      )
    }) || {}

  const cacheKey = generateCacheKey(
    reportUid,
    dateRangeData.fromDate,
    dateRangeData.toDate,
    comparisonPeriod.previousFromDate,
    comparisonPeriod.previousToDate,
  )

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const configOverrides = [
    {
      key: "meta_info",
      fallback: function (reportUid, title) {
        return [
          "Gross Sales",
          "Average Ticket",
          "Repeat Customers",
          "Avg Sales per employee",
        ].includes(title) ?
          { "format": "Currency" } :
          { "format": "Number" }
      }(reportUid, title)
    },
  ]

  configOverrides.forEach(({ key, fallback }) => {
    transformedConfigs[key] = transformedConfigs[key] || fallback
  })

  function calculateChangePercentage(apiResponse) {
    try {
      const currentValue = apiResponse.reportDataCurrent[0]?.value
      const previousValue = apiResponse.reportDataPrevious[0]?.value

      if (currentValue === undefined || previousValue === undefined || isNaN(currentValue) || isNaN(previousValue)) {
        return "NA"
      }

      // If both values are the same, return 0 (no change)
      if (currentValue === previousValue) return 0

      // If previous value is 0, division by zero
      if (previousValue === 0) return currentValue > 0 ? Infinity : 0 // Infinite or No Change based on context

      // change
      return ((currentValue - previousValue) / previousValue) * 100
    } catch (error) {
      // Catch any unexpected errors and return "NA"
      return "NA"
    }
  }

  useEffect(() => {
    if (["static", "external"].includes(reportUid)) return
    if (reportState.cacheKey === cacheKey) return
    const payload = {
      entityDb: localStorage.getItem("entityDb"),
      fromDate: dateRangeData.fromDate,
      toDate: dateRangeData.toDate,
      reportInstanceId: reportUid,
      comparedBy: {
        previousFromDate: comparisonPeriod.previousFromDate,
        previousToDate: comparisonPeriod.previousToDate,
      },
    }

    setLoading(true)
    postData(postUrl, payload)
      .then((apiResponse) => {
        dispatch(
          setChartData({
            reportUid,
            apiResponse: {
              ...apiResponse,
              changePercentage: isComparedBy ? null : calculateChangePercentage(apiResponse)
            },
            cacheKey,
          })
        )
        setLoading(false)
      })
      .catch((err) => {
        console.log("LineBarContainer API Error", err)
        setError("Something went wrong while fetching the data...")
        setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateRangeData.fromDate,
    dateRangeData.toDate,
    comparisonPeriod.previousFromDate,
    comparisonPeriod.previousToDate,
    reportState.cacheKey,
    reportState.viewBy,
  ])

  if (reportUid === "static") {
    reportState.apiResponse = isComparedBy ? [{ "value": 100 }] : {
      "reportDataCurrent": [
        {
          "value": 100
        }
      ],
      "reportDataPrevious": [
        {
          "value": 50
        }
      ],
      changePercentage: 200
    }
  }

  if (reportUid === "external") {
    reportState.apiResponse = {
      ...extApiResponse,
      changePercentage: calculateChangePercentage(extApiResponse)
    }
  }

  return (
    <Stack
      display="flex"
      justifyContent="space-between"
      height="100%"
      gap="5px"
    >
      {/* Title and analytica box */}
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        gap="2px"
      >
        <Typography
          variant="subtitle2"
          component="div"
          style={{
            marginBottom: "8px",
            overflow: "visible",
            overflowWrap: "break-word",
            textOverflow: "none",
            color: theme.palette.mode === "dark" ? "#ffffff" : "#737373",
            fontWeight: "700",
          }}
          textAlign="left"
        >
          {title}
        </Typography>

        <Box display="flex">
          {transformedConfigs.focus_mode && (
            <Analytica description={description} />
          )}
        </Box>
      </Box>

      {/* Body Stack */}
      {loading ? (<CircularProgress disableShrink color="info" size={30} />) : (
        error ? "Something went wrong!" :
          <Stack display="flex" flexDirection="column" gap="5px">
            {isComparedBy ?
              <Typography variant="h5" color="inherit" className="hoverEffect" fontWeight="bold">
                {getFormattedValue(
                  reportState?.apiResponse?.[0]?.value,
                  transformedConfigs?.meta_info?.format
                )}
                {/* {transformedConfigs?.star && <Star />} */}
              </Typography>
              :
              <>
                <Typography variant="h5" color="inherit" className="hoverEffect" fontWeight="bold">
                  {getFormattedValue(
                    reportState?.apiResponse?.reportDataCurrent?.[0]?.value,
                    transformedConfigs?.meta_info?.format
                  )}
                </Typography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  flexWrap="wrap"
                >
                  <Typography
                    color={theme.palette.mode === "dark" ? "#ffffff" : "#404040"}
                    className="hoverEffect"
                  >
                    {getFormattedValue(
                      reportState?.apiResponse?.reportDataPrevious?.[0]?.value,
                      transformedConfigs?.meta_info?.format
                    )}
                  </Typography>
                  <Typography
                    className="hoverEffect"
                    sx={{
                      display: "flex",
                      alignItems: "flex-end",
                      color: theme.palette.mode === "dark" ? "#ffffff" : "#404040",
                      fontSize: "1.2rem"
                    }}
                  >
                    {getFormattedValue(reportState?.apiResponse?.changePercentage, "Percentage")}
                    {reportState?.apiResponse?.changePercentage ?
                      reportState?.apiResponse?.changePercentage > 0 ?
                        <ArrowRise width="45" height="35" /> : <ArrowFall width="45" height="35" />
                      : null}
                  </Typography>
                </Box>
              </>
            }
          </Stack>
      )}
    </Stack>
  )
}

export default KPICard
