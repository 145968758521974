import React from "react"
import { Grid, Stack, Typography } from "@mui/material"
import GoogleOAuth from "./GoogleOAuth"
import YelpAuth from "./YelpAuth"
import RouteTitle from "../../../components/RouteTitle"
import CustomPaper from "../../../components/CustomPaper"

const MyConfigurationAuth = () => {
  return (
    <>
      <RouteTitle title="My Configuration Auth" />
      <Stack padding="20px" gap={3}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          Authentication Configurations
        </Typography>
        <Grid container spacing={2}>
          {/* GoogleOAuth */}
          <Grid item xs={12} sm={6}>
            <CustomPaper>
              <GoogleOAuth />
            </CustomPaper>
          </Grid>

          {/* YelpAuth */}
          <Grid item xs={12} sm={6}>
            <CustomPaper>
              <YelpAuth />
            </CustomPaper>
          </Grid>
        </Grid>
      </Stack>
    </>
  )
}

export default MyConfigurationAuth
