import { useSelector, useDispatch } from "react-redux"
import { useEffect, useState, useRef, memo } from "react"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs.js"
import { fetchData } from "./services/dataServices.js"
import {
  transformDataPDFExport,
  transformDataSheetExport,
  transfromChartData,
} from "./services/transformServices.js"
import { generateCacheKey } from "../../../utils/utils.js"
import { Stack } from "@mui/material"

import {
  chartContainerDimentionsFallbackConfig,
  customerDefaultColor,
  defaultColor,
  macroMetricsFallbackConfig,
  PDFFieldsFallbackConfig,
  ProductDefaultColor,
  tooltipDataFallbackConfig,
  tooltipDataFallbackConfigEmployees,
  tooltipDataFallbackConfigProduct,
} from "./constants/fallbackConfigs.js"
import Message from "../../Message.jsx"
import {
  calculateDaysInRange,
  getDefaultOption,
  rangeOptions,
} from "../../viewBy_re_re/utils.js"
import HeaderSection from "./components/widget/HeaderSection.jsx"
import BodySection from "./components/widget/BodySection.jsx"
import MacroMetricsSection from "./components/widget/MacroMatricsSection.jsx"
import FooterSection from "./components/widget/FooterSection.jsx"
import { HeatmapChartParameters } from "./constants/dataFallBack.js"

const HeatmapContainer = memo(
  ({
    title,
    description,
    route,
    setChartData,
    postUrl,
    reportUid,
    configurations,
  }) => {
    const fallbackconfig =
      configurations.length === 1 ? HeatmapChartParameters : configurations

    const dispatch = useDispatch()
    const chartRef = useRef(null)
    const dateRangeData = useSelector((state) => state.calendar)
    const comparisonPeriod = useSelector((state) => state.period.selectedValue)
    let transformedConfigs = extractKeyValuePairs(fallbackconfig)

    let reportState =
      useSelector((state) => {
        return state[route]?.reports?.find(
          (report) => report.report_uid === reportUid
        )
      }) || {}

    const cacheKey = generateCacheKey(
      reportUid,
      dateRangeData.fromDate,
      dateRangeData.toDate,
      comparisonPeriod.previousFromDate,
      comparisonPeriod.previousToDate,
      reportState.viewBy
    )

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const configOverrides = [
      { key: "macro-metrics", fallback: macroMetricsFallbackConfig(reportUid) },
      { key: "pdfFields", fallback: PDFFieldsFallbackConfig },
      {
        key: "chartContainerDimensions",
        fallback: chartContainerDimentionsFallbackConfig,
      },
      // { key: "tooltip_data", fallback: tooltipDataFallbackConfig },
      {
        key: "showTooltipPrevious",
        fallback:
          transformedConfigs.showTooltipPrevious === undefined
            ? true
            : transformedConfigs.showTooltipPrevious,
      },
      {
        key: "tooltip_data",
        fallback: (() => {
          switch (reportUid) {
            case "AF2362DF-8E53-44D3-9052-E2E8603C4D73":
              return tooltipDataFallbackConfigProduct
            case "9748E662-8465-463E-8AD1-8D661A0700D6":
              return tooltipDataFallbackConfigEmployees

            default:
              return tooltipDataFallbackConfig
          }
        })(),
      },
      {
        key: "defaultColor",
        fallback: (() => {
          switch (reportUid) {
            case "1836342B-CDF7-427C-B4E2-1BC5A9BE40F2":
              return defaultColor
            case "A7175E27-290F-4DAE-BB50-ADCF7EC372E2":
              return customerDefaultColor
            case "AF2362DF-8E53-44D3-9052-E2E8603C4D73":
              return ProductDefaultColor
            default:
              return defaultColor
          }
        })(),
      },
    ]

    configOverrides.forEach(({ key, fallback }) => {
      transformedConfigs[key] = transformedConfigs[key] || fallback
    })

    const daysInRange = dateRangeData
      ? calculateDaysInRange(dateRangeData.fromDate, dateRangeData.toDate)
      : 0

    const visibleViewByOptions =
      rangeOptions.find(
        (range) => daysInRange >= range.min && daysInRange <= range.max
      )?.options || []

    const defaultViewByOption = getDefaultOption(daysInRange)

    const viewBy = visibleViewByOptions.includes(reportState.viewBy)
      ? reportState.viewBy
      : defaultViewByOption

    useEffect(() => {
      if (reportState.cacheKey === cacheKey) return
      const payload = {
        entityDb: localStorage.getItem("entityDb"),
        fromDate: dateRangeData.fromDate,
        toDate: dateRangeData.toDate,
        reportInstanceId: reportUid,
        comparedBy: {
          previousFromDate: comparisonPeriod.previousFromDate,
          previousToDate: comparisonPeriod.previousToDate,
        },
      }
      if (!!transformedConfigs.view_by_period) {
        payload.viewBy = viewBy
      }
      setLoading(true)
      fetchData(postUrl, payload)
        .then((apiResponse) => {
          dispatch(
            setChartData({
              reportUid,
              response: apiResponse,
              apiResponse,
              transformedChartData: transfromChartData(
                apiResponse,
                transformedConfigs.tooltip_data
              ),
              viewBy,
              cacheKey,
            })
          )
          setLoading(false)
        })
        .catch((err) => {
          console.warn(err)
          setError("Failed to fetch data")
          setLoading(false)
        })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      dateRangeData.fromDate,
      dateRangeData.toDate,
      comparisonPeriod.previousFromDate,
      comparisonPeriod.previousToDate,
      reportState.cacheKey,
      reportState.viewBy,
    ])

    return (
      <>
        <Stack spacing={1} ref={chartRef}>
          <HeaderSection
            route={route}
            reportUid={reportUid}
            title={title}
            description={description}
            setChartData={setChartData}
            children={null}
            config={transformedConfigs}
            reportStateViewBy={reportState.viewBy}
            dateRangeData={dateRangeData}
            parentLoading={loading}
            parentError={error}
            transformedChartData={reportState.apiResponse}
            apiResponse={reportState.apiResponse}
            chartRef={chartRef}
            pdfFields={transformedConfigs.pdfFields}
            pdfTransformFunction={transformDataPDFExport}
            transformDataSheetExport={transformDataSheetExport}
            visibleViewByOptions={visibleViewByOptions}
            defaultViewByOption={defaultViewByOption}
          />

          {loading ? (
            <Message type="loading" />
          ) : error ? (
            <Message message={error} type="error" />
          ) : (
            <>
              <MacroMetricsSection
                config={transformedConfigs["macro-metrics"]}
                data={
                  comparisonPeriod.selectedValue === "Compared By"
                    ? reportState?.apiResponse
                    : reportState?.apiResponse?.reportDataCurrent
                }
              />
              <BodySection
                config={transformedConfigs}
                data={reportState.transformedChartData}
              />
              <FooterSection
                legendsData={[]}
                title={title}
                apiResponse={reportState.apiResponse || []}
              />
            </>
          )}
        </Stack>
      </>
    )
  }
)

export default HeatmapContainer
