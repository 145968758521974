import React, { useState } from "react"
import RouteLoader from "../../../components/loader/RouteLoader"
import RouteTitle from "../../../components/RouteTitle"
import { Box } from "@mui/material"
import DatePicker from "../../../components/DatePicker"
import { useSelector } from "react-redux"
import { useTheme } from "../../../contexts/theme"
import Table from "../../../components/datagrid/Table"
import { getFormattedValue } from "../../../utils/utils"
import {
  TargetAudiencesData,
  TargetAudiencesParameters,
} from "./Campaigns/staticTableData/viewCapaigns"
import BlurEffect from "../../../components/BlurEffect"

const TargetAudience = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { theme } = useTheme()
  const dateRangeData = useSelector((state) => state.calendar)
  const [selectedRows, setSelectedRows] = useState([])
  const [open, setOpen] = useState(false)
  const svgColor = theme.palette.accent

  const handleOpen = () => {
    setOpen(true)
  }

  const staticData = [
    {
      id: 1,
      Audience: "Loyal Cuatomer",
      "Criteria/Filters": "Age: 25-34, Gender: Male",
      CreatedDate: "2023-12-20",
      TotalAudience: 5000,
      EngagementRate: "75%",
      ConversionRate: "10%",
      Notes: "High engagement group",
    },
    {
      id: 2,
      Audience: "New Sign UPs",
      "Criteria/Filters": "Location: US, Interests: Tech",
      CreatedDate: "2023-12-18",
      TotalAudience: 8000,
      EngagementRate: "60%",
      ConversionRate: "8%",
      Notes: "Potential for upsell",
    },
    {
      id: 3,
      Audience: "InActive Customers",
      "Criteria/Filters": "Age: 18-24, Gender: Female",
      CreatedDate: "2023-12-15",
      TotalAudience: 3000,
      EngagementRate: "85%",
      ConversionRate: "12%",
      Notes: "Ideal For Retention",
    },
    {
      id: 4,
      Audience: "Holiday Shoppers",
      "Criteria/Filters": "Age: 18-24, Gender: Female",
      CreatedDate: "2023-12-15",
      TotalAudience: 3000,
      EngagementRate: "85%",
      ConversionRate: "12%",
      Notes: "Ideal For Retention",
    },
    {
      id: 5,
      Audience: "Premium Customers",
      "Criteria/Filters": "Age: 18-24, Gender: Female",
      CreatedDate: "2023-12-15",
      TotalAudience: 3000,
      EngagementRate: "85%",
      ConversionRate: "12%",
      Notes: "Ideal For Retention",
    },
    {
      id: 6,
      Audience: "Social Media Followers",
      "Criteria/Filters": "Age: 18-24, Gender: Female",
      CreatedDate: "2023-12-15",
      TotalAudience: 3000,
      EngagementRate: "85%",
      ConversionRate: "12%",
      Notes: "Ideal For Retention",
    },
    {
      id: 7,
      Audience: "InActive Customers",
      "Criteria/Filters": "Age: 18-24, Gender: Female",
      CreatedDate: "2023-12-15",
      TotalAudience: 3000,
      EngagementRate: "85%",
      ConversionRate: "12%",
      Notes: "Ideal For Retention",
    },
  ]

  return (
    <>
      <BlurEffect showBlur={true} />

      <RouteTitle title="Target Audience">
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          maxWidth="auto"
          gap={2}
        >
          <DatePicker />
        </Box>
      </RouteTitle>

      <Box padding="15px 30px 30px 30px">
        <Table
          title="Target Audience"
          showExportButton={false}
          StaticData={TargetAudiencesData}
          StaticParameters={TargetAudiencesParameters}
        />
      </Box>
    </>
    //   )}
    // </>
  )
}

export default TargetAudience
