import React from "react"
import RouteTitle from "../../../../components/RouteTitle"
import { Box, Grid } from "@mui/material"
import DatePicker from "../../../../components/DatePicker"
import CustomBreadcrumbs from "../../../../components/CustomBreadcrumbs"
import CustomPaper from "../../../../components/CustomPaper"
import Filter from "../../Filter"
import Table from "../../../../components/datagrid/Table"
import {
  ViewCampaignData,
  ViewCampaignParameters,
} from "./staticTableData/viewCapaigns"
import { useNavigate } from "react-router-dom"
import BlurEffect from "../../../../components/BlurEffect"

const ViewCampaigns = () => {
  const navigate = useNavigate()
  const handleCreateCampgain = () => {
    navigate("/submenu/marketing/createcampaign")
  }

  return (
    <>
      <BlurEffect showBlur={true} />

      <RouteTitle title="Campaigns">
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          maxWidth="auto"
          gap={2}
        >
          <DatePicker />
        </Box>
      </RouteTitle>
      <Box padding="0px 30px ">
        <CustomBreadcrumbs
          breadcrumbItems={[
            {
              text: "Campaigns",
            },
            {
              text: "Draft Campaigns",
              isActive: true,
            },
          ]}
        />

        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          sx={{ mt: 1 }}
        >
          <Grid item xs={12} md={4} lg={3}>
            <CustomPaper customStyles={{ paddingX: "unset" }}>
              <Filter />
            </CustomPaper>
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <Table
              title="Draft Campaigns"
              buttonName="+ Create Campaign"
              showButton={handleCreateCampgain}
              showExportButton={false}
              StaticData={ViewCampaignData}
              StaticParameters={ViewCampaignParameters}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default ViewCampaigns
