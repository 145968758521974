import { getDateFormatFromLocalStorage } from "../../../../utils/dateUtils"

import dayjs from "dayjs"
import { swarmPlotTooltip } from "./configServices"

export function transformChartData(data, tooltip_data) {
  const tooltipConfig = tooltip_data.reduce((acc, { name, format }) => {
    const normalizedKey = name.toLowerCase().replace(/\s+/g, "")
    acc[normalizedKey] = { format, originalKey: name }
    return acc
  }, {})

  const currentData = data?.reportDataCurrent || data || []
  const previousData = data?.reportDataPrevious || []

  const transformedData = currentData.map((currentItem, index) => {
    const tooltipData = {
      current: {},
    }

    Object.keys(currentItem).forEach((key) => {
      const normalizedKey = key.toLowerCase().replace(/\s+/g, "")
      const config = tooltipConfig[normalizedKey]

      if (config) {
        tooltipData.current[config.originalKey] = {
          value: currentItem[key],
          format: config.format || false,
        }
      }
    })

    if (previousData.length > 0) {
      const previousItem = previousData.find(
        (prev) => prev.id === currentItem.id
      )
      if (previousItem) {
        tooltipData.previous = {}
        Object.keys(previousItem).forEach((key) => {
          const normalizedKey = key.toLowerCase().replace(/\s+/g, "")
          const config = tooltipConfig[normalizedKey]

          if (config) {
            tooltipData.previous[config.originalKey] = {
              value: previousItem[key],
              format: config.format || false,
            }
          }
        })
      }
    }

    const hasValidTooltipData = Object.keys(tooltipData.current).length > 0

    if (!hasValidTooltipData) {
      return null
    }

    return {
      id: `0.${index + 1}`,
      group: currentItem.PaymentMethod || currentItem.Employee,
      product: currentItem.TotalCustomers || currentItem.Product,
      tooltipData,
      ...currentItem,
    }
  })

  return transformedData.filter(Boolean)
}

export function transformDataPDFExport(apiResponse, pdfFields, reportUid) {
  const transformedData = []

  const currentData = apiResponse?.reportDataCurrent || apiResponse
  const previousData = apiResponse?.reportDataPrevious || []

  if (!Array.isArray(currentData)) {
    console.warn("Current data is missing or not an array")
    return transformedData
  }

  const currentLength = currentData.length

  const tooltip_data = pdfFields.tooltip_data || swarmPlotTooltip

  const dateFormat = getDateFormatFromLocalStorage()

  for (let i = 0; i < currentLength; i++) {
    const current = currentData[i]
    const previous = previousData[i] || {}

    const transformedWeek = {
      date:
        current["WeekMonthYear1"] ||
        (current["date"]
          ? dayjs(current["date"]).format(dateFormat)
          : current["id"]),
    }

    tooltip_data.forEach((tooltip) => {
      const name = tooltip.name
      const currentVal = current[name] || 0

      if (Object.keys(previous).length > 0) {
        const previousVal = previous[name] || 0

        transformedWeek[name] = {
          current:
            tooltip.format === "Currency"
              ? `$${currentVal.toLocaleString()}`
              : currentVal.toString(),
          previous:
            previousVal > 0
              ? tooltip.format === "Currency"
                ? `$${previousVal.toLocaleString()}`
                : previousVal.toString()
              : tooltip.format === "Currency"
              ? "$0"
              : "0",
          changePercent:
            previousVal === 0
              ? "-"
              : (((currentVal - previousVal) / previousVal) * 100).toFixed(2) +
                "%",
        }
      } else {
        transformedWeek[name] = {
          current:
            tooltip.format === "Currency"
              ? `$${currentVal.toLocaleString()}`
              : currentVal.toString(),
        }
      }
    })

    transformedData.push(transformedWeek)
  }

  return transformedData
}

export const transformDataSheetExport = (dataArray) =>
  dataArray?.map((item) => {
    const { tooltipData, ...rest } = item

    let flattenedTooltipData = {}

    if (tooltipData) {
      Object.entries(tooltipData).forEach(([key, value]) => {
        Object.entries(value).forEach(([subKey, subValue]) => {
          const newKey = `${key} ${subKey} `.replace(/\s+/g, "")
          flattenedTooltipData[newKey] = subValue.value
        })
      })
    }

    return {
      ...rest,
      ...flattenedTooltipData,
    }
  })
