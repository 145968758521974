import React from "react"
import { useTheme } from "../../../../../contexts/theme"
import { Box } from "@mui/material"
import { ResponsiveHeatMap } from "@nivo/heatmap"
import CustomTooltip from "../../../../CustomTooltip"

const BodySection = ({ data = [], config = {}, showTooltipPrevious }) => {
  const { theme } = useTheme()
  const itemTextColor = theme.palette.mode === "dark" ? "#fff" : "#333333"
  const {
    margin,
    valueFormat,
    axisTop,
    axisRight,
    axisLeft,
    colors,
    legends,
    defaultColor,
    chartContainerDimensions = {
      height: "350px",
    },
  } = config

  const customTheme = {
    text: {
      fill: itemTextColor,
    },
    tooltip: {
      container: {
        background: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
      },
    },
    grid: {
      line: {
        stroke: "rgba(29, 71, 206, 0.3)",
        strokeWidth: 0.93,
        top: 359.59,
      },
    },
    axis: {
      ticks: {
        line: {
          transform: "translateY(-2px)",
        },
        text: {
          fontSize: 10,
          fill: itemTextColor,
          outlineWidth: 0,
          outlineColor: "transparent",
        },
      },
    },
  }

  // Find highest and lowest performer

  return (
    <Box sx={{ ...chartContainerDimensions }}>
      <ResponsiveHeatMap
        animate={false}
        motionConfig="none"
        data={data}
        theme={customTheme}
        valueFormat={valueFormat}
        margin={margin}
        axisTop={axisTop}
        axisRight={axisRight}
        axisLeft={axisLeft}
        colors={defaultColor ? defaultColor : colors}
        legends={legends}
        tooltip={({ cell }) => (
          <CustomTooltip
            data={cell.data}
            showCurrentPrevDate={true}
            heatmapCell={cell}
            showTooltipPrevious={showTooltipPrevious}
          />
        )}
      />
    </Box>
  )
}

export default BodySection
