import Box from "@mui/material/Box"
import CustomTooltip from "../../../../CustomTooltip"
import { useTheme } from "../../../../../contexts/theme"
import { ResponsiveBump } from "@nivo/bump"

const BodySection = ({ data, config }) => {
  let {
    inactiveLineWidth,
    margin,
    inactiveOpacity,
    pointSize,
    axisTop,
    activePointSize,
    axisBottom,
    axisLeft,
    inactivePointSize,
    pointColor,
    pointBorderColor,
    activePointBorderWidth,
    pointBorderWidth,
    activeLineWidth,
    lineWidth,
    colors,
  } = config
  const { theme } = useTheme()

  const itemTextColor = theme.palette.mode === "dark" ? "#fff" : "#333333"

  const customTheme = {
    text: {
      fill: itemTextColor,
    },
    tooltip: {
      container: {
        background: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
      },
    },
    grid: {
      line: {
        stroke: "rgba(29, 71, 206, 0.3)",
        strokeWidth: 0.93,
        top: 359.59,
      },
    },
    axis: {
      ticks: {
        text: {
          fontSize: 10,
          fill: itemTextColor,
          outlineWidth: 0,
          outlineColor: "transparent",
        },
      },
    },
  }

  return (
    <>
      <Box height="350px">
        <ResponsiveBump
          animate={false}
          motionConfig="none"
          data={data}
          theme={customTheme}
          colors={colors}
          lineWidth={lineWidth}
          activeLineWidth={activeLineWidth}
          inactiveLineWidth={inactiveLineWidth}
          inactiveOpacity={inactiveOpacity}
          pointSize={pointSize}
          activePointSize={activePointSize}
          inactivePointSize={inactivePointSize}
          pointColor={pointColor}
          pointBorderWidth={pointBorderWidth}
          activePointBorderWidth={activePointBorderWidth}
          pointBorderColor={pointBorderColor}
          axisTop={axisTop}
          axisBottom={axisBottom}
          axisLeft={axisLeft}
          margin={margin}
          axisRight={null}
          useMesh={true}
          pointTooltip={(point) => (
            <CustomTooltip data={point.point.data} showCurrentPrevDate={true} />
          )}
        />
      </Box>
    </>
  )
}

export default BodySection
