import React, { useState, useEffect } from "react"
import { useGoogleLogin } from "@react-oauth/google"
import { getData } from "../../../api/api"
import { Box, Button, TextField, Typography } from "@mui/material"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import InfoToolTip from "../../../components/InfoToolTip"
import { useTheme } from "../../../contexts/theme"
import { LoadingButton } from "@mui/lab"
import { useNavigate } from "react-router-dom"
import Google from "../../../assets/svgs/Google"

const isValidValue = (value) =>
  value !== null && value !== undefined && value !== ""

const GoogleOAuth = () => {
  const { theme } = useTheme()
  const navigate = useNavigate()
  const [businessName, setBusinessName] = useState("")
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [userInfo, setUserInfo] = useState(null)
  const [showSubmitSection, setShowSubmitSection] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [isBusinessNameSaved, setIsBusinessNameSaved] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)

  useEffect(() => {
    const storedUserInfo = localStorage.getItem("googleBusinessName")
    const storedBusinessData = localStorage.getItem("googlePlaceId")

    if (storedUserInfo) {
      setIsLoggedIn(true)
      setUserInfo(storedUserInfo)
      setShowSubmitSection(true)
    }

    if (storedBusinessData) {
      setBusinessName(storedUserInfo)
      setIsBusinessNameSaved(true)
    }
  }, [])

  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      localStorage.setItem("googleAccessToken", tokenResponse.access_token)

      fetch("https://www.googleapis.com/oauth2/v3/userinfo", {
        headers: {
          Authorization: `Bearer ${tokenResponse.access_token}`,
        },
      })
        .then((response) => response.json())
        .then((userInfo) => {
          localStorage.setItem("googleUserInfo", JSON.stringify(userInfo))
          setUserInfo(userInfo)
          setIsLoggedIn(true)
          setShowSubmitSection(true)
        })
        .catch((err) => console.error("Failed to fetch user info:", err))
    },
    onError: (error) => {
      console.error("Google Login Failed:", error)
    },
    scope: "openid profile email",
  })

  const handleSubmit = async () => {
    if (!isValidValue(businessName)) {
      setErrorMessage("Please enter a valid business name!")
      return
    }

    setErrorMessage("")

    const entityId = localStorage.getItem("entityId")
    if (!isValidValue(entityId)) {
      alert("Missing Entity ID. Please set it before proceeding.")
      return
    }

    setIsLoading(true)

    try {
      const googleAccessToken = localStorage.getItem("token")
      if (!googleAccessToken) {
        alert("Missing Google Access Token. Please try again.")
        setIsLoading(false)
        return
      }

      const response = await getData(
        `google/place-id?businessName=${businessName}&entityId=${entityId}`,
        {
          headers: {
            Authorization: `Bearer ${googleAccessToken}`,
          },
        }
      )

      if (response && response.googleBusinessPlaceId) {
        const { placeId, businessName: responseBusinessName } =
          response.googleBusinessPlaceId

        localStorage.setItem("googleBusinessName", responseBusinessName)
        localStorage.setItem("googlePlaceId", placeId)

        setIsBusinessNameSaved(true)
        setIsEditMode(false)
        alert("Google Authentication Data updated successfully!")
        navigate("/customers/dashboard/customerSentiment")
      } else {
        console.error("Invalid API response:", response)
        alert("Failed to store data. Invalid response.")
      }
    } catch (error) {
      console.error("Error submitting data:", error)
      alert("Something went wrong while submitting data.")
    } finally {
      setIsLoading(false)
    }
  }

  const handleEdit = () => {
    setIsEditMode(true)
    setIsBusinessNameSaved(false)
  }

  return (
    <>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography color="inherit">
          Google Authentication and Add Business Name
        </Typography>
        <InfoToolTip title="Add a business name to enable Google Authentication functionality">
          <span style={{ height: "16px", cursor: "pointer" }}>
            <InfoLogoSvg svgColor={theme.palette.accentSecondary} />
          </span>
        </InfoToolTip>
      </Box>

      <Box mt={2} mb={2}>
        <Button
          sx={{
            color: theme.palette.accent,
            padding: "10px 19px",
            borderRadius: 10,
            border: "2px solid ",
            borderColor: theme.palette.accent,
            width: "8rem",
            textTransform: "none",
            "& .MuiCircularProgress-colorInherit": {
              color: "white",
            },
          }}
          onClick={() => googleLogin()}
          startIcon={<Google />}
          disabled={isLoggedIn}
        >
          Google
        </Button>
      </Box>

      {showSubmitSection && (
        <>
          <TextField
            id="business-name"
            label="Business Name"
            variant="standard"
            value={businessName}
            onChange={(e) => setBusinessName(e.target.value)}
            error={!!errorMessage}
            helperText={errorMessage}
            disabled={isBusinessNameSaved && !isEditMode}
          />

          <Box mt={2} display="flex" justifyContent="space-between">
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              loading={isLoading}
              disabled={isBusinessNameSaved && !isEditMode}
              sx={{
                backgroundColor: theme.palette.accent,
                padding: "10px 19px",
                borderRadius: 8,
                color: "#fff",
                width: "20%",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: theme.palette.accent,
                  color: "",
                },
                "&:disabled": {
                  backgroundColor: "#fff",
                  color: "#B6B7BC",
                },

                "& .MuiCircularProgress-colorInherit": {
                  color: theme.palette.accent,
                },
              }}
            >
              Submit
            </LoadingButton>

            {!isEditMode && isBusinessNameSaved && (
              <Button
                variant="text"
                onClick={handleEdit}
                sx={{
                  backgroundColor: theme.palette.accent,
                  padding: "10px 19px",
                  borderRadius: 6,
                  color: "#fff",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: theme.palette.accent,
                  },
                  "& .MuiCircularProgress-colorInherit": {
                    color: theme.palette.accent,
                  },
                }}
              >
                Edit
              </Button>
            )}
          </Box>
        </>
      )}
    </>
  )
}

export default GoogleOAuth
