import React, { useState, useEffect } from "react"
import {
  Box,
  Typography,
  Rating,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
} from "@mui/material"
import { useTheme } from "../../../contexts/theme"
import CustomPaper from "../../../components/CustomPaper"
import RatingCard from "./RatingCard"
import Table from "../../../components/datagrid/Table"
import { getData } from "../../../api/api"
import Message from "../../../components/Message"
import YelpLogo from "../../../assets/svgs/YelpLogo"

const YelpInsight = () => {
  const { theme } = useTheme()
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [selectedReview, setSelectedReview] = useState(null)
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  useEffect(() => {
    const fetchReviews = async () => {
      setLoading(true)
      try {
        const yelpBusinessId = localStorage.getItem("yelpBusinessId")
        const token = localStorage.getItem("token")

        if (!yelpBusinessId || !token) {
          console.error(
            "Missing required parameters (businessId, entityId, or token)."
          )
          setLoading(false)
          return
        }

        const businessId = yelpBusinessId

        const response = await getData(`yelp/review?businessId=${businessId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })

        if (response) {
          setData(response)
        } else {
          console.error("Failed to fetch reviews. Invalid response.")
        }
      } catch (error) {
        console.error("Error fetching reviews:", error)
      } finally {
        setLoading(false)
      }
    }

    fetchReviews()
  }, [])

  const handleRowClick = (row) => {
    setSelectedReview(row.row)
    setIsDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setIsDialogOpen(false)
    setSelectedReview(null)
  }

  if (loading)
    return (
      <Message
        type="loading"
        message="Fetching Google Reviews, please wait..."
      />
    )
  if (!data) {
    return (
      <Message
        type="error"
        message={
          <>
            <CustomPaper
              customStyles={{
                width: "35vw",
                background:
                  theme.palette.mode === "dark"
                    ? "linear-gradient(75.66deg, #153391 1.45%, #0D2266 95.77%)"
                    : "#FBFBFE",
                boxShadow: 24,
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  textAlign: "center",
                  fontWeight: "bold",
                  fontSize: 22,
                  p: 1,
                }}
              >
                No Yelp Reviews Available
              </Typography>
              <Typography>
                To proceed, please ensure your business details are set up
                correctly.
              </Typography>
              <Typography>
                👉 Click the link below to search for your business name and
                location:
                <a
                  href="/ConfigurationAuth"
                  style={{ color: "#2FB876", textDecoration: "underline" }}
                >
                  Search Business
                </a>
              </Typography>
            </CustomPaper>
          </>
        }
      />
    )
  }

  const reviewColumns = [
    {
      field: "profile_photo_url",
      headerName: "Profile Photo",
      flex: 0.5,
      minWidth: 100,
      headerClassName: "datagrid-header",
      renderCell: (params) => {
        const imageUrl =
          params.value && params.value !== "" ? params.value : null
        return (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <img
              src={imageUrl || "https://www.yelp.com/favicon.ico"}
              alt="Profile"
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "50%",
              }}
            />
          </Box>
        )
      },
    },

    {
      field: "author_name",
      headerName: "Author Name",
      flex: 1,
      minWidth: 150,
      headerClassName: "datagrid-header",
    },
    {
      field: "rating",
      headerName: "Rating",
      flex: 0.5,
      minWidth: 100,
      headerClassName: "datagrid-header",
      renderCell: (params) => (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Rating
            value={params.value}
            precision={0.1}
            readOnly
            size="small"
            sx={{
              "& .MuiRating-iconFilled": {
                color: "#FFD700",
              },
              "& .MuiRating-iconEmpty": {
                color: "#ddd",
              },
            }}
          />
        </Box>
      ),
    },

    {
      field: "text",
      headerName: "Review Text",
      flex: 2,
      minWidth: 300,
      headerClassName: "datagrid-header",
    },
    {
      field: "relative_time_description",
      headerName: "Time",
      flex: 0.5,
      minWidth: 150,
      headerClassName: "datagrid-header",
    },
  ]

  const formattedReviews = data.reviews.map((review, index) => ({
    id: index,
    profile_photo_url: review.profile_photo_url,
    author_name: review.author_name,
    rating: review.rating,
    text: review.text,
    relative_time_description: review.relative_time_description,
  }))

  return (
    <CustomPaper
      customStyles={{
        background: theme.palette.mode === "dark" ? "#000" : "#1b4cf00a",
      }}
    >
      <YelpLogo />
      <CustomPaper>
        <Box display="flex" justifyContent="space-between">
          {/* <Typography>{data.placeName}</Typography> */}
        </Box>
        <Stack gap={2}>
          <CustomPaper
            customStyles={{
              background:
                theme.palette.mode === "dark" ? "#071339" : "#1b4cf00a",
            }}
          >
            <RatingCard totalReviews={data.totalReviews} rating={data.rating} />
          </CustomPaper>
        </Stack>
      </CustomPaper>

      {/* Reviews Table */}
      <Box mt={2}>
        <Box
          sx={{
            height: "auto",
            width: "100%",
          }}
        >
          <Table
            title="Latest Reviews"
            showFiltersButton={false}
            showExportButton={false}
            showSearchBar={false}
            showFooter={true}
            StaticData={{ data: formattedReviews }}
            parameters={[
              {
                parameterType: "COLUMNS",
                reportParameters: [
                  { parameterName: "columns", parameterValue: reviewColumns },
                ],
              },
            ]}
            route="reviews"
            checkboxSelection={false}
            onRowClick={(row) => handleRowClick(row)}
          />
        </Box>
      </Box>

      {/* Dialog for Review Details */}
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "12px",
          },
          pt: 10,
        }}
      >
        <CustomPaper
          customStyles={{
            background:
              theme.palette.mode === "dark"
                ? "linear-gradient(75.66deg, #153391 1.45%, #0D2266 95.77%)"
                : "#FBFBFE",
            boxShadow: 24,
            borderRadius: "12px",
          }}
        >
          <DialogTitle
            sx={{ fontWeight: "bold", fontSize: "24px", textAlign: "center" }}
          >
            Review Details
          </DialogTitle>
          <DialogContent>
            {selectedReview && (
              <Stack spacing={3} sx={{ padding: "16px" }}>
                {/* Profile Section */}
                <Box display="flex" alignItems="center" gap={2}>
                  <img
                    src={
                      selectedReview.profile_photo_url ||
                      "https://www.yelp.com/favicon.ico"
                    }
                    alt="Profile"
                    style={{
                      width: "80px",
                      height: "80px",
                      borderRadius: "50%",
                      border: "2px solid #ddd",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                    }}
                  />
                  <Typography
                    component="div"
                    variant="h5"
                    sx={{ fontWeight: "bold" }}
                  >
                    {selectedReview.author_name}
                  </Typography>
                </Box>

                {/* Rating Section */}
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography component="div" sx={{ fontWeight: "bold" }}>
                      Rating:
                    </Typography>
                    <Rating
                      value={selectedReview.rating}
                      precision={0.1}
                      readOnly
                      size="medium"
                      sx={{
                        "& .MuiRating-iconFilled": {
                          color: "#FFD700",
                        },
                        "& .MuiRating-iconEmpty": {
                          color: "#ddd",
                        },
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography
                      component="div"
                      variant="body2"
                      // sx={{ color: "#333" }}
                    >
                      {selectedReview.relative_time_description}
                    </Typography>
                  </Box>
                </Box>

                {/* Review Text Section */}
                <Box
                  sx={{
                    padding: "16px",
                    background:
                      theme.palette.mode === "dark" ? "#000000" : "#fff",
                    borderRadius: "8px",
                    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Typography component="div" sx={{ fontWeight: "bold" }}>
                    {selectedReview.author_name} Review :
                  </Typography>
                  <Typography variant="body1" sx={{ lineHeight: 1.5 }}>
                    {selectedReview.text}
                  </Typography>
                </Box>
              </Stack>
            )}

            {/* Footer Button */}
            <Box mt={3} display="flex" justifyContent="flex-end">
              <Button
                onClick={handleCloseDialog}
                color="primary"
                variant="contained"
                sx={{
                  textTransform: "none",
                  borderRadius: "8px",
                  padding: "8px 16px",
                }}
              >
                Close
              </Button>
            </Box>
          </DialogContent>
        </CustomPaper>
      </Dialog>
    </CustomPaper>
  )
}

export default YelpInsight
