import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { Rating } from "@mui/material"

const RatingCard = ({ totalReviews, rating }) => {
  return (
    <>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={2}>
          <Box>
            <Typography whiteSpace={"nowrap"} sx={{ fontSize: "1.2rem" }}>
              Overall Rating
            </Typography>
            {/* <Typography sx={{ fontWeight: "bold", fontSize: "2.6rem" }}>
              {totalReviews}
            </Typography> */}
          </Box>
        </Grid>

        <Grid item xs={12} sm={10}>
          <Box display="flex" alignItems="right" justifyContent="flex-end">
            <Typography
              variant="h3"
              sx={{
                fontSize: "33px",
                marginRight: "8px",
                fontWeight: "bold",
                color: "inherit",
              }}
            >
              {rating}
            </Typography>
            <Rating
              value={rating}
              precision={0.1}
              readOnly
              size="large"
              sx={{
                "& .MuiRating-iconFilled": {
                  color: "#FFD700",
                },
                "& .MuiRating-iconEmpty": {
                  color: "#1B2A49",
                },
              }}
            />
          </Box>
        </Grid>
        {/* <Grid item xs={12} sm={9}>
          <Grid container spacing={3} justifyContent="space-between">
            <Grid item xs={4} textAlign="center">
              <HappySentiments />
              <Typography variant="body1">70 %</Typography>
            </Grid>

            <Grid item xs={4} textAlign="center">
              <SentimentNeutral />
              <Typography variant="body1">28 %</Typography>
            </Grid>

            <Grid item xs={4} textAlign="center">
              <SentimentDissatisfied />
              <Typography variant="body1">2 %</Typography>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </>
  )
}

export default RatingCard
