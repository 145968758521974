export const tooltipDataFallbackConfig = [
  {
    name: "Gross Sales",
    format: "Currency",
    color: "",
  },
  {
    name: "Customers",
    format: "Number",
    color: "",
  },
  {
    name: "Transactions",
    format: "Number",
    color: "",
  },
  {
    name: "Average Ticket",
    format: "Currency",
    color: "",
  },
  {
    name: "value",
    format: "Number",
    color: "",
  },
  {
    name: "Tips",
    format: "Currency",
    color: "",
  },
  {
    name: "Hours",
    format: "Number",
    color: "",
  },
  {
    name: "Sales Quantity",
    format: "Number",
    color: "",
  },
]

export const colorScheme = [
  "#36a2eb",
  "#FF6384",
  "#17a2b8",
  "#a17bc8",
  "#8dd3c7",
  "#fccde5",
  "#2271B4",
  "#6E8B3D",
  "#C75D8A",
  "#DB7093",
  "#FF8C00",
  "#9370DB",
  "#FFE4B5",
  "#BA55D3",
  "#B0E0E6",
  "#8B4513",
  "#00CED1",
  "#CD5C5C",
  "#3CB371",
  "#A7CEFA",
  "#BDB76B",
  "#D2691E",
  "#00FA9A",
  "#F4A460",
  "#FF69B4",
  "#8A2BE2",
  "#2E8B57",
]

export const chartContainerDimentionsFallbackConfig = {
  height: "350px",
  minHeight: "350px",
  maxHeight: "unset",
}

export const PDFFieldsFallbackConfig = [
  {
    name: "Gross Sales",
    format: "Currency",
    color: "",
  },
  {
    name: "Customers",
    format: "Number",
    color: "",
  },
  {
    name: "Transactions",
    format: "Number",
    color: "",
  },
  {
    name: "Average Ticket",
    format: "Number",
    color: "",
  },
  {
    name: "value",
    format: "Number",
    color: "",
  },
  {
    name: "Tips",
    format: "Currency",
    color: "",
  },
  {
    name: "Hours",
    format: "Number",
    color: "",
  },
  {
    name: "Sales Quantity",
    format: "Number",
    color: "",
  },
]
