import { useTheme } from "../../contexts/theme"


const DollarIcon = () => {
    const { theme } = useTheme()
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_8601_14206)">
                <path d="M7.22656 11.9422C7.22656 13.0172 8.05156 13.8838 9.07658 13.8838H11.1682C12.0599 13.8838 12.7849 13.1255 12.7849 12.1922C12.7849 11.1755 12.3432 10.8172 11.6849 10.5838L8.32655 9.41715C7.66822 9.18382 7.22656 8.82549 7.22656 7.80885C7.22656 6.87552 7.95155 6.11719 8.84325 6.11719H10.9349C11.9599 6.11719 12.7849 6.98385 12.7849 8.05885" stroke={theme.palette.accent} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M10 5V15" stroke={theme.palette.accent} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path opacity="0.6" d="M10.0003 18.3307C14.6027 18.3307 18.3337 14.5997 18.3337 9.99739C18.3337 5.39502 14.6027 1.66406 10.0003 1.66406C5.39795 1.66406 1.66699 5.39502 1.66699 9.99739C1.66699 14.5997 5.39795 18.3307 10.0003 18.3307Z" stroke={theme.palette.accent} stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_8601_14206">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default DollarIcon