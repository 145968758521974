import { ResponsiveBar } from "@nivo/bar"
import { useTheme } from "../../../contexts/theme"
import CustomTooltip from "../../CustomTooltip"
import CustomLegend from "./CustomLegend"

const NivoStackedBarChart = ({
  chartInput,
  parameters,
  showlegend,
  showAiDropdown,
  showTooltipPrevious,
}) => {
  const legendData = [
    { label: "Normal", color: "#50CD89" },
    { label: "Positive", color: "#FEBE10" },
    { label: "Negative", color: "#EE000C" },
  ]
  const { theme } = useTheme()
  let {
    margin,
    padding,
    borderRadius,
    enableLabel,
    valueScale,
    colors,
    axisLeft,
    axisBottom,
    indexBy,
    keys,
    indexScale,
  } = parameters

  const itemTextColor =
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, 0.50)" : "#333333"

  const customTheme = {
    tooltip: {
      container: {
        background: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
      },
    },
    grid: {
      line: {
        stroke: "rgba(29, 71, 206, 0.3)",
        strokeWidth: 0.93,
        top: 359.59,
      },
    },
    axis: {
      ticks: {
        text: {
          fontSize: 10,
          fill: itemTextColor,
          outlineWidth: 0,
          outlineColor: "transparent",
        },
      },
    },
  }

  return (
    <div style={{ height: "345px" }}>
      <ResponsiveBar
        animate={false}
        motionConfig="none"
        data={chartInput}
        theme={customTheme}
        keys={keys}
        indexBy={indexBy}
        enableLabel={enableLabel}
        margin={margin}
        padding={padding}
        borderRadius={borderRadius}
        valueScale={valueScale}
        indexScale={indexScale}
        colors={colors}
        axisBottom={axisBottom}
        axisLeft={axisLeft}
        tooltip={(point) => (
          <CustomTooltip
            data={point.data}
            showCurrentPrevDate={true}
            showTooltipPrevious={showTooltipPrevious}
          />
        )}
      />
      {showlegend && (
        <CustomLegend
          legendsData={legendData}
          showAiDropdown={showAiDropdown}
        />
      )}
    </div>
  )
}

export default NivoStackedBarChart
