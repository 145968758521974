import React, { useState } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import ArrowRise from "../../../assets/svgs/ArrowRise"
import ArrowFall from "../../../assets/svgs/ArrowFall"

const DataTable = () => {
  const [data, setData] = useState({
    Mail: {
      "Total-Campaigns": { value: "15", change: "" },
      "Messages-Sent": { value: "4,000", change: "" },
      "Open-Rate": { value: "50%", change: "up" },
      "Click-Through-Rate": { value: "30%", change: "up" },
      "Avg-Cost-Per-Campaign": { value: "Free", change: "" },
    },
    SMS: {
      "Total-Campaigns": { value: "20", change: "" },
      "Messages-Sent": { value: "5,000", change: "" },
      "Open-Rate": { value: "40%", change: "up" },
      "Click-Through-Rate": { value: "28%", change: "up" },
      "Avg-Cost-Per-Campaign": { value: "$50", change: "" },
    },
  })

  const renderChange = (change) => {
    if (change === "up") {
      return <ArrowRise />
    } else if (change === "down") {
      return <ArrowFall />
    }
    return null
  }

  // Extract regions (columns) and metrics (rows) dynamically
  const regions = Object.keys(data) // Correctly access keys of `data`
  const rowKeys = regions.length > 0 ? Object.keys(data[regions[0]]) : []

  return (
    <Table aria-label="dynamic table">
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          {regions.map((region) => (
            <TableCell key={region} sx={{ minWidth: "100px" }} align="left">
              {region}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {rowKeys.map((key) => (
          <TableRow key={key}>
            <TableCell sx={{ minWidth: "100px" }} component="th" scope="row">
              {key.charAt(0).toUpperCase() + key.slice(1)}
            </TableCell>
            {regions.map((region) => {
              const cellData = data[region][key] || {} // Safeguard against missing data
              return (
                <TableCell
                  key={`${region}-${key}`}
                  sx={{ minWidth: "100px" }}
                  align="left"
                >
                  <span style={{ fontWeight: "bold" }}>
                    {cellData.value || "N/A"}
                  </span>
                  {renderChange(cellData.change)}
                </TableCell>
              )
            })}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default DataTable
