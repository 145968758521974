import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import AddIcon from "@mui/icons-material/Add"
import RouteTitle from "../../../components/RouteTitle"
import CustomPaper from "../../../components/CustomPaper"
import DatePicker from "../../../components/DatePicker"
import { setChartData } from "../../../redux/slices/customerSlice"
import ArrowRise from "../../../assets/svgs/ArrowRise"
import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { useTheme } from "../../../contexts/theme"
import CompareBy from "../../../components/CompareBy"
import { PieChartParameters } from "../../../components/charts/pie/constant/dataFallback"
import BarChartContainer from "../../../components/charts/bar/BarChartContainer"
import PieChartContainer from "../../../components/charts/pie/PieChartContainer"
import MarketingKPICard from "../MarketingKPICard"
import { CustomerBarChartParameters } from "../CustomerBarChartParameters"
import { ReviewClassificationData } from "../ReviewClassficationData"
import TableChart from "../../../components/charts/table/TableChart"
import LineChartContainer from "../../../components/charts/line/LineChartContainer"
import { summeryTableData } from "./Campaigns/staticTableData/viewCapaigns"
import RightIcon from "./../../../assets/svgs/RightIcon"
import BlurEffect from "../../../components/BlurEffect"

const Summary = () => {
  const navigate = useNavigate()
  const comparisonPeriod = useSelector((state) => state.period.selectedValue)

  const colorScheme = [
    "rgba(238, 0, 12, 1)",
    "rgba(252, 191, 73, 1)",
    "rgba(61, 80, 245, 1)",
    "rgba(80, 205, 137, 1)",
    "rgba(161, 123, 200, 1)",
    "rgba(222, 76, 8, 1)",
    "rgba(241, 144, 4, 1)",
    "rgba(252, 185, 50, 1)",
    "rgba(0, 123, 255, 1)",
    "rgba(40, 167, 69, 1)",
    "rgba(255, 193, 7, 1)",
    "rgba(23, 162, 184, 1)",
    "rgba(108, 117, 125, 1)",
    "rgba(233, 236, 239, 1)",
    "rgba(52, 58, 64, 1)",
    "rgba(255, 99, 132, 1)",
  ]

  const orderDetails = [
    {
      title: "No. of Campaigns",
      status: "53",
      numberrise: "Overall",
      icon: null,
    },
    {
      title: "Total Reach",
      status: "10,000",
      numberrise: "Customers",
      icon: "ArrowRise",
    },
    {
      title: "Effectiveness",
      status: "20%",
      numberrise: "Increase in sales",
      icon: "ArrowRise",
    },
    {
      title: "Conversion Matrix",
      status: "10%",
      numberrise: "1k  Customers",
      icon: "ArrowFall",
    },
    {
      title: "Sales Generated",
      status: "$10,000",
      numberrise: "in sales revenue",
      icon: "ArrowRise",
    },
  ]

  const handleCreateCampgain = () => {
    navigate("/submenu/marketing/createcampaign")
  }

  const { theme } = useTheme()
  const svgColor = theme.palette.mode === "dark" ? "#BCCAF6" : "#1D47CE"

  const dateRangeData = useSelector((state) => state.calendar)
  return (
    <>
      <BlurEffect showBlur={true} />
      <RouteTitle>
        <Stack sx={{ maxWidth: { md: "1250px" } }} gap="3px">
          <Typography
            variant="subtitle1"
            color="inherit"
            sx={{ maxWidth: { md: "1250px" } }}
          >
            {"Marketing Portal"}
          </Typography>
          <Box display="flex" flexDirection="row" gap="5px" alignItems="center">
            <Typography
              variant="body2"
              color="inherit"
              fontWeight="fontWeightLight"
              sx={{ fontSize: "0.8rem", opacity: 0.9 }}
            >
              Selected Marketing Channels <RightIcon svgColor={svgColor} />
              Whatsapp <RightIcon svgColor={svgColor} />
              Instagram <RightIcon svgColor={svgColor} /> SMS
            </Typography>
          </Box>
        </Stack>

        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          maxWidth="auto"
          gap={2}
        >
          <CompareBy dateRangeData={dateRangeData} />
          <DatePicker />
        </Box>
      </RouteTitle>

      <Stack padding="15px 30px 15px 30px" gap={2}>
        <Grid container spacing={2} justifyContent="space-between">
          {orderDetails.map((orderDetail) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={1.71428571429}
              key={orderDetail.title}
            >
              <CustomPaper watermarkBg={true} customStyles={{ height: "100%" }}>
                <MarketingKPICard
                  title={orderDetail.title}
                  status={orderDetail.status}
                  numberrise={orderDetail.numberrise}
                  comparisonPeriod={comparisonPeriod}
                  icon={<ArrowRise />}
                />
              </CustomPaper>
            </Grid>
          ))}
          <Grid item xs={12} sm={6} md={4} lg={3.42857142858}>
            <CustomPaper
              customStyles={{
                height: "100%",
                background:
                  theme.palette.mode === "dark"
                    ? "linear-gradient(94deg, #FF7935 -30%, #0E256D 62.46%)"
                    : "var(--Grade-blue, linear-gradient(76deg, #D7E0FF 1.45%, #FFF 95.77%))",
              }}
              watermarkBg={true}
            >
              <Box
                display="flex"
                flexDirection={{ xs: "column", lg: "row" }}
                justifyContent={{ xs: "center", lg: "space-evenly" }}
                alignItems="center"
                height="100%"
              >
                <Typography
                  variant="h5"
                  textAlign={{ xs: "center", lg: "left" }}
                >
                  Boost Your Business
                </Typography>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  sx={{
                    mx: 2,
                    borderLeftWidth: 1,
                    borderColor: theme.palette.divider,
                    display: {
                      xs: "none",
                      md: "none",
                      lg: "block",
                    },
                  }}
                />

                <Stack gap={1}>
                  <Typography variant="body2">Create Campaign</Typography>
                  <Button
                    startIcon={<AddIcon />}
                    onClick={handleCreateCampgain}
                    sx={{
                      color: "white",
                      background: theme.palette.accent,
                      textTransform: "none",
                      borderRadius: "20px",
                      "&:hover": {
                        backgroundColor:
                          theme.palette.mode === "dark"
                            ? "rgba(255, 100, 0, 1)"
                            : "rgba(29, 71, 226, 1)",
                      },
                    }}
                  >
                    Create
                  </Button>
                </Stack>
              </Box>
            </CustomPaper>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CustomPaper customStyles={{ overflow: "visible" }}>
              <CustomPaper
                key={1}
                customStyles={{
                  overflow: "visible",
                  background:
                    theme.palette.mode === "dark" ? "#071339" : "#FBFBFE",
                }}
              >
                <TableChart
                  route="sales"
                  showLegend={true}
                  setChartData={setChartData}
                  comparisonPeriod={comparisonPeriod}
                  title={"Channel Performance"}
                  description={"Spend Trend"}
                  postUrl="sales/data"
                  reportUid={"static"}
                  configurations={CustomerBarChartParameters}
                  dateRangeData={dateRangeData}
                  data={summeryTableData}
                />
              </CustomPaper>
            </CustomPaper>
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomPaper key={1} customStyles={{ overflow: "visible" }}>
              <BarChartContainer
                route="sales"
                showLegend={true}
                setChartData={setChartData}
                comparisonPeriod={comparisonPeriod}
                title={"Conversion Rate Analysis"}
                description={"Spend Trend"}
                postUrl="sales/data"
                reportUid={"static"}
                configurations={CustomerBarChartParameters}
                dateRangeData={dateRangeData}
              />
            </CustomPaper>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <CustomPaper>
              <LineChartContainer
                title="Engagement trend by channel"
                chartConfig={PieChartParameters}
                chartInput={ReviewClassificationData}
                colorScheme={colorScheme}
                reportUid={"static"}
              />
            </CustomPaper>
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomPaper>
              <PieChartContainer
                title="Channel Revenue Contribution"
                chartConfig={PieChartParameters}
                chartInput={ReviewClassificationData}
                colorScheme={colorScheme}
                reportUid={"static"}
              />
            </CustomPaper>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <CustomPaper key={1} customStyles={{ overflow: "visible" }}>
            <CustomPaper
              key={1}
              customStyles={{
                overflow: "visible",
                background:
                  theme.palette.mode === "dark" ? "#071339" : "#FBFBFE",
              }}
            >
              <TableChart
                route="sales"
                showLegend={true}
                setChartData={setChartData}
                comparisonPeriod={comparisonPeriod}
                title={"Performance Summary Table"}
                description={"Performance Summary Table"}
                postUrl="sales/data"
                reportUid={"static"}
                configurations={CustomerBarChartParameters}
                dateRangeData={dateRangeData}
                data={summeryTableData}
              />
            </CustomPaper>
          </CustomPaper>
        </Grid>
      </Stack>
    </>
  )
}

export default Summary
