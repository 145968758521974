export const SwarmPlotData = [
  {
    id: "0.0",
    group: "group C",
    price: 40,
    volume: 18,
    tooltipData: {
      current: {
        price: {
          value: 40,
          format: "Currency",
        },
        volume: {
          value: 18,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 80,
          format: "Currency",
        },
        volume: {
          value: 180,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.1",
    group: "group B",
    price: 384,
    volume: 19,
    tooltipData: {
      current: {
        price: {
          value: 384,
          format: "Currency",
        },
        volume: {
          value: 19,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 364,
          format: "Currency",
        },
        volume: {
          value: 18,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.2",
    group: "group B",
    price: 182,
    volume: 15,
    tooltipData: {
      current: {
        price: {
          value: 182,
          format: "Currency",
        },
        volume: {
          value: 15,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 150,
          format: "Currency",
        },
        volume: {
          value: 12,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.3",
    group: "group A",
    price: 270,
    volume: 10,
    tooltipData: {
      current: {
        price: {
          value: 270,
          format: "Currency",
        },
        volume: {
          value: 10,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 300,
          format: "Currency",
        },
        volume: {
          value: 8,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.4",
    group: "group B",
    price: 257,
    volume: 5,
    tooltipData: {
      current: {
        price: {
          value: 257,
          format: "Currency",
        },
        volume: {
          value: 5,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 200,
          format: "Currency",
        },
        volume: {
          value: 7,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.5",
    group: "group C",
    price: 307,
    volume: 14,
    tooltipData: {
      current: {
        price: {
          value: 307,
          format: "Currency",
        },
        volume: {
          value: 14,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 250,
          format: "Currency",
        },
        volume: {
          value: 10,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.6",
    group: "group C",
    price: 0,
    volume: 13,
    tooltipData: {
      current: {
        price: {
          value: 0,
          format: "Currency",
        },
        volume: {
          value: 13,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 100,
          format: "Currency",
        },
        volume: {
          value: 15,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.7",
    group: "group A",
    price: 394,
    volume: 7,
    tooltipData: {
      current: {
        price: {
          value: 394,
          format: "Currency",
        },
        volume: {
          value: 7,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 400,
          format: "Currency",
        },
        volume: {
          value: 6,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.8",
    group: "group C",
    price: 339,
    volume: 13,
    tooltipData: {
      current: {
        price: {
          value: 339,
          format: "Currency",
        },
        volume: {
          value: 13,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 350,
          format: "Currency",
        },
        volume: {
          value: 12,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.9",
    group: "group A",
    price: 296,
    volume: 15,
    tooltipData: {
      current: {
        price: {
          value: 296,
          format: "Currency",
        },
        volume: {
          value: 15,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 290,
          format: "Currency",
        },
        volume: {
          value: 14,
          format: "Number",
        },
      },
    },
  },
  {
    id: "1.0",
    group: "group A",
    price: 285,
    volume: 6,
    tooltipData: {
      current: {
        price: {
          value: 285,
          format: "Currency",
        },
        volume: {
          value: 6,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 275,
          format: "Currency",
        },
        volume: {
          value: 5,
          format: "Number",
        },
      },
    },
  },
  {
    id: "1.1",
    group: "group B",
    price: 134,
    volume: 16,
    tooltipData: {
      current: {
        price: {
          value: 134,
          format: "Currency",
        },
        volume: {
          value: 16,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 130,
          format: "Currency",
        },
        volume: {
          value: 18,
          format: "Number",
        },
      },
    },
  },
  {
    id: "1.2",
    group: "group C",
    price: 164,
    volume: 15,
    tooltipData: {
      current: {
        price: {
          value: 164,
          format: "Currency",
        },
        volume: {
          value: 15,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 160,
          format: "Currency",
        },
        volume: {
          value: 12,
          format: "Number",
        },
      },
    },
  },
  {
    id: "1.3",
    group: "group C",
    price: 50,
    volume: 18,
    tooltipData: {
      current: {
        price: {
          value: 50,
          format: "Currency",
        },
        volume: {
          value: 18,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 70,
          format: "Currency",
        },
        volume: {
          value: 20,
          format: "Number",
        },
      },
    },
  },
  {
    id: "1.4",
    group: "group A",
    price: 195,
    volume: 17,
    tooltipData: {
      current: {
        price: {
          value: 195,
          format: "Currency",
        },
        volume: {
          value: 17,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 190,
          format: "Currency",
        },
        volume: {
          value: 15,
          format: "Number",
        },
      },
    },
  },
  {
    id: "1.5",
    group: "group A",
    price: 145,
    volume: 10,
    tooltipData: {
      current: {
        price: {
          value: 145,
          format: "Currency",
        },
        volume: {
          value: 10,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 150,
          format: "Currency",
        },
        volume: {
          value: 9,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.0",
    group: "group C",
    price: 40,
    volume: 18,
    tooltipData: {
      current: {
        price: {
          value: 40,
          format: "Currency",
        },
        volume: {
          value: 18,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 80,
          format: "Currency",
        },
        volume: {
          value: 180,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.1",
    group: "group B",
    price: 384,
    volume: 19,
    tooltipData: {
      current: {
        price: {
          value: 384,
          format: "Currency",
        },
        volume: {
          value: 19,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 364,
          format: "Currency",
        },
        volume: {
          value: 18,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.2",
    group: "group B",
    price: 182,
    volume: 15,
    tooltipData: {
      current: {
        price: {
          value: 182,
          format: "Currency",
        },
        volume: {
          value: 15,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 150,
          format: "Currency",
        },
        volume: {
          value: 12,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.3",
    group: "group A",
    price: 270,
    volume: 10,
    tooltipData: {
      current: {
        price: {
          value: 270,
          format: "Currency",
        },
        volume: {
          value: 10,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 300,
          format: "Currency",
        },
        volume: {
          value: 8,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.4",
    group: "group B",
    price: 257,
    volume: 5,
    tooltipData: {
      current: {
        price: {
          value: 257,
          format: "Currency",
        },
        volume: {
          value: 5,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 200,
          format: "Currency",
        },
        volume: {
          value: 7,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.5",
    group: "group C",
    price: 307,
    volume: 14,
    tooltipData: {
      current: {
        price: {
          value: 307,
          format: "Currency",
        },
        volume: {
          value: 14,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 250,
          format: "Currency",
        },
        volume: {
          value: 10,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.6",
    group: "group C",
    price: 0,
    volume: 13,
    tooltipData: {
      current: {
        price: {
          value: 0,
          format: "Currency",
        },
        volume: {
          value: 13,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 100,
          format: "Currency",
        },
        volume: {
          value: 15,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.7",
    group: "group A",
    price: 394,
    volume: 7,
    tooltipData: {
      current: {
        price: {
          value: 394,
          format: "Currency",
        },
        volume: {
          value: 7,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 400,
          format: "Currency",
        },
        volume: {
          value: 6,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.8",
    group: "group C",
    price: 339,
    volume: 13,
    tooltipData: {
      current: {
        price: {
          value: 339,
          format: "Currency",
        },
        volume: {
          value: 13,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 350,
          format: "Currency",
        },
        volume: {
          value: 12,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.9",
    group: "group A",
    price: 296,
    volume: 15,
    tooltipData: {
      current: {
        price: {
          value: 296,
          format: "Currency",
        },
        volume: {
          value: 15,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 290,
          format: "Currency",
        },
        volume: {
          value: 14,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.0",
    group: "group C",
    price: 50, // Updated price
    volume: 20, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 50,
          format: "Currency",
        },
        volume: {
          value: 20,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 80,
          format: "Currency",
        },
        volume: {
          value: 180,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.1",
    group: "group B",
    price: 400, // Updated price
    volume: 25, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 400,
          format: "Currency",
        },
        volume: {
          value: 25,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 364,
          format: "Currency",
        },
        volume: {
          value: 18,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.2",
    group: "group B",
    price: 200, // Updated price
    volume: 20, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 200,
          format: "Currency",
        },
        volume: {
          value: 20,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 150,
          format: "Currency",
        },
        volume: {
          value: 12,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.3",
    group: "group A",
    price: 300, // Updated price
    volume: 15, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 300,
          format: "Currency",
        },
        volume: {
          value: 15,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 300,
          format: "Currency",
        },
        volume: {
          value: 8,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.4",
    group: "group B",
    price: 300, // Updated price
    volume: 10, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 300,
          format: "Currency",
        },
        volume: {
          value: 10,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 200,
          format: "Currency",
        },
        volume: {
          value: 7,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.5",
    group: "group C",
    price: 350, // Updated price
    volume: 16, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 350,
          format: "Currency",
        },
        volume: {
          value: 16,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 250,
          format: "Currency",
        },
        volume: {
          value: 10,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.6",
    group: "group C",
    price: 50, // Updated price
    volume: 15, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 50,
          format: "Currency",
        },
        volume: {
          value: 15,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 100,
          format: "Currency",
        },
        volume: {
          value: 15,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.7",
    group: "group A",
    price: 400, // Updated price
    volume: 10, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 400,
          format: "Currency",
        },
        volume: {
          value: 10,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 400,
          format: "Currency",
        },
        volume: {
          value: 6,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.8",
    group: "group C",
    price: 400, // Updated price
    volume: 20, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 400,
          format: "Currency",
        },
        volume: {
          value: 20,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 350,
          format: "Currency",
        },
        volume: {
          value: 12,
          format: "Number",
        },
      },
    },
  },
  {
    id: "0.9",
    group: "group A",
    price: 300, // Updated price
    volume: 20, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 300,
          format: "Currency",
        },
        volume: {
          value: 20,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 290,
          format: "Currency",
        },
        volume: {
          value: 14,
          format: "Number",
        },
      },
    },
  },
  {
    id: "1.0",
    group: "group A",
    price: 300, // Updated price
    volume: 8, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 300,
          format: "Currency",
        },
        volume: {
          value: 8,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 275,
          format: "Currency",
        },
        volume: {
          value: 5,
          format: "Number",
        },
      },
    },
  },
  {
    id: "1.1",
    group: "group B",
    price: 150, // Updated price
    volume: 22, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 150,
          format: "Currency",
        },
        volume: {
          value: 22,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 130,
          format: "Currency",
        },
        volume: {
          value: 18,
          format: "Number",
        },
      },
    },
  },
  {
    id: "1.2",
    group: "group C",
    price: 180, // Updated price
    volume: 20, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 180,
          format: "Currency",
        },
        volume: {
          value: 20,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 160,
          format: "Currency",
        },
        volume: {
          value: 12,
          format: "Number",
        },
      },
    },
  },
  {
    id: "1.3",
    group: "group C",
    price: 60, // Updated price
    volume: 22, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 60,
          format: "Currency",
        },
        volume: {
          value: 22,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 70,
          format: "Currency",
        },
        volume: {
          value: 20,
          format: "Number",
        },
      },
    },
  },
  {
    id: "1.4",
    group: "group A",
    price: 200, // Updated price
    volume: 20, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 200,
          format: "Currency",
        },
        volume: {
          value: 20,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 190,
          format: "Currency",
        },
        volume: {
          value: 15,
          format: "Number",
        },
      },
    },
  },
  {
    id: "1.5",
    group: "group A",
    price: 150, // Updated price
    volume: 12, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 150,
          format: "Currency",
        },
        volume: {
          value: 12,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 140,
          format: "Currency",
        },
        volume: {
          value: 10,
          format: "Number",
        },
      },
    },
  },
  {
    id: "1.6",
    group: "group C",
    price: 300, // Updated price
    volume: 16, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 300,
          format: "Currency",
        },
        volume: {
          value: 16,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 250,
          format: "Currency",
        },
        volume: {
          value: 12,
          format: "Number",
        },
      },
    },
  },
  {
    id: "1.7",
    group: "group A",
    price: 400, // Updated price
    volume: 25, // Updated volume
    tooltipData: {
      current: {
        price: {
          value: 400,
          format: "Currency",
        },
        volume: {
          value: 25,
          format: "Number",
        },
      },
      previous: {
        price: {
          value: 380,
          format: "Currency",
        },
        volume: {
          value: 20,
          format: "Number",
        },
      },
    },
  },
]

export const SwarmPlotParametersCust = {
  margin: { top: 50, right: 10, bottom: 50, left: 55 },
  groups: [" "],
  identity: "id",
  value: "TotalCustomers",
  valueFormat: "$.2f",
  valueScale: { type: "linear", min: 0, max: 2000, reverse: false },
  size: { key: "TotalCustomers", values: [1, 50], sizes: [8, 10] },
  spacing: 1,
  gap: 25,
  enableGridY: false,
  forceStrength: 0.2,
  simulationIterations: 260,
  colorBy: "id",
  borderColor: {
    from: "color",
    modifiers: [
      ["darker", 0],
      ["opacity", 2.1],
    ],
  },
  axisTop: null,
  axisRight: null,
  axisBottom: {
    orient: "bottom",
    tickSize: 10,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Payment Methods",
    legendPosition: "middle",
    legendOffset: 40,
    truncateTickAt: 0,
  },
  axisLeft: {
    orient: "left",
    tickSize: 10,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Total Customers",
    legendPosition: "middle",
    legendOffset: -50,
    truncateTickAt: 0,
  },
}
export const SwarmPlotParametersEmp = {
  margin: { top: 50, right: 10, bottom: 50, left: 55 },
  groups: [" "],
  identity: "id",
  value: "GrossSales",
  valueFormat: "$.2f",
  valueScale: { type: "linear", min: 0, max: 2000, reverse: false },
  size: { key: "SalesQuantity", values: [1, 50], sizes: [8, 8] },
  spacing: 1,
  gap: 25,
  enableGridY: false,
  forceStrength: 0.2,
  simulationIterations: 260,
  colorBy: "id",
  borderColor: {
    from: "color",
    modifiers: [
      ["darker", 0],
      ["opacity", 2.1],
    ],
  },
  axisTop: null,
  axisRight: null,
  axisBottom: {
    orient: "bottom",
    tickSize: 10,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Employee Name",
    legendPosition: "middle",
    legendOffset: 40,
    truncateTickAt: 0,
  },
  axisLeft: {
    orient: "left",
    tickSize: 10,
    tickPadding: 5,
    tickRotation: 0,
    legend: "Sales By Employee",
    legendPosition: "middle",
    legendOffset: -50,
    truncateTickAt: 0,
  },
}
