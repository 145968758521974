import React, { useState, useEffect } from "react"
import { Box, Typography, TextField, Button } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import InfoToolTip from "../../../components/InfoToolTip"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import { useTheme } from "../../../contexts/theme"
import { getData } from "../../../api/api"
import { useNavigate } from "react-router-dom"

const YelpAuth = () => {
  const [businessName, setBusinessName] = useState("")
  const [location, setLocation] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [isBusinessSaved, setIsBusinessSaved] = useState(false)
  const { theme } = useTheme()
  const navigate = useNavigate()

  useEffect(() => {
    const yelpBusinessId = localStorage.getItem("yelpBusinessId")
    const location = localStorage.getItem("yelpBusinessLocation")
    const yelpBusinessName = localStorage.getItem("yelpBusinessName")
    if (yelpBusinessId) {
      setBusinessName(yelpBusinessName)
      setLocation(location)
      setIsBusinessSaved(true)
    }
  }, [])

  const fetchBusinessInfo = async () => {
    if (!businessName || !location) {
      alert("Please enter both business name and location!")
      return
    }

    setIsLoading(true)

    try {
      const entityId = localStorage.getItem("entityId")
      const token = localStorage.getItem("token")

      if (!entityId || !token) {
        alert("Missing entityId or Authorization token. Please log in again.")
        return
      }

      const response = await getData(
        `yelp/business-id?businessName=${businessName}&location=${location}&entityId=${entityId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      const { yelpBusinessDetails } = response

      localStorage.setItem("yelpBusinessId", yelpBusinessDetails.businessId)
      localStorage.setItem("yelpBusinessName", yelpBusinessDetails.businessName)
      localStorage.setItem("yelpBusinessLocation", location)

      setIsBusinessSaved(true)
      alert("Yelp Business Name and Location stored successfully!")
      navigate("/customers/dashboard/customerSentiment")
    } catch (error) {
      console.error("Error fetching business info:", error)
      alert("Something went wrong. Please try again.")
    } finally {
      setIsLoading(false)
    }
  }

  const handleEdit = () => {
    setIsBusinessSaved(false)
  }

  return (
    <Box>
      <Box display="flex" alignItems="center" mb={2}>
        <Typography color="inherit">Yelp Business</Typography>
        <InfoToolTip title="Enter a business name and location to fetch its Yelp details.">
          <span style={{ height: "16px", cursor: "pointer" }}>
            <InfoLogoSvg svgColor={theme.palette.accentSecondary} />
          </span>
        </InfoToolTip>
      </Box>

      <Box display="flex" flexDirection="column" gap={2}>
        <TextField
          label="Business Name"
          placeholder="e.g., Green Bawarchi"
          variant="standard"
          value={businessName}
          onChange={(e) => setBusinessName(e.target.value)}
          fullWidth
          disabled={isBusinessSaved}
        />
        <TextField
          label="Location"
          placeholder="e.g., 4020 FM 1463, Fulshear, TX"
          variant="standard"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          fullWidth
          disabled={isBusinessSaved}
        />

        <Box display="flex" justifyContent="space-between">
          <LoadingButton
            variant="contained"
            color="primary"
            onClick={fetchBusinessInfo}
            loading={isLoading}
            disabled={
              isBusinessSaved || !businessName || !location || isLoading
            }
            sx={{
              backgroundColor: theme.palette.accent,
              padding: "10px 19px",
              borderRadius: 6,
              color: "#fff",
              textTransform: "none",
              "&:hover": {
                backgroundColor: theme.palette.accent,
              },
              "&:disabled": {
                backgroundColor: "#fff",
                color: "#B6B7BC",
              },
              "& .MuiCircularProgress-colorInherit": {
                color: theme.palette.accent,
              },
            }}
          >
            Search
          </LoadingButton>
          {isBusinessSaved && (
            <Button
              variant="outlined"
              color="primary"
              onClick={handleEdit}
              sx={{
                backgroundColor: theme.palette.accent,
                padding: "10px 23px",
                borderRadius: 6,
                color: "#fff",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: theme.palette.accent,
                },
                "& .MuiCircularProgress-colorInherit": {
                  color: theme.palette.accent,
                },
              }}
            >
              Edit
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default YelpAuth
