export const tooltipDataFallbackConfigCust = [
  { name: "Payment Method", format: false, color: "" },
  { name: "Customer Profiles", format: false, color: "" },
  { name: "Total Customers", format: "Number", color: "" },
]

export const tooltipDataFallbackConfigEmp = [
  { name: "Product", format: false, color: "" },
  { name: "Gross Sales", format: "Currency", color: "" },
  { name: "Sales Quantity", format: "Number", color: "" },
  { name: "SEQ Rank", format: "Number", color: "" },
]

export const PDFFieldsFallbackConfig = [
  { name: "Gross Sales", format: "Currency", color: "" },
  { name: "Customers", format: "Number", color: "" },
  { name: "Transactions", format: "Number", color: "" },
  { name: "Average Ticket", format: "Currency", color: "" },
]

export const chartContainerDimentionsFallbackConfig = {
  height: "350px",
  minHeight: "350px",
  maxHeight: "unset",
}
