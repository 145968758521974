import React, { memo, useEffect, useRef, useState } from "react"
import BodySection from "./components/widget/BodySection"
import FooterSection from "./components/widget/FooterSection"
import Message from "../../Message"
import HeaderSection from "./components/widget/HeaderSection"
import { Stack } from "@mui/material"
import { generateCacheKey } from "../../../utils/utils"
import { useDispatch, useSelector } from "react-redux"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import { StreamChartData, StreamChartParameters } from "./constant/dataFallback"
import { fetchData } from "./services/dataServices"

const StreamChartContainer = memo(
  ({
    title,
    description,
    route,
    setChartData,
    postUrl,
    reportUid,
    configurations,
  }) => {
    const dispatch = useDispatch()
    const chartRef = useRef(null)

    const dateRangeData = useSelector((state) => state.calendar)
    const comparisonPeriod = useSelector((state) => state.period.selectedValue)
    let transformedConfigs = extractKeyValuePairs(configurations) || {}

    let reportState =
      useSelector((state) => {
        return state[route]?.reports?.find(
          (report) => report.report_uid === reportUid
        )
      }) || {}

    const cacheKey = generateCacheKey(
      reportUid,
      dateRangeData.fromDate,
      dateRangeData.toDate,
      comparisonPeriod.previousFromDate,
      comparisonPeriod.previousToDate,
      reportState.viewBy
    )
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    // useEffect(() => {
    //   if (reportState.cacheKey === cacheKey) return
    //   const isComparedBy = comparisonPeriod.selectedValue === "Compared By"
    //   const payload = {
    //     entityDb: localStorage.getItem("entityDb"),
    //     fromDate: dateRangeData.fromDate,
    //     toDate: dateRangeData.toDate,
    //     reportInstanceId: reportUid,
    //     comparedBy: {
    //       previousFromDate: comparisonPeriod.previousFromDate,
    //       previousToDate: comparisonPeriod.previousToDate,
    //     },
    //   }

    //   setLoading(true)
    //   fetchData(postUrl, payload)
    //     .then((apiResponse) => {
    //       dispatch(
    //         setChartData({
    //           reportUid,
    //           response: StreamChartData,
    //           apiResponse,
    //           cacheKey,
    //         })
    //       )
    //       setLoading(false)
    //     })
    //     .catch((err) => {
    //       console.warn(err)
    //       setError("Failed to fetch data")
    //       setLoading(false)
    //     })
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [
    //   dateRangeData.fromDate,
    //   dateRangeData.toDate,
    //   comparisonPeriod.previousFromDate,
    //   comparisonPeriod.previousToDate,
    //   reportState.cacheKey,
    // ])

    return (
      <Stack spacing={1} ref={chartRef}>
        <HeaderSection
          route={route}
          reportUid={reportUid}
          title={title}
          description={description}
          setChartData={setChartData}
          children={null}
          config={transformedConfigs}
          reportStateViewBy={reportState.viewBy}
          dateRangeData={dateRangeData}
          parentLoading={loading}
          parentError={error}
          transformedChartData={reportState.response}
          apiResponse={reportState.apiResponse}
          chartRef={chartRef}
          pdfFields={transformedConfigs.pdfFields}
        />

        {loading ? (
          <Message type="loading" />
        ) : error ? (
          <Message message={error} type="error" />
        ) : (
          <>
            <BodySection
              config={StreamChartParameters}
              chartInput={StreamChartData}
            />
            <FooterSection
              title={title}
              apiResponse={reportState.apiResponse}
            />
          </>
        )}
      </Stack>
    )
  }
)

export default StreamChartContainer
