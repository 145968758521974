import { Grid, Stack, Tab, Tabs } from "@mui/material"
import React, { useState } from "react"
import { useTheme } from "../../../../contexts/theme"
import RouteTitle from "../../../../components/RouteTitle"
import { useNavigate } from "react-router-dom"
import {
  TargetAudience,
  TargetAudienceParameters,
  TemplatesData,
  TemplatesParameters,
} from "../Campaigns/staticTableData/viewCapaigns"
import Table from "../../../../components/datagrid/Table"
import CustomPaper from "../../../../components/CustomPaper"
import Filter from "../../Filter"
import BlurEffect from "../../../../components/BlurEffect"

const Configurations = () => {
  const [activeTab, setActiveTab] = useState(0)
  const { theme } = useTheme()
  const navigate = useNavigate()
  const palette = theme.palette

  const tabStyles = {
    textTransform: "none",
    color: "inherit",
    "&:hover": {
      color: palette.accent,
    },
    "&.Mui-selected": {
      color: palette.accent,
    },
  }
  const handleTabChange = (event, newActiveTab) => {
    setActiveTab(newActiveTab)
  }

  const handleCreateCampgain = () => {
    navigate("/submenu/marketing/createcampaign")
  }

  return (
    <>
      <BlurEffect showBlur={true} />

      <RouteTitle title="Configurations" />
      <Stack padding=" 0 15px">
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="configurations tabs"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: palette.accent,
            },
          }}
        >
          <Tab label="Target Audience" sx={tabStyles} />
          <Tab label="Templates" sx={tabStyles} />
        </Tabs>
        {activeTab === 0 && (
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            sx={{ mt: 0.2 }}
          >
            <Grid item xs={12} md={4} lg={3}>
              <CustomPaper customStyles={{ paddingX: "unset" }}>
                <Filter />
              </CustomPaper>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <Table
                title="Target Audience"
                buttonName="+ Create Audience"
                showButton={handleCreateCampgain}
                showExportButton={false}
                StaticData={TargetAudience}
                StaticParameters={TargetAudienceParameters}
              />
            </Grid>
          </Grid>
        )}

        {activeTab === 1 && (
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            sx={{ mt: 0.2 }}
          >
            <Grid item xs={12} md={4} lg={3}>
              <CustomPaper customStyles={{ paddingX: "unset" }}>
                <Filter />
              </CustomPaper>
            </Grid>
            <Grid item xs={12} md={8} lg={9}>
              <Table
                title="Templates"
                buttonName="+ Create Template"
                showButton={handleCreateCampgain}
                showExportButton={false}
                StaticData={TemplatesData}
                StaticParameters={TemplatesParameters}
              />
            </Grid>
          </Grid>
        )}
      </Stack>
    </>
  )
}

export default Configurations
