export const PieSentimentChartData = [
  {
    id: "Positive",
    value: 50,
    tooltipData: {
      current: {
        "Food": {
		value: 20,
          format: "Number",
        },
        Staff: {
          value: 40,
          format: "Number",
        }
      }
    },
  },
  {
    id: "Negative",
    value: 18,
    tooltipData: {
      current: {
"Food": {
		value: 10,
          format: "Number",
        },
        Staff: {
          value: 5,
          format: "Number",
        }

             },     
    },
  },
  {
    id: "Neutral",
    value: 32,
    tooltipData: {
      current: {
"Items": {
		value: 250,
          format: "Number",
        },
        Staff: {
          value: 13,
          format: "Number",
        }

             },
         },
  },
]


export const PieChartData = [
  {
    id: "Walk In",
    value: 9457.36,
    tooltipData: {
      current: {
        "Gross Sales": {
          format: "Currency",
        },
        Customers: {
          value: 1367,
          format: "Number",
        },
        Transactions: {
          value: 3807,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.18,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          format: "Currency",
        },
        Customers: {
          value: 1241,
          format: "Number",
        },
        Transactions: {
          value: 3443,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.53,
          format: "Currency",
        },
      },
    },
  },
  {
    id: "Dine In",
    value: 3457.36,
    tooltipData: {
      current: {
        "Gross Sales": {
          format: "Currency",
        },
        Customers: {
          value: 1367,
          format: "Number",
        },
        Transactions: {
          value: 3807,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.18,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          format: "Currency",
        },
        Customers: {
          value: 1241,
          format: "Number",
        },
        Transactions: {
          value: 3443,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.53,
          format: "Currency",
        },
      },
    },
  },
  {
    id: "To Go",
    value: 1457.36,
    tooltipData: {
      current: {
        "Gross Sales": {
          format: "Currency",
        },
        Customers: {
          value: 1367,
          format: "Number",
        },
        Transactions: {
          value: 3807,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.18,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          format: "Currency",
        },
        Customers: {
          value: 1241,
          format: "Number",
        },
        Transactions: {
          value: 3443,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.53,
          format: "Currency",
        },
      },
    },
  },
]

export const PieChartParameters = {
  margin: {
    top: 20,
    bottom: 15,
    left: 50,
  },
  startAngle: -5,
  innerRadius: 0.6,
  padAngle: 0,
  cornerRadius: 0,
  activeInnerRadiusOffset: 26,
  activeOuterRadiusOffset: 19,
  borderWidth: 1,
  borderColor: {
    from: "color",
    modifiers: [["darker", 0.2]],
  },
  enableSliceLabels: false,
  enableSlicesLabels: false,
  enableArcLinkLabels: true,
  arcLinkLabel: "id",
  arcLinkLabelsTextOffset: 0,
  arcLinkLabelsTextColor: "#333333",
  arcLinkLabelsOffset: -24,
  arcLinkLabelsDiagonalLength: 0,
  arcLinkLabelsStraightLength: 0,
  arcLinkLabelsThickness: 0,
  arcLinkLabelsColor: {
    from: "color",
  },
  enableArcLabels: false,
  arcLabelsRadiusOffset: 0.25,
  arcLabelsSkipAngle: 14,
  arcLabelsTextColor: {
    from: "color",
    modifiers: [["darker", 2]],
  },
  animate: false,
  meta_info: {
    format: "Currency",
  },
  tooltip_fields: "'Gross Sales','Customers','Transactions','Average Ticket'",
}

export const PieapiResponse = {
  reportDataCurrent: [
    {
      id: "VISA",
      value: 19811.4,
      Amount: 19811.4,
      Customers: 463,
      Transactions: 775,
    },
    {
      id: "MASTERCARD",
      value: 5124.7,
      Amount: 5124.7,
      Customers: 121,
      Transactions: 200,
    },
    {
      id: "CustomerAccount",
      value: 3453.46,
      Amount: 3453.46,
      Customers: 7,
      Transactions: 81,
    },
    {
      id: "AMEX",
      value: 2824.44,
      Amount: 2824.44,
      Customers: 64,
      Transactions: 96,
    },
    {
      id: "Cash",
      value: 2448.4,
      Amount: 2448.4,
      Customers: 76,
      Transactions: 159,
    },
    {
      id: "DISCOVER",
      value: 1126.66,
      Amount: 1126.66,
      Customers: 34,
      Transactions: 49,
    },
    {
      id: "EBT",
      value: 503.89,
      Amount: 503.89,
      Customers: 13,
      Transactions: 18,
    },
  ],
  reportDataPrevious: [
    {
      id: "VISA",
      value: 19866.72,
      Amount: 19866.72,
      Customers: 452,
      Transactions: 775,
    },
    {
      id: "MASTERCARD",
      value: 6791.95,
      Amount: 6791.95,
      Customers: 131,
      Transactions: 224,
    },
    {
      id: "CustomerAccount",
      value: 5430.27,
      Amount: 5430.27,
      Customers: 5,
      Transactions: 87,
    },
    {
      id: "AMEX",
      value: 2837.21,
      Amount: 2837.21,
      Customers: 73,
      Transactions: 107,
    },
    {
      id: "Cash",
      value: 2286.1,
      Amount: 2286.1,
      Customers: 68,
      Transactions: 150,
    },
    {
      id: "DISCOVER",
      value: 1221.63,
      Amount: 1221.63,
      Customers: 29,
      Transactions: 44,
    },
    {
      id: "EBT",
      value: 384.22,
      Amount: 384.22,
      Customers: 11,
      Transactions: 15,
    },
  ],
}