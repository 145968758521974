import { ComparisonofExpensesandRevenueData } from "../../../../constants/ComparisonofExpensesandRevenueData"
import { CustomerDetailsBarChartData } from "../../../../constants/CustomerDetailsBarChartData"
import { MostWastedProducts } from "../../../../constants/MostWastedProducts"

export const chartDataMap = {
  "Comparison of Expenses and Revenue": ComparisonofExpensesandRevenueData,
  "Most Wasted Products": MostWastedProducts,
  "Customer-Details-Page": CustomerDetailsBarChartData,
}

export const staticApiResponse = {
  reportDataCurrent: [
    {
      Date: "2024-11-03",
      Quantity: 2,
      "Net Sales": 5.98,
    },
    {
      Date: "2024-11-04",
      Quantity: 1,
      "Net Sales": 2.99,
    },
    {
      Date: "2024-11-05",
      Quantity: 1,
      "Net Sales": 2.99,
    },
    {
      Date: "2024-11-06",
      Quantity: 3,
      "Net Sales": 8.97,
    },
    {
      Date: "2024-11-07",
      Quantity: 3,
      "Net Sales": 8.97,
    },
    {
      Date: "2024-11-09",
      Quantity: 2,
      "Net Sales": 5.98,
    },
    {
      Date: "2024-11-10",
      Quantity: 3,
      "Net Sales": 8.97,
    },
    {
      Date: "2024-11-11",
      Quantity: 1,
      "Net Sales": 2.99,
    },
    {
      Date: "2024-11-13",
      Quantity: 3,
      "Net Sales": 8.97,
    },
    {
      Date: "2024-11-15",
      Quantity: 7,
      "Net Sales": 20.93,
    },
    {
      Date: "2024-11-16",
      Quantity: 11,
      "Net Sales": 32.89,
    },
    {
      Date: "2024-11-17",
      Quantity: 2,
      "Net Sales": 5.98,
    },
    {
      Date: "2024-11-19",
      Quantity: 1,
      "Net Sales": 2.99,
    },
    {
      Date: "2024-11-20",
      Quantity: 1,
      "Net Sales": 2.99,
    },
    {
      Date: "2024-11-21",
      Quantity: 1,
      "Net Sales": 2.99,
    },
    {
      Date: "2024-11-22",
      Quantity: 2,
      "Net Sales": 5.98,
    },
    {
      Date: "2024-11-23",
      Quantity: 4,
      "Net Sales": 11.96,
    },
    {
      Date: "2024-11-24",
      Quantity: 1,
      "Net Sales": 2.99,
    },
    {
      Date: "2024-11-25",
      Quantity: 7,
      "Net Sales": 20.93,
    },
    {
      Date: "2024-11-27",
      Quantity: 1,
      "Net Sales": 2.99,
    },
    {
      Date: "2024-11-28",
      Quantity: 3,
      "Net Sales": 8.97,
    },
  ],
  // reportDataPrevious: [
  //   {
  //     Date: "2024-11-01",
  //     Quantity: 5,
  //     NetSales: 9.95,
  //   },
  //   {
  //     Date: "2024-11-02",
  //     Quantity: 1,
  //     NetSales: 1.99,
  //   },
  //   {
  //     Date: "2024-11-04",
  //     Quantity: 2,
  //     NetSales: 3.98,
  //   },
  //   {
  //     Date: "2024-11-09",
  //     Quantity: 4,
  //     NetSales: 7.96,
  //   },
  //   {
  //     Date: "2024-11-10",
  //     Quantity: 3,
  //     NetSales: 5.97,
  //   },
  //   {
  //     Date: "2024-11-14",
  //     Quantity: 2,
  //     NetSales: 3.98,
  //   },
  // ],
}

export const BarChartData = [
  {
    id: "Mail",
    "Gross Sales": 4285.47,
    tooltipData: {
      current: {
        "Gross Sales": {
          value: 4285.47,
          format: "Currency",
        },
        Customers: {
          value: 115,
          format: "Number",
        },
        Transactions: {
          value: 154,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.83,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 2290.04,
          format: "Currency",
        },
        Customers: {
          value: 70,
          format: "Number",
        },
        Transactions: {
          value: 82,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.93,
          format: "Currency",
        },
      },
    },
  },
  {
    id: "SMS",
    "Gross Sales": 92528.12,
    tooltipData: {
      current: {
        "Gross Sales": {
          value: 92528.12,
          format: "Currency",
        },
        Customers: {
          value: 1215,
          format: "Number",
        },
        Transactions: {
          value: 3422,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.04,
          format: "Currency",
        },
      },
      previous: {
        "Gross Sales": {
          value: 84551.9,
          format: "Currency",
        },
        Customers: {
          value: 1109,
          format: "Number",
        },
        Transactions: {
          value: 3086,
          format: "Number",
        },
        "Average Ticket": {
          value: 27.4,
          format: "Currency",
        },
      },
    },
  },
]

export const BarChartParameters = {
  keys: ["value"],
  indexBy: "id",
  margin: { top: 30, right: 10, bottom: 50, left: 60 },
  padding: 0.6,
  colors: "rgb(0, 108, 255), rgb(251, 101, 29)",
  borderColor: { from: "color", modifiers: [["darker", 1.6]] },
  borderRadius: 10,
  axisTop: null,
  axisRight: null,
  axisBottom: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: -42,

    legendPosition: "middle",
    legendOffset: 32,
  },
  axisLeft: {
    tickSize: 5,
    tickPadding: 5,
    tickRotation: 0,
    legendPosition: "middle",
    legendOffset: -40,
  },
  labelTextColor: { from: "color", modifiers: [["darker", 1.6]] },
  enableLabel: false,
  isInteractive: false,
  layout: "horizantal",
}
