import { useState } from "react"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import { Link, NavLink, useLocation } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { setCurrentPage } from "../../../../redux/slices/navigationSlice"
import { useTheme } from "../../../../contexts/theme"
import SubMenu from "../../../../assets/svgs/SubMenu"
import ListItemIcon from "@mui/material/ListItemIcon"
import MngIcon from "../../../../assets/svgs/MngIcon"
// import ConfigIcon from "../../../../assets/svgs/ConfigIcon"
import InterIcon from "../../../../assets/svgs/InterIcon"
import GraphIcon from "../../../../assets/svgs/GraphIcon"
import PercentIcon from "../../../../assets/svgs/PercentIcon"
import DollarIcon from "../../../../assets/svgs/DollarIcon"
import HomeIcon from "@mui/icons-material/Home"
import Primary from "../../primary/Primary"

const Left = () => {
  const [customersMenuAnchorEl, setCustomersMenuAnchorEl] = useState(null)
  const [vendorsMenuAnchorEl, setVendorsMenuAnchorEl] = useState(null)
  const [inventoryMenuAnchorEl, setInventoryMenuAnchorEl] = useState(null)
  const [employeesMenuAnchorEl, setEmployeesMenuAnchorEl] = useState(null)
  const [campaignsMenuAnchorEl, setCampaignsMenuAnchorEl] = useState(null)
  const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null)
  const dispatch = useDispatch()
  const activeButton = useSelector((state) => state.navigation.currentPage)
  const { theme } = useTheme()
  const location = useLocation()

  const btnStyles = (activeButton, menuItem, listItem) => {
    let isActive
    if (Array.isArray(menuItem)) {
      isActive = menuItem.includes(activeButton) || menuItem.includes(listItem)
    } else {
      isActive = activeButton === menuItem || activeButton === listItem
    }

    return {
      textTransform: "none",
      borderRadius: "0px",
      color: isActive
        ? theme.palette.accent
        : theme.palette.mode === "dark"
        ? "#CCCCCC"
        : "#000",
      borderBottom: isActive ? `2px solid ${theme.palette.accent}` : "none",
    }
  }

  const handleClick = (buttonName) => {
    dispatch(setCurrentPage(buttonName))
  }
  const isMarketingRoute = location.pathname.includes("submenu/marketing")

  const handleMenuClick = (buttonName, event) => {
    switch (buttonName) {
      case "Customers":
        setCustomersMenuAnchorEl(event.currentTarget)
        break
      case "Vendors":
        setVendorsMenuAnchorEl(event.currentTarget)
        break
      case "Products / Item-PLU":
        setInventoryMenuAnchorEl(event.currentTarget)
        break
      case "Employees":
        setEmployeesMenuAnchorEl(event.currentTarget)
        break
      case "Campaigns":
        setCampaignsMenuAnchorEl(event.currentTarget)
        break
      case "SubMenu":
        setSubMenuAnchorEl(event.currentTarget)
        break
      case "management":
        setSubMenuAnchorEl(event.currentTarget)
        break
      case "integration":
        setSubMenuAnchorEl(event.currentTarget)
        break
      default:
        break
    }
    handleClick(buttonName)
  }

  const handleClose = (buttonName) => {
    switch (buttonName) {
      case "Customers":
        setCustomersMenuAnchorEl(null)
        break
      case "Vendors":
        setVendorsMenuAnchorEl(null)
        break
      case "Products / Item-PLU":
        setInventoryMenuAnchorEl(null)
        break
      case "Employees":
        setEmployeesMenuAnchorEl(null)
        break
      case "Campaigns":
        setCampaignsMenuAnchorEl(null)
        break
      case "SubMenu":
        setSubMenuAnchorEl(null)
        break
      case "management":
        setSubMenuAnchorEl(null)
        break
      case "integration":
        setSubMenuAnchorEl(null)
        break
      default:
        break
    }
  }

  const renderSubMenu = () => (
    <Menu
      id="sub-menu"
      anchorEl={subMenuAnchorEl}
      open={Boolean(subMenuAnchorEl)}
      onClose={() => handleClose("SubMenu")}
    >
      <MenuItem
        component={Link}
        to="/businesssynopsis"
        onClick={() => handleClose("businesssynopsis")}
      >
        <ListItemIcon>
          <DollarIcon />
        </ListItemIcon>
        <Typography startIcon={<SubMenu />} variant="subtitle2" color="inherit">
          Transactions
        </Typography>
      </MenuItem>

      <MenuItem
        component={Link}
        to="/management"
        onClick={() => handleClose("management")}
      >
        <ListItemIcon>
          <MngIcon color="primary" />
        </ListItemIcon>
        <Typography startIcon={<SubMenu />} variant="subtitle2" color="inherit">
          Management
        </Typography>
      </MenuItem>

      <MenuItem
        component={Link}
        to="/integration"
        onClick={() => handleClose("integration")}
      >
        <ListItemIcon>
          <InterIcon />
        </ListItemIcon>
        <Typography variant="subtitle2" color="inherit">
          Integration
        </Typography>
      </MenuItem>
      <MenuItem
        component={Link}
        to="/submenu/marketing/summary"
        onClick={() => handleClose("SubMenu")}
      >
        <ListItemIcon>
          <PercentIcon />
        </ListItemIcon>
        <Typography variant="subtitle2" color="inherit">
          Marketing Portal
        </Typography>
      </MenuItem>
    </Menu>
  )

  const renderCustomersMenu = () => (
    <Menu
      id="customers-menu"
      anchorEl={customersMenuAnchorEl}
      open={Boolean(customersMenuAnchorEl)}
      onClose={() => handleClose("Customers")}
    >
      <MenuItem
        component={Link}
        to="/customers/dashboard"
        onClick={() => handleClose("Customers")}
      >
        <Typography variant="subtitle2" color="inherit">
          Dashboard
        </Typography>
      </MenuItem>
      <MenuItem
        component={Link}
        to="/customers/allcustomers"
        onClick={() => handleClose("Customers")}
      >
        <Typography variant="subtitle2" color="inherit">
          All Customers
        </Typography>
      </MenuItem>
    </Menu>
  )

  const renderVendorsMenu = () => (
    <Menu
      id="vendors-menu"
      anchorEl={vendorsMenuAnchorEl}
      open={Boolean(vendorsMenuAnchorEl)}
      onClose={() => handleClose("Vendors")}
    >
      <MenuItem
        component={Link}
        to="/vendors/dashboard"
        onClick={() => handleClose("Vendors")}
      >
        <Typography variant="subtitle2" color="inherit">
          Dashboard
        </Typography>
      </MenuItem>
      <MenuItem
        component={Link}
        to="/vendors/allvendors"
        onClick={() => handleClose("Vendors")}
      >
        <Typography variant="subtitle2" color="inherit">
          All Vendors
        </Typography>
      </MenuItem>
    </Menu>
  )

  const renderInventoryMenu = () => (
    <Menu
      id="inventory-menu"
      anchorEl={inventoryMenuAnchorEl}
      open={Boolean(inventoryMenuAnchorEl)}
      onClose={() => handleClose("Products / Item-PLU")}
    >
      <MenuItem
        component={Link}
        to="/products/dashboard"
        onClick={() => handleClose("Products / Item-PLU")}
        sx={{ minWidth: "11.2rem" }}
      >
        <Typography variant="subtitle2" color="inherit">
          Dashboard
        </Typography>
      </MenuItem>
      <MenuItem
        component={Link}
        to="/products/products"
        onClick={() => handleClose("Products / Item-PLU")}
      >
        <Typography variant="subtitle2" color="inherit">
          Products
        </Typography>
      </MenuItem>
    </Menu>
  )

  const renderEmployeesMenu = () => (
    <Menu
      id="employees-menu"
      anchorEl={employeesMenuAnchorEl}
      open={Boolean(employeesMenuAnchorEl)}
      onClose={() => handleClose("Employees")}
    >
      <MenuItem
        component={Link}
        to="/employees/dashboard"
        onClick={() => handleClose("Employees")}
      >
        <Typography variant="subtitle2" color="inherit">
          Dashboard
        </Typography>
      </MenuItem>
      <MenuItem
        component={Link}
        to="/employees/allemployees"
        onClick={() => handleClose("Employees")}
      >
        <Typography variant="subtitle2" color="inherit">
          All Employees
        </Typography>
      </MenuItem>
    </Menu>
  )

  const renderCampaignsMenu = () => (
    <Menu
      id="Campaigns-menu"
      anchorEl={campaignsMenuAnchorEl}
      open={Boolean(campaignsMenuAnchorEl)}
      onClose={() => handleClose("Campaigns")}
    >
      <MenuItem
        component={Link}
        to="/submenu/marketing/viewCampaigns"
        onClick={() => handleClose("Campaigns")}
      >
        <Typography variant="subtitle2" color="inherit">
          View Campaigns
        </Typography>
      </MenuItem>
      <MenuItem
        component={Link}
        to="/submenu/marketing/viewTemplates"
        onClick={() => handleClose("Campaigns")}
      >
        <Typography variant="subtitle2" color="inherit">
          View Tempaltes
        </Typography>
      </MenuItem>
    </Menu>
  )

  return (
    <>
      <Button
        aria-label="SubMenu"
        sx={{
          ...btnStyles(
            activeButton,
            ["submenu/marketing", "management", "integration"],
            ""
          ),
          minWidth: "40px",
          ".MuiButton-endIcon": {
            margin: "unset",
          },
        }}
        onClick={(event) => handleMenuClick("SubMenu", event)}
        aria-controls="sub-menu"
        aria-haspopup="true"
        endIcon={
          <div style={{ display: "flex", alignItems: "center" }}>
            <SubMenu
              stroke={
                ["submenu/marketing", "management", "integration"].includes(
                  activeButton
                )
                  ? theme.palette.accent
                  : theme.palette.mode === "dark"
                  ? "white"
                  : "gray"
              }
            />
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginLeft: "8px" }}
            >
              <path
                d="M15.8327 8L9.99935 13L4.16602 8"
                stroke={theme.palette.accent}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        }
      />
      {renderSubMenu()}

      {!isMarketingRoute && (
        <Link
          to="/businesssynopsis"
          style={{
            textDecoration: "none",
            color: theme.palette.mode === "dark" ? "#CCCCCC" : "#000",
          }}
        >
          <Button
            aria-label="Business Synopsis "
            sx={btnStyles(activeButton, "businesssynopsis")}
            onClick={() => handleClick("businesssynopsis")}
            startIcon={
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_6469_16334)">
                  <path
                    d="M5.73242 15.1234V13.3984"
                    stroke={theme.palette.accent}
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M10 15.1258V11.6758V15.1258Z"
                    fill={theme.palette.accent}
                  />
                  <path
                    d="M10 15.1258V11.6758"
                    stroke={theme.palette.accent}
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M14.2676 15.1247V9.94141"
                    stroke={theme.palette.accent}
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M14.2658 4.875L13.8824 5.325C11.7574 7.80833 8.90744 9.56666 5.73242 10.3583"
                    stroke={theme.palette.accent}
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M11.8242 4.875H14.2659V7.30833"
                    stroke={theme.palette.accent}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    opacity="0.6"
                    d="M7.49935 18.3346H12.4993C16.666 18.3346 18.3327 16.668 18.3327 12.5013V7.5013C18.3327 3.33464 16.666 1.66797 12.4993 1.66797H7.49935C3.33268 1.66797 1.66602 3.33464 1.66602 7.5013V12.5013C1.66602 16.668 3.33268 18.3346 7.49935 18.3346Z"
                    stroke={theme.palette.accent}
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6469_16334">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            }
          >
            Business Synopsis
          </Button>
        </Link>
      )}
      {!isMarketingRoute && (
        <Link
          to="/sales"
          style={{
            textDecoration: "none",
            color: theme.palette.mode === "dark" ? "#CCCCCC" : "#000",
          }}
        >
          <Button
            aria-label="Sales"
            sx={btnStyles(activeButton, "sales")}
            onClick={() => handleClick("sales")}
          >
            Sales
          </Button>
        </Link>
      )}
      {!isMarketingRoute && (
        <>
          <Button
            aria-label="Customers"
            sx={btnStyles(
              activeButton,
              "customers/dashboard",
              "customers/allcustomers"
            )}
            onClick={(event) => handleMenuClick("Customers", event)}
            aria-controls="customer-menu"
            aria-haspopup="true"
            endIcon={
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.8327 8L9.99935 13L4.16602 8"
                  stroke={theme.palette.accent}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
          >
            Customers
          </Button>
          {renderCustomersMenu()}
        </>
      )}
      {/* {renderVendorsMenu()} */}
      {!isMarketingRoute && (
        <>
          <Button
            aria-label="Inventory"
            sx={btnStyles(
              activeButton,
              "products/dashboard",
              "products/products"
            )}
            onClick={(event) => handleMenuClick("Products / Item-PLU", event)}
            aria-controls="inventory-menu"
            aria-haspopup="true"
            endIcon={
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.8327 8L9.99935 13L4.16602 8"
                  stroke={theme.palette.accent}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
          >
            Products / Item-PLU
          </Button>
          {renderInventoryMenu()}
        </>
      )}
      {!isMarketingRoute && (
        <>
          <Button
            aria-label="Employees"
            sx={btnStyles(
              activeButton,
              "employees/dashboard",
              "employees/allemployees"
            )}
            onClick={(event) => handleMenuClick("Employees", event)}
            aria-controls="employees-menu"
            aria-haspopup="true"
            endIcon={
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.8327 8L9.99935 13L4.16602 8"
                  stroke={theme.palette.accent}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
          >
            Employees
          </Button>
          {renderEmployeesMenu()}
        </>
      )}

      {isMarketingRoute && (
        <>
          <NavLink
            to="/submenu/marketing/summary"
            style={({ isActive }) => ({
              textDecoration: "none",
              color: theme.palette.mode === "dark" ? "#CCCCCC" : "#000",
            })}
          >
            {({ isActive }) => (
              <Button
                aria-label="Summary"
                sx={btnStyles(
                  isActive ? "/submenu/marketing/summary" : "",
                  "/submenu/marketing/summary"
                )}
              >
                Summary
              </Button>
            )}
          </NavLink>

          <Button
            aria-label="Campaigns"
            sx={btnStyles(
              activeButton,
              "submenu/marketing/viewCampaigns",
              "submenu/marketing/viewTemplates"
            )}
            onClick={(event) => handleMenuClick("Campaigns", event)}
            aria-controls="Campaigns-menu"
            aria-haspopup="true"
            endIcon={
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.8327 8L9.99935 13L4.16602 8"
                  stroke={theme.palette.accent}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            }
          >
            Campaigns
          </Button>
          {renderCampaignsMenu()}

          <NavLink
            to="/submenu/marketing/targetAudience"
            style={({ isActive }) => ({
              textDecoration: "none",
              color: theme.palette.mode === "dark" ? "#CCCCCC" : "#000",
            })}
          >
            {({ isActive }) => (
              <Button
                aria-label="TargetAudience"
                sx={btnStyles(
                  isActive ? "/submenu/marketing/targetAudience" : "",
                  "/submenu/marketing/targetAudience"
                )}
              >
                Target Audience
              </Button>
            )}
          </NavLink>
        </>
      )}
    </>
  )
}

export default Left
