import React from "react"
import RouteTitle from "../../../../components/RouteTitle"
import { Box, Grid } from "@mui/material"
import DatePicker from "../../../../components/DatePicker"
import { useNavigate } from "react-router-dom"
import CustomPaper from "../../../../components/CustomPaper"
import Filter from "../../Filter"
import Table from "../../../../components/datagrid/Table"
import {
  ViewTemplatesData,
  ViewTemplatesParameters,
} from "./staticTableData/viewCapaigns"
import BlurEffect from "../../../../components/BlurEffect"

const ViewTemplates = () => {
  const navigate = useNavigate()
  return (
    <>
      <BlurEffect showBlur={true} />

      <RouteTitle title="Templates">
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          maxWidth="auto"
          gap={2}
        >
          <DatePicker />
        </Box>
      </RouteTitle>
      <Box padding="0px 30px ">
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} md={4} lg={3}>
            <CustomPaper customStyles={{ paddingX: "unset" }}>
              <Filter />
            </CustomPaper>
          </Grid>
          <Grid item xs={12} md={8} lg={9}>
            <Table
              title="Templates"
              showExportButton={false}
              StaticData={ViewTemplatesData}
              StaticParameters={ViewTemplatesParameters}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default ViewTemplates
