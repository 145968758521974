import { ResponsiveBar } from "@nivo/bar"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import { useTheme } from "../../../../../contexts/theme"
import { formatNumber } from "../../../../../utils/utils"
import CustomTooltip from "../../../../CustomTooltip"
import CustomLegend from "../../../../CustomLegend"

const BodySection = ({
  chartInput1 = [],
  chartInput2 = [],
  chartConfig,
  showTooltipPrevious,
}) => {
  const {
    margin,
    padding,
    borderRadius,
    layout,
    keys,
    indexBy,
    axisBottom,
    axisLeft,
    enableLabel,
    animate,
  } = chartConfig

  const { theme } = useTheme()
  const itemTextColor = theme.palette.mode === "dark" ? "#fff" : "#333333"
  const customTheme = {
    text: {
      fill: itemTextColor,
    },
    tooltip: {
      container: {
        background: "rgba(0, 0, 0, 0.8)",
        color: "#fff",
      },
    },
    grid: {
      line: {
        stroke: "rgba(29, 71, 206, 0.3)",
        strokeWidth: 0.93,
        top: 359.59,
      },
    },
    axis: {
      domain: {
        line: {
          stroke: "rgba(29, 71, 206, 0.3)",
          strokeWidth: 1,
        },
      },
      ticks: {
        text: {
          fontSize: 10,
          fill: itemTextColor,
          outlineWidth: 0,
          outlineColor: "transparent",
        },
      },
      y: {
        domain: {
          line: {
            stroke: "rgba(255, 0, 0, 0.5)",
            strokeWidth: 20,
          },
        },
      },
    },
  }

  const ordersColor = "rgb(252, 151,72)"
  const revenueColor = "rgba(0, 158, 247, 1)"

  const customAxisBottom = {
    ...axisBottom,
    format: (value) => Math.abs(Number(value)).toString(),
    legend: "Order Count",
    legendPosition: "middle",
    legendOffset: 45,
  }

  const customAxisBottom1 = {
    ...axisBottom,
    format: (value) =>
      formatNumber(Math.abs(Number(value)), {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
    legend: "Gross Sale",
    legendPosition: "middle",
    legendOffset: 45,
  }

  const customAxisLeft = {
    ...axisLeft,
    legend: "Months",
    legendPosition: "middle",
    legendOffset: -80,
  }

  return (
    <Box spacing={1}>
      <Grid
        container
        spacing={0}
        sx={{ flexWrap: "nowrap", overflow: "visible", height: "500px" }}
      >
        <Grid item sx={{ flex: 1, minWidth: "50%" }}>
          <Box sx={{ height: "100%", width: "100%", overflow: "visible" }}>
            <ResponsiveBar
              animate={false}
              motionConfig="none"
              data={chartInput1}
              theme={customTheme}
              // margin={margin}
              margin={{ top: 10, right: 0, bottom: 60, left: 90 }}
              padding={padding}
              borderRadius={borderRadius}
              layout={layout}
              keys={["orderCount"]}
              indexBy={["idMonth"]}
              colors={ordersColor}
              axisBottom={customAxisBottom}
              axisLeft={customAxisLeft}
              enableLabel={enableLabel}
              tooltip={(point) => {
                return (
                  <CustomTooltip data={point.data} showCurrentPrevDate={true} />
                )
              }}
              label={(d) => Math.abs(d.value)}
            />
          </Box>
        </Grid>
        <Grid item sx={{ flex: 1, minWidth: "50%", overflow: "visible" }}>
          <Box sx={{ height: "100%", width: "100%", overflow: "visible" }}>
            <ResponsiveBar
              animate={false}
              motionConfig="none"
              data={chartInput2}
              theme={customTheme}
              margin={{ top: 10, right: 10, bottom: 60, left: 0 }}
              padding={padding}
              borderRadius={borderRadius}
              layout={layout}
              keys={["grossSale"]}
              // keys={["revenue"]}
              indexBy={["idMonth"]}
              colors={revenueColor}
              axisBottom={customAxisBottom1}
              axisLeft={null}
              enableLabel={enableLabel}
              tooltip={(point) => {
                return (
                  <CustomTooltip
                    data={point.data}
                    showCurrentPrevDate={true}
                    showTooltipPrevious={showTooltipPrevious}
                  />
                )
              }}
              label={(d) => Math.abs(d.value)}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default BodySection
