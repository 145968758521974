import { useTheme } from "../../contexts/theme"

const YelpLogo = () => {
  const { theme } = useTheme()
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100px"
      height="50px"
      viewBox="0 0 224 120"
      enable-background="new 0 0 224 120"
    >
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M219.000000,121.000000 
	C146.390549,121.000000 73.781097,121.000000 1.085824,121.000000 
	C1.085824,81.088875 1.085824,41.177505 1.085824,1.000000 
	C73.354012,1.000000 145.708344,1.000000 218.529816,1.468655 
	C218.996536,9.414578 219.123856,16.894732 218.954956,24.368193 
	C218.822021,30.250681 218.323776,36.124916 217.654022,42.034248 
	C216.253311,42.372929 215.186386,42.680176 214.040527,42.787148 
	C213.961578,42.586876 213.928787,42.157581 214.065521,41.832569 
	C214.547592,38.908348 214.892929,36.309139 215.310547,33.166080 
	C212.654663,35.182259 207.912888,32.735939 206.642242,37.997299 
	C202.473358,41.682281 198.664017,45.316406 194.449097,48.950706 
	C189.863373,45.767517 192.464340,41.478127 192.332291,37.697109 
	C192.153885,32.588486 192.845596,27.423912 192.340729,22.365513 
	C192.152756,20.482040 189.602432,17.305300 188.201004,17.343552 
	C182.872101,17.489010 177.454514,18.275801 172.305145,19.683012 
	C169.105408,20.557430 168.072083,26.008593 169.563538,29.345280 
	C170.829712,32.178028 171.874542,35.109715 172.612946,38.000618 
	C171.118759,38.840183 169.393250,39.483566 169.068405,40.552593 
	C168.340317,42.948559 168.308777,45.556190 167.760056,48.205345 
	C167.342621,48.626945 167.158737,48.920834 166.577576,49.183849 
	C165.120804,49.125229 164.061340,49.097488 162.783905,48.827110 
	C161.961884,49.618053 161.357834,50.651630 160.699341,52.025253 
	C160.376663,53.242176 160.108414,54.119041 159.487244,54.982998 
	C158.303879,53.537014 157.473450,52.103943 156.697357,50.764641 
	C152.480103,55.810452 156.259903,59.826794 158.622589,64.049294 
	C159.135223,63.650372 159.642868,63.250801 160.169296,62.833302 
	C160.188095,62.815372 160.239685,62.821331 160.177551,63.158623 
	C160.545090,63.934155 160.974777,64.372398 161.674759,64.859535 
	C162.274750,65.064980 162.604462,65.221527 163.002533,65.665298 
	C164.070526,65.789070 165.070175,65.625618 166.424774,65.349785 
	C167.519516,65.164574 168.259293,65.091736 169.150696,65.250870 
	C170.205444,65.073593 171.108551,64.664337 172.327469,64.135635 
	C173.089355,63.969341 173.535461,63.922501 174.166290,64.132317 
	C175.245026,63.961746 176.139023,63.534519 177.290863,62.946815 
	C178.332504,62.681770 179.116302,62.577198 179.873352,62.624924 
	C179.846573,62.777225 179.910690,63.079781 179.947174,63.526031 
	C180.177002,65.141357 180.370331,66.310440 180.542419,67.502625 
	C180.521179,67.525726 180.461945,67.546448 180.077347,67.418686 
	C178.815720,68.904900 177.938705,70.518883 176.990265,72.394943 
	C176.448349,73.202621 175.977875,73.748230 175.096863,74.212158 
	C170.049789,74.260696 170.781387,78.477211 170.535675,80.837799 
	C170.313629,82.971069 171.882797,86.161171 173.685806,87.426453 
	C176.252319,89.227547 179.834671,90.684853 182.834290,90.443130 
	C185.628662,90.217941 189.946304,88.034447 190.514099,85.895576 
	C191.380722,82.631012 189.596527,78.662758 188.862396,74.623840 
	C188.856232,73.508347 188.925522,72.762291 189.316681,71.964592 
	C190.666931,70.996750 191.695343,70.080566 192.804901,69.485046 
	C193.532944,70.556465 194.179840,71.307236 194.945587,72.226425 
	C195.064468,72.394852 195.164230,72.794914 195.109650,73.178848 
	C197.381836,77.340973 199.708618,81.119171 201.835373,85.192535 
	C198.595474,88.072571 200.642700,91.080688 202.019226,92.872856 
	C203.154144,94.350456 206.240479,94.329239 208.462036,94.972221 
	C208.811417,94.538521 209.160797,94.104828 209.510178,93.671127 
	C204.893188,91.072517 204.050949,88.161133 208.259949,84.657349 
	C208.970383,83.266708 209.428635,82.070808 209.944122,80.929916 
	C210.001358,80.984932 209.873993,80.890717 210.145386,80.849258 
	C210.599518,80.507072 210.782257,80.206360 211.112778,79.675125 
	C211.715912,78.877151 212.171265,78.309700 213.026978,77.737793 
	C216.496918,73.231979 215.603790,70.053360 210.703110,68.941040 
	C209.952957,68.564095 209.477554,68.261925 208.887482,67.716385 
	C208.438202,67.410233 208.103622,67.347435 207.866699,67.139145 
	C207.964340,66.993652 207.760544,67.281662 207.672729,66.988876 
	C207.209244,66.649635 206.833572,66.603180 206.193329,66.495743 
	C205.633942,66.229881 205.339172,66.025024 204.917755,65.585632 
	C204.455078,65.287239 204.119003,65.223381 203.528229,65.052460 
	C202.852997,64.613068 202.432510,64.280746 201.975220,63.711006 
	C201.938446,63.473579 201.818588,63.008247 202.082306,62.834633 
	C202.530853,62.074795 202.715683,61.488575 203.126572,60.641052 
	C206.206360,59.225697 209.060104,58.071640 211.942413,57.317177 
	C211.860153,59.628105 211.749313,61.539440 211.588547,64.311943 
	C212.967285,62.742687 213.634460,61.873020 214.416779,61.123531 
	C215.220108,60.353916 216.135880,59.701687 217.380051,59.045021 
	C219.433762,59.634079 221.109879,60.176197 223.853561,61.063610 
	C216.301331,69.002373 219.062256,78.286423 219.011673,87.154480 
	C218.947296,98.435974 218.998581,109.718132 219.000000,121.000000 
M139.771820,81.372009 
	C149.986877,76.407196 153.679184,65.207657 148.523697,54.825737 
	C143.987015,45.689957 135.538132,44.053486 124.912834,49.291279 
	C121.629440,48.107323 119.286186,46.447449 117.138412,46.670437 
	C113.515343,47.046597 113.628220,50.388828 113.639374,53.322353 
	C113.683029,64.796783 113.708878,76.271957 113.615997,87.745834 
	C113.589211,91.055588 114.646790,93.506546 118.145416,93.641289 
	C121.970490,93.788620 123.242859,91.194870 123.200302,87.713196 
	C123.163094,84.668701 123.192543,81.623390 123.192543,79.846756 
	C128.922409,80.494087 133.995972,81.067276 139.771820,81.372009 
M23.574520,82.604355 
	C21.933468,83.903084 18.915358,85.255493 18.963419,86.488380 
	C19.042017,88.504578 20.660654,91.176331 22.433558,92.279007 
	C28.140882,95.828766 36.359360,93.783737 40.091263,86.916687 
	C46.022839,76.002037 50.955311,64.536041 56.093479,53.204899 
	C57.276531,50.595921 56.819386,47.250221 53.611309,46.735252 
	C51.616379,46.415016 49.048351,48.190937 47.113319,49.557980 
	C46.055408,50.305355 45.738079,52.142235 45.158714,53.518082 
	C43.361572,57.785805 41.598877,62.068027 39.526890,67.057198 
	C38.399918,65.045471 37.555340,63.658421 36.825974,62.213226 
	C34.652710,57.907017 32.911419,53.310604 30.196644,49.383923 
	C29.042715,47.714867 25.886742,47.429955 23.640627,46.516003 
	C22.838322,48.796722 21.816441,51.033123 21.342667,53.380161 
	C21.155306,54.308334 22.118692,55.520218 22.675604,56.538673 
	C25.784744,62.224545 28.948425,67.880600 32.054790,73.567978 
	C33.580841,76.361984 35.157803,79.029068 32.435444,82.218834 
	C29.730326,85.388412 27.073944,83.659393 23.574520,82.604355 
M88.813599,75.975426 
	C88.483650,71.577660 86.785942,70.174515 82.992439,71.347725 
	C81.257317,71.884338 79.654572,72.843422 77.924599,73.403297 
	C73.099937,74.964706 69.171799,72.719246 67.917976,67.324341 
	C74.153229,67.324341 80.276154,67.405067 86.395935,67.300880 
	C92.136452,67.203148 93.951469,64.809776 92.631622,59.404198 
	C90.699997,51.493008 83.751137,46.093784 75.506790,46.098316 
	C66.622009,46.103199 60.651836,51.081127 58.349632,60.403942 
	C56.529160,67.775970 60.247005,76.574196 66.681610,80.121460 
	C73.813942,84.053368 83.297958,82.560532 88.813599,75.975426 
M107.594711,65.488510 
	C107.587540,56.662060 107.848213,47.820717 107.358986,39.021069 
	C107.254601,37.143532 104.692841,34.329418 102.792206,33.862026 
	C99.463402,33.043430 97.629852,35.752232 97.610352,38.997765 
	C97.533333,51.820587 97.413635,64.650940 97.848328,77.460228 
	C97.909828,79.272446 100.947136,80.983688 102.607079,82.741676 
	C104.260826,80.985352 106.889908,79.450989 107.351349,77.423325 
	C108.144516,73.938057 107.585854,70.145134 107.594711,65.488510 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M219.499481,121.000000 
	C218.998581,109.718132 218.947296,98.435974 219.011673,87.154480 
	C219.062256,78.286423 216.301331,69.002373 223.853561,61.063610 
	C221.109879,60.176197 219.433762,59.634079 217.381119,58.712006 
	C217.005280,57.887981 217.005966,57.443905 217.262054,56.788849 
	C217.679092,55.721020 217.840729,54.864174 218.362167,53.933533 
	C218.477249,49.907429 218.232529,45.955124 217.987793,42.002815 
	C218.323776,36.124916 218.822021,30.250681 218.954956,24.368193 
	C219.123856,16.894732 218.996536,9.414578 218.998474,1.468655 
	C220.992416,1.000000 222.984818,1.000000 224.982925,1.000000 
	C224.988617,41.000000 224.988617,81.000000 224.984299,121.000000 
	C223.319641,121.000000 221.659286,121.000000 219.499481,121.000000 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#E5EAFC" : "#000000"}
        opacity="1.000000"
        stroke="none"
        d="
M139.420685,81.506233 
	C133.995972,81.067276 128.922409,80.494087 123.192543,79.846756 
	C123.192543,81.623390 123.163094,84.668701 123.200302,87.713196 
	C123.242859,91.194870 121.970490,93.788620 118.145416,93.641289 
	C114.646790,93.506546 113.589211,91.055588 113.615997,87.745834 
	C113.708878,76.271957 113.683029,64.796783 113.639374,53.322353 
	C113.628220,50.388828 113.515343,47.046597 117.138412,46.670437 
	C119.286186,46.447449 121.629440,48.107323 124.912834,49.291279 
	C135.538132,44.053486 143.987015,45.689957 148.523697,54.825737 
	C153.679184,65.207657 149.986877,76.407196 139.420685,81.506233 
M123.232140,65.228180 
	C124.842667,71.403206 127.947632,74.369720 132.716278,74.289444 
	C137.034515,74.216736 140.414200,71.166763 141.569138,66.300194 
	C142.604813,61.936199 140.365692,57.191074 136.263184,55.055847 
	C130.367340,51.987255 124.705460,56.037891 123.232140,65.228180 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#E5EAFC" : "#000000"}
        opacity="1.000000"
        stroke="none"
        d="
M23.937464,82.682739 
	C27.073944,83.659393 29.730326,85.388412 32.435444,82.218834 
	C35.157803,79.029068 33.580841,76.361984 32.054790,73.567978 
	C28.948425,67.880600 25.784744,62.224545 22.675604,56.538673 
	C22.118692,55.520218 21.155306,54.308334 21.342667,53.380161 
	C21.816441,51.033123 22.838322,48.796722 23.640625,46.516003 
	C25.886742,47.429955 29.042715,47.714867 30.196644,49.383923 
	C32.911419,53.310604 34.652710,57.907017 36.825974,62.213226 
	C37.555340,63.658421 38.399918,65.045471 39.526890,67.057198 
	C41.598877,62.068027 43.361572,57.785805 45.158714,53.518082 
	C45.738079,52.142235 46.055408,50.305355 47.113319,49.557980 
	C49.048351,48.190937 51.616379,46.415016 53.611309,46.735252 
	C56.819386,47.250221 57.276531,50.595921 56.093479,53.204899 
	C50.955311,64.536041 46.022839,76.002037 40.091263,86.916687 
	C36.359360,93.783737 28.140882,95.828766 22.433558,92.279007 
	C20.660654,91.176331 19.042017,88.504578 18.963419,86.488380 
	C18.915358,85.255493 21.933468,83.903084 23.937464,82.682739 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#E5EAFC" : "#000000"}
        opacity="1.000000"
        stroke="none"
        d="
M88.665634,76.329895 
	C83.297958,82.560532 73.813942,84.053368 66.681610,80.121460 
	C60.247005,76.574196 56.529160,67.775970 58.349632,60.403942 
	C60.651836,51.081127 66.622009,46.103199 75.506790,46.098316 
	C83.751137,46.093784 90.699997,51.493008 92.631622,59.404198 
	C93.951469,64.809776 92.136452,67.203148 86.395935,67.300880 
	C80.276154,67.405067 74.153229,67.324341 67.917976,67.324341 
	C69.171799,72.719246 73.099937,74.964706 77.924599,73.403297 
	C79.654572,72.843422 81.257317,71.884338 82.992439,71.347725 
	C86.785942,70.174515 88.483650,71.577660 88.665634,76.329895 
M80.130219,61.343048 
	C81.333595,61.180634 82.536980,61.018223 84.101463,60.807076 
	C82.118790,55.909966 78.944901,53.592152 74.290955,54.307423 
	C70.784752,54.846298 68.066521,56.828320 67.384155,61.345154 
	C71.636108,61.345154 75.427933,61.345154 80.130219,61.343048 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#E5EAFC" : "#000000"}
        opacity="1.000000"
        stroke="none"
        d="
M107.594666,65.984932 
	C107.585854,70.145134 108.144516,73.938057 107.351349,77.423325 
	C106.889908,79.450989 104.260826,80.985352 102.607079,82.741676 
	C100.947136,80.983688 97.909828,79.272446 97.848328,77.460228 
	C97.413635,64.650940 97.533333,51.820587 97.610352,38.997765 
	C97.629852,35.752232 99.463402,33.043430 102.792206,33.862026 
	C104.692841,34.329418 107.254601,37.143532 107.358986,39.021069 
	C107.848213,47.820717 107.587540,56.662060 107.594666,65.984932 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M173.017319,37.997620 
	C171.874542,35.109715 170.829712,32.178028 169.563538,29.345280 
	C168.072083,26.008593 169.105408,20.557430 172.305145,19.683012 
	C177.454514,18.275801 182.872101,17.489010 188.201004,17.343552 
	C189.602432,17.305300 192.152756,20.482040 192.340729,22.365513 
	C192.845596,27.423912 192.153885,32.588486 192.332291,37.697109 
	C192.464340,41.478127 189.863373,45.767517 194.620178,49.066700 
	C195.196854,49.182518 195.561432,49.373329 195.364380,49.661552 
	C194.221741,51.276115 193.276138,52.602455 192.115051,54.006489 
	C192.053223,54.741329 192.206879,55.398476 192.563843,56.314285 
	C193.177704,57.083836 193.588272,57.594719 194.061310,58.262993 
	C194.404114,58.408279 194.684418,58.396172 195.383011,58.282333 
	C197.508774,57.949692 199.216263,57.718777 200.937378,57.844727 
	C201.600830,59.101845 202.250671,60.002098 202.900513,60.902355 
	C202.715683,61.488575 202.530853,62.074795 201.763153,62.832260 
	C200.536270,62.986179 199.892303,62.968849 198.954803,62.846333 
	C198.103714,62.463604 197.546143,62.186062 196.807480,61.675003 
	C195.759415,61.558472 194.892441,61.675449 193.816238,61.985703 
	C193.334824,62.443848 193.062653,62.708714 192.491821,63.024471 
	C192.316574,64.368866 192.439972,65.662361 192.708191,67.223175 
	C192.887924,67.690125 192.922836,67.889771 192.906296,68.359695 
	C192.811142,68.808098 192.767441,68.986237 192.723740,69.164383 
	C191.695343,70.080566 190.666931,70.996750 189.205170,71.568802 
	C188.012650,68.888161 187.253510,66.551666 186.494354,64.215172 
	C184.517456,65.303284 182.540558,66.391403 180.563660,67.479523 
	C180.370331,66.310440 180.177002,65.141357 180.169830,63.415165 
	C180.646774,62.679977 180.856079,62.432896 181.281860,61.900379 
	C185.091263,58.685184 185.027527,56.797386 181.240250,54.842468 
	C179.210724,53.794876 177.079086,52.945107 174.999390,51.755909 
	C175.002182,51.338959 174.999130,51.172165 175.391083,51.005981 
	C177.110245,50.843338 178.434372,50.680084 180.530334,50.421669 
	C177.677414,45.703865 175.347366,41.850742 173.017319,37.997620 
M190.865219,36.131767 
	C190.986664,34.143734 191.095535,32.154835 191.231674,30.167807 
	C191.989700,19.104969 188.988205,16.639824 178.228149,19.504637 
	C177.587708,19.675150 176.950989,19.870434 176.329742,20.100624 
	C170.667145,22.198795 169.322418,25.121109 172.084763,30.464754 
	C175.434341,36.944386 179.016083,43.312813 182.757080,49.574188 
	C183.543167,50.889877 185.971863,52.616241 186.741806,52.251198 
	C188.212311,51.554016 189.688538,49.608784 189.990585,47.966122 
	C190.645874,44.402458 190.563293,40.703121 190.865219,36.131767 
z"
      />
      <path
        fill="#EA2528"
        opacity="1.000000"
        stroke="none"
        d="
M174.993530,52.006062 
	C177.079086,52.945107 179.210724,53.794876 181.240250,54.842468 
	C185.027527,56.797386 185.091263,58.685184 180.983017,61.904121 
	C180.224136,62.240402 180.062119,62.356514 179.900116,62.472626 
	C179.116302,62.577198 178.332504,62.681770 176.965408,62.862457 
	C175.581940,63.250938 174.781738,63.563301 173.981552,63.875664 
	C173.535461,63.922501 173.089355,63.969341 172.000732,64.068016 
	C170.571808,64.419533 169.785446,64.719223 168.999069,65.018906 
	C168.259293,65.091736 167.519516,65.164574 166.042389,65.241989 
	C164.514740,65.290413 163.724457,65.334244 162.934158,65.378075 
	C162.604462,65.221527 162.274750,65.064980 161.627106,64.562210 
	C160.952667,63.751102 160.596176,63.286217 160.239685,62.821331 
	C160.239685,62.821331 160.188095,62.815372 160.194824,62.400574 
	C160.081100,59.655819 159.960632,57.325863 159.840164,54.995911 
	C160.108414,54.119041 160.376663,53.242176 160.920792,51.842491 
	C161.798401,50.569698 162.400131,49.819721 163.001877,49.069748 
	C164.061340,49.097488 165.120804,49.125229 166.802536,49.445068 
	C169.947708,50.493465 172.470627,51.249763 174.993530,52.006062 
z"
      />
      <path
        fill="#E92327"
        opacity="1.000000"
        stroke="none"
        d="
M192.790482,62.973579 
	C193.062653,62.708714 193.334824,62.443848 194.184906,62.035080 
	C195.504745,61.896957 196.246674,61.902740 196.988586,61.908520 
	C197.546143,62.186062 198.103714,62.463604 199.132721,63.105019 
	C200.406784,63.628738 201.209396,63.788586 202.012009,63.948433 
	C202.432510,64.280746 202.852997,64.613068 203.683197,65.259995 
	C204.410049,65.656456 204.727219,65.738312 205.044388,65.820160 
	C205.339172,66.025024 205.633942,66.229881 206.342285,66.719528 
	C207.090759,67.096771 207.425644,67.189217 207.760544,67.281662 
	C207.760544,67.281662 207.964340,66.993652 207.987656,67.371292 
	C208.341370,67.819199 208.671768,67.889473 209.002167,67.959747 
	C209.477554,68.261925 209.952957,68.564095 210.852203,69.255508 
	C214.746460,71.840179 214.071625,74.726982 212.626617,77.742249 
	C212.171265,78.309700 211.715912,78.877151 210.842575,79.717972 
	C210.241058,80.291130 210.057526,80.590919 209.873993,80.890717 
	C209.873993,80.890717 210.001358,80.984932 209.581360,81.003998 
	C203.528152,84.600258 202.131485,84.196693 198.907181,78.377892 
	C197.825119,76.425140 196.421082,74.650803 195.164215,72.794922 
	C195.164230,72.794914 195.064468,72.394852 194.978836,71.900330 
	C194.248062,70.300339 193.602905,69.194878 192.957764,68.089417 
	C192.922836,67.889771 192.887924,67.690125 192.792770,66.829407 
	C192.751846,65.103424 192.771164,64.038498 192.790482,62.973579 
z"
      />
      <path
        fill="#DF2E30"
        opacity="1.000000"
        stroke="none"
        d="
M180.542419,67.502625 
	C182.540558,66.391403 184.517456,65.303284 186.494354,64.215172 
	C187.253510,66.551666 188.012650,68.888161 188.883301,71.620453 
	C188.925522,72.762291 188.856232,73.508347 188.732407,75.017212 
	C188.523987,77.599068 188.360565,79.417358 188.217972,81.237267 
	C187.679535,88.109703 185.552200,89.545166 178.888000,87.523178 
	C171.590286,85.308960 170.590286,82.583450 174.664566,76.075455 
	C175.010773,75.522438 175.229462,74.889595 175.507401,74.293839 
	C175.977875,73.748230 176.448349,73.202621 177.281311,72.237015 
	C178.583176,70.393501 179.522552,68.969971 180.461945,67.546448 
	C180.461945,67.546448 180.521179,67.525726 180.542419,67.502625 
M180.359238,85.195580 
	C181.857254,84.791046 184.575684,84.562614 184.663437,83.954575 
	C185.488037,78.240067 185.863052,72.460686 186.367920,66.700043 
	C182.676804,70.321045 179.992966,73.780388 177.398239,77.305328 
	C173.898849,82.059250 174.095520,82.641533 180.359238,85.195580 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M172.612946,38.000618 
	C175.347366,41.850742 177.677414,45.703865 180.530334,50.421669 
	C178.434372,50.680084 177.110245,50.843338 175.070572,50.872124 
	C172.234558,49.850979 170.114090,48.964306 167.993622,48.077629 
	C168.308777,45.556190 168.340317,42.948559 169.068405,40.552593 
	C169.393250,39.483566 171.118759,38.840183 172.612946,38.000618 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M175.096863,74.212158 
	C175.229462,74.889595 175.010773,75.522438 174.664566,76.075455 
	C170.590286,82.583450 171.590286,85.308960 178.888000,87.523178 
	C185.552200,89.545166 187.679535,88.109703 188.217972,81.237267 
	C188.360565,79.417358 188.523987,77.599068 188.807831,75.386658 
	C189.596527,78.662758 191.380722,82.631012 190.514099,85.895576 
	C189.946304,88.034447 185.628662,90.217941 182.834290,90.443130 
	C179.834671,90.684853 176.252319,89.227547 173.685806,87.426453 
	C171.882797,86.161171 170.313629,82.971069 170.535675,80.837799 
	C170.781387,78.477211 170.049789,74.260696 175.096863,74.212158 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M203.126572,60.641052 
	C202.250671,60.002098 201.600830,59.101845 201.221313,57.649544 
	C204.686798,56.303726 207.902145,55.582462 211.072327,54.699051 
	C215.731827,53.400623 216.487732,51.226025 213.856949,47.174816 
	C213.424973,46.509609 213.178741,45.723782 212.915634,44.762032 
	C213.093353,44.180801 213.329559,43.984730 214.034851,44.106110 
	C215.199127,47.158405 216.021744,50.046745 216.611816,53.127686 
	C215.541641,54.182632 214.704025,55.044979 213.652832,55.908672 
	C213.439255,55.910019 213.015503,55.963673 212.775879,56.054710 
	C212.340057,56.417358 212.143845,56.688976 211.944519,56.949432 
	C211.941406,56.938274 211.913834,56.917587 211.913834,56.917587 
	C209.060104,58.071640 206.206360,59.225697 203.126572,60.641052 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M208.007767,84.852097 
	C204.050949,88.161133 204.893188,91.072517 209.510178,93.671127 
	C209.160797,94.104828 208.811417,94.538521 208.462036,94.972221 
	C206.240479,94.329239 203.154144,94.350456 202.019226,92.872856 
	C200.642700,91.080688 198.595474,88.072571 202.260315,85.214493 
	C204.592773,84.911545 206.300262,84.881821 208.007767,84.852097 
z"
      />
      <path
        fill="#E72D38"
        opacity="1.000000"
        stroke="none"
        d="
M213.693192,43.942154 
	C213.329559,43.984730 213.093353,44.180801 212.610535,44.601654 
	C210.325958,44.494888 208.413467,44.158775 206.505524,44.182861 
	C204.564896,44.207363 202.628754,44.586739 200.872375,44.521683 
	C203.698013,42.509029 206.341934,40.785404 209.111847,39.177006 
	C209.237823,39.292233 209.555817,39.416592 209.707703,39.684784 
	C210.580948,40.279427 211.302292,40.605885 212.245789,41.115288 
	C212.954880,41.584686 213.441833,41.871132 213.928787,42.157578 
	C213.928787,42.157581 213.961578,42.586876 213.933838,43.025871 
	C213.835114,43.623962 213.764160,43.783058 213.693192,43.942154 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M208.259949,84.657349 
	C206.300262,84.881821 204.592773,84.911545 202.460327,84.919319 
	C199.708618,81.119171 197.381836,77.340973 195.109634,73.178848 
	C196.421082,74.650803 197.825119,76.425140 198.907181,78.377892 
	C202.131485,84.196693 203.528152,84.600258 209.524124,80.948990 
	C209.428635,82.070808 208.970383,83.266708 208.259949,84.657349 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M159.487244,54.982998 
	C159.960632,57.325863 160.081100,59.655819 160.176025,62.418503 
	C159.642868,63.250801 159.135223,63.650372 158.622589,64.049294 
	C156.259903,59.826794 152.480103,55.810452 156.697357,50.764641 
	C157.473450,52.103943 158.303879,53.537014 159.487244,54.982998 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M209.555817,39.416592 
	C209.555817,39.416592 209.237823,39.292233 208.972641,38.905365 
	C208.138901,38.327812 207.570328,38.137127 207.001770,37.946442 
	C207.912888,32.735939 212.654663,35.182259 215.310547,33.166080 
	C214.892929,36.309139 214.547592,38.908348 214.065521,41.832565 
	C213.441833,41.871132 212.954880,41.584686 212.208313,40.760670 
	C211.151062,39.954266 210.353439,39.685429 209.555817,39.416592 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M206.642242,37.997299 
	C207.570328,38.137127 208.138901,38.327812 208.846649,38.790138 
	C206.341934,40.785404 203.698013,42.509029 200.597748,44.678452 
	C198.614746,46.540611 197.088089,47.956966 195.561432,49.373329 
	C195.561432,49.373329 195.196854,49.182518 195.025772,49.066525 
	C198.664017,45.316406 202.473358,41.682281 206.642242,37.997299 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M214.034851,44.106110 
	C213.764160,43.783058 213.835114,43.623962 214.012787,43.226143 
	C215.186386,42.680176 216.253311,42.372929 217.654007,42.034248 
	C218.232529,45.955124 218.477249,49.907429 218.228790,53.698212 
	C217.438538,53.336155 217.141449,53.135620 216.844360,52.935089 
	C216.021744,50.046745 215.199127,47.158405 214.034851,44.106110 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M213.015503,55.963669 
	C213.015503,55.963673 213.439255,55.910019 213.961700,56.051949 
	C215.324997,56.462528 216.165817,56.731182 217.006653,56.999832 
	C217.005966,57.443905 217.005280,57.887981 217.003525,58.665070 
	C216.135880,59.701687 215.220108,60.353916 214.416779,61.123531 
	C213.634460,61.873020 212.967285,62.742687 211.588547,64.311943 
	C211.749313,61.539440 211.860153,59.628105 211.942413,57.317181 
	C211.913834,56.917587 211.941406,56.938274 212.176666,56.848892 
	C212.613113,56.494228 212.814301,56.228947 213.015503,55.963669 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M213.026962,77.737785 
	C214.071625,74.726982 214.746460,71.840179 211.126984,69.330276 
	C215.603790,70.053360 216.496918,73.231979 213.026962,77.737785 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M167.760071,48.205341 
	C170.114090,48.964306 172.234558,49.850979 174.675537,50.871513 
	C174.999130,51.172165 175.002182,51.338959 174.999390,51.755909 
	C172.470627,51.249763 169.947708,50.493465 167.199829,49.475945 
	C167.158737,48.920834 167.342621,48.626945 167.760071,48.205341 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M180.077332,67.418678 
	C179.522552,68.969971 178.583176,70.393501 177.352737,71.974945 
	C177.938705,70.518883 178.815720,68.904900 180.077332,67.418678 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M162.783905,48.827110 
	C162.400131,49.819721 161.798401,50.569698 160.975220,51.502441 
	C161.357834,50.651630 161.961884,49.618053 162.783905,48.827110 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M192.906281,68.359695 
	C193.602905,69.194878 194.248062,70.300339 194.859955,71.731903 
	C194.179840,71.307236 193.532944,70.556465 192.804901,69.485046 
	C192.767441,68.986237 192.811142,68.808098 192.906281,68.359695 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M174.166290,64.132317 
	C174.781738,63.563301 175.581940,63.250938 176.707581,63.022934 
	C176.139023,63.534519 175.245026,63.961746 174.166290,64.132317 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M163.002533,65.665298 
	C163.724457,65.334244 164.514740,65.290413 165.687424,65.354370 
	C165.070175,65.625618 164.070526,65.789070 163.002533,65.665298 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        E5EAFC
        stroke="none"
        d="
M169.150696,65.250877 
	C169.785446,64.719223 170.571808,64.419533 171.684937,64.187469 
	C171.108551,64.664337 170.205444,65.073593 169.150696,65.250877 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M201.975220,63.711006 
	C201.209396,63.788586 200.406784,63.628738 199.426239,63.210205 
	C199.892303,62.968849 200.536270,62.986179 201.499420,63.005875 
	C201.818588,63.008247 201.938446,63.473579 201.975220,63.711006 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M160.177551,63.158623 
	C160.596176,63.286217 160.952667,63.751102 161.356812,64.513321 
	C160.974777,64.372398 160.545090,63.934155 160.177551,63.158623 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M204.917755,65.585632 
	C204.727219,65.738312 204.410049,65.656456 203.937912,65.367065 
	C204.119003,65.223381 204.455078,65.287239 204.917755,65.585632 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M207.672729,66.988876 
	C207.425644,67.189217 207.090759,67.096771 206.606888,66.780525 
	C206.833572,66.603180 207.209244,66.649635 207.672729,66.988876 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M208.887482,67.716385 
	C208.671768,67.889473 208.341370,67.819199 207.890015,67.516777 
	C208.103622,67.347435 208.438202,67.410233 208.887482,67.716385 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M179.873352,62.624924 
	C180.062119,62.356514 180.224136,62.240402 180.684998,62.120552 
	C180.856079,62.432896 180.646774,62.679977 180.133331,62.968918 
	C179.910690,63.079781 179.846573,62.777225 179.873352,62.624924 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M210.145386,80.849258 
	C210.057526,80.590919 210.241058,80.291130 210.694794,79.948486 
	C210.782257,80.206360 210.599518,80.507072 210.145386,80.849258 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M217.262054,56.788849 
	C216.165817,56.731182 215.324997,56.462528 214.175293,56.050598 
	C214.704025,55.044979 215.541641,54.182632 216.611816,53.127686 
	C217.141449,53.135620 217.438538,53.336155 217.868988,53.772011 
	C217.840729,54.864174 217.679092,55.721020 217.262054,56.788849 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M123.178421,64.841789 
	C124.705460,56.037891 130.367340,51.987255 136.263184,55.055847 
	C140.365692,57.191074 142.604813,61.936199 141.569138,66.300194 
	C140.414200,71.166763 137.034515,74.216736 132.716278,74.289444 
	C127.947632,74.369720 124.842667,71.403206 123.178421,64.841789 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M79.674988,61.344101 
	C75.427933,61.345154 71.636108,61.345154 67.384155,61.345154 
	C68.066521,56.828320 70.784752,54.846298 74.290955,54.307423 
	C78.944901,53.592152 82.118790,55.909966 84.101463,60.807076 
	C82.536980,61.018223 81.333595,61.180634 79.674988,61.344101 
z"
      />
      <path
        fill="#EC2124"
        opacity="1.000000"
        stroke="none"
        d="
M190.823822,36.595512 
	C190.563293,40.703121 190.645874,44.402458 189.990585,47.966122 
	C189.688538,49.608784 188.212311,51.554016 186.741806,52.251198 
	C185.971863,52.616241 183.543167,50.889877 182.757080,49.574188 
	C179.016083,43.312813 175.434341,36.944386 172.084763,30.464754 
	C169.322418,25.121109 170.667145,22.198795 176.329742,20.100624 
	C176.950989,19.870434 177.587708,19.675150 178.228149,19.504637 
	C188.988205,16.639824 191.989700,19.104969 191.231674,30.167807 
	C191.095535,32.154835 190.986664,34.143734 190.823822,36.595512 
z"
      />
      <path
        fill="#E92122"
        opacity="1.000000"
        stroke="none"
        d="
M195.364380,49.661552 
	C197.088089,47.956966 198.614746,46.540611 200.416031,44.967484 
	C202.628754,44.586739 204.564896,44.207363 206.505524,44.182861 
	C208.413467,44.158775 210.325958,44.494888 212.541565,44.833321 
	C213.178741,45.723782 213.424973,46.509609 213.856949,47.174816 
	C216.487732,51.226025 215.731827,53.400623 211.072327,54.699051 
	C207.902145,55.582462 204.686798,56.303726 201.207703,57.292679 
	C199.216263,57.718777 197.508774,57.949692 195.160736,58.146156 
	C194.346420,58.109669 194.172623,58.107639 193.998840,58.105606 
	C193.588272,57.594719 193.177704,57.083836 192.640488,55.962196 
	C192.452759,54.877224 192.391647,54.403011 192.330551,53.928795 
	C193.276138,52.602455 194.221741,51.276115 195.364380,49.661552 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M192.491821,63.024471 
	C192.771164,64.038498 192.751846,65.103424 192.647949,66.562103 
	C192.439972,65.662361 192.316574,64.368866 192.491821,63.024471 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M192.115051,54.006493 
	C192.391647,54.403011 192.452759,54.877224 192.437195,55.703529 
	C192.206879,55.398476 192.053223,54.741329 192.115051,54.006493 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M196.807480,61.675011 
	C196.246674,61.902740 195.504745,61.896957 194.394165,61.841801 
	C194.892441,61.675449 195.759415,61.558472 196.807480,61.675011 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M194.061310,58.262997 
	C194.172623,58.107639 194.346420,58.109669 194.742462,58.247883 
	C194.684418,58.396172 194.404114,58.408279 194.061310,58.262997 
z"
      />
      <path
        fill="#FA1715"
        opacity="1.000000"
        stroke="none"
        d="
M180.005096,85.039070 
	C174.095520,82.641533 173.898849,82.059250 177.398239,77.305328 
	C179.992966,73.780388 182.676804,70.321045 186.367920,66.700043 
	C185.863052,72.460686 185.488037,78.240067 184.663437,83.954575 
	C184.575684,84.562614 181.857254,84.791046 180.005096,85.039070 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M212.775879,56.054707 
	C212.814301,56.228947 212.613113,56.494228 212.179779,56.860046 
	C212.143845,56.688976 212.340057,56.417358 212.775879,56.054707 
z"
      />
      <path
        fill={theme.palette.mode === "dark" ? "#000000" : "#E5EAFC"}
        opacity="1.000000"
        stroke="none"
        d="
M209.707703,39.684784 
	C210.353439,39.685429 211.151062,39.954266 211.986160,40.577721 
	C211.302292,40.605885 210.580948,40.279427 209.707703,39.684784 
z"
      />
    </svg>
  )
}

export default YelpLogo
