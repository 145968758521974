import React, { forwardRef, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toPng } from "html-to-image"
import {
  PDFViewer,
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer"
import { CircularProgress, Typography, Box } from "@mui/material"
import { aiReportApi } from "../../api/api"
import { generateCacheKey } from "../../utils/utils"
import { useTheme } from "../../contexts/theme"
import { getDateFormatFromLocalStorage } from "../../utils/dateUtils"
import Comfortaa from "../../assets/fonts/Comfortaa-VariableFont_wght.ttf"
import { Font } from "@react-pdf/renderer"
import dayjs from "dayjs"
import Logo from "../../assets/svgs/logopng.png"
import Message from "../Message"

// Register the font
Font.register({
  family: "Comfortaa",
  format: "truetype",
  src: Comfortaa,
})

const PAGE_HEIGHT = 842
const PAGE_PADDING = 20
const TABLE_ROW_HEIGHT = 10

const PDFPreview = forwardRef(
  (
    {
      route,
      title,
      description,
      reportUid,
      setChartData,
      apiResponse,
      chartRef,
      orientation,
      pdfFields,
      headerType,
      transformFunction,
    },
    ref
  ) => {
    const { theme } = useTheme()
    const styles = StyleSheet.create({
      title: { fontSize: 14 },
      description: { fontSize: 10 },
      summaryText: { fontSize: 5 },
      chartImage: {
        padding: 10,
        width: "100%",
        height: "auto",
        backgroundColor:
          theme.palette.mode === "dark" ? "#0D2266" : "transparent",
        borderRadius: 8,
      },
      page: {
        fontFamily: "Comfortaa",
        padding: 20,
        paddingTop: 40,
        flexDirection: "column",
        rowGap: 10,
        marginBottom: 50,
      },
      section: {
        titles: {
          fontSize: 10,
          marginBottom: 5,
        },
        Analysis: {
          padding: 10,
          backgroundColor: "#ebeffc",
          borderRadius: 8,
          borderLeft: "2px solid #FCBF49",
          fontSize: 8,
        },
        Recommendations: {
          padding: 10,
          backgroundColor: "#ebeffc",
          borderRadius: 8,
          borderLeft: "2px solid #50CD89",
          fontSize: 8,
        },
        Anomalies: {
          padding: 10,
          backgroundColor: "#ebeffc",
          borderRadius: 8,
          borderLeft: "2px solid #FF0000",
          fontSize: 8,
        },
      },
      table: {
        display: "table",
        width: "100%",
        borderWidth: 1,
        borderColor: "#ddd",
        borderStyle: "solid",
        borderRadius: 5,
        overflow: "hidden",
      },
      tableHeader: {
        flexDirection: "row",
        backgroundColor: "#f0f0f0",
        textAlign: "center",
        fontSize: 10,
        borderColor: "#ccc",
        borderBottomWidth: 1,
        borderBottomColor: "#ddd",
        borderBottomStyle: "solid",
      },
      tableRow: {
        flexDirection: "row",
        fontSize: 5,
        borderBottomWidth: 1,
        borderBottomColor: "#ddd",
        borderBottomStyle: "solid",
        wrap: false,
      },
      tableCell: {
        padding: 5,
        borderColor: "#dddddd",
        fontSize: orientation === "portrait" ? 5 : 8,
        textAlign: "center",
        flex: 1.15,
        borderRightWidth: 1,
        borderRightColor: "#ddd",
        borderRightStyle: "solid",
      },
      header: {
        position: "absolute",
        top: 15,
        right: 20,
        textAlign: "right",
        width: 70,
        height: 27,
      },
      footer: {
        position: "absolute",
        width: "100%",
        bottom: 25,
        fontSize: 8,
        color: "grey",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        zIndex: 1,
        generatedOn: {
          width: "100%",
          position: "absolute",
          textAlign: "left",
        },
        pageNo: {
          width: "100%",
          right: 40,
          position: "absolute",
          textAlign: "right",
        },
      },
    })

    const dispatch = useDispatch()
    const dateFormat = getDateFormatFromLocalStorage()
    const generatedOn = dayjs().format(dateFormat)
    const [loading, setLoading] = useState(false)
    const [aiResponseState, setAiResponseState] = useState({})
    const [imgData, setImgData] = useState("")
    const [error, setError] = useState(null)
    const [pageContent, setPageContent] = useState([])
    const [isDataReady, setIsDataReady] = useState(false)

    const dateRangeData = useSelector((state) => state.calendar)
    const comparisonPeriod = useSelector((state) => state.period.selectedValue)
    const isComparedBy = comparisonPeriod.selectedValue === "Compared By"

    let reportState =
      useSelector((state) =>
        state[route]?.reports?.find((report) => report.report_uid === reportUid)
      ) || {}

    const cacheKey = generateCacheKey(
      reportUid,
      dateRangeData.fromDate,
      dateRangeData.toDate,
      comparisonPeriod.previousFromDate,
      comparisonPeriod.previousToDate,
      reportState.viewBy
    )

    useEffect(() => {
      setLoading(true)
      setError(null)
      aiReportApi(title, apiResponse)
        .then((response) => {
          if (["static", "external"].includes(reportUid)) {
            setAiResponseState(response)
          } else {
            dispatch(
              setChartData({
                reportUid,
                aiResponse: response,
                transformedPDFExportData: transformFunction(
                  apiResponse,
                  pdfFields,
                  reportUid
                ),
                cacheKey,
              })
            )
          }
        })
        .catch((err) => {
          setError(err)
          console.log("Error fetching AI report:", err)
        })
        .finally(() => setLoading(false))
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportUid, title])

    useEffect(() => {
      if (!chartRef.current) return

      const filter = (node) => {
        if (!(node instanceof HTMLElement)) {
          return true
        }
        return !node.classList.contains("pdf-export-hidden")
      }

      toPng(chartRef.current, {
        filter: filter,
        quality: 1,
        pixelRatio: 2,
        skipAutoScale: true,
      })
        .then((dataUrl) => setImgData(dataUrl))
        .catch((error) => {
          setError(error)
          console.error("Error generating image:", error)
        })
    }, [chartRef, reportUid])

    const transformedPDFExportData = reportState.transformedPDFExportData || []

    if (["static", "external"].includes(reportUid)) {
      reportState.transformedPDFExportData = transformFunction(
        apiResponse,
        pdfFields,
        reportUid
      )

      reportState.aiResponse = aiResponseState
    }

    const aiResponse = reportState?.aiResponse || {}

    const parsedAiResponse = {
      Analysis: aiResponse?.msg?.[0]?.["Analysis"] || "",
      Recommendations: aiResponse?.msg?.[1]?.["Recommendations"] || "",
      Anomalies: aiResponse?.msg?.[2]?.["Anomalies"] || "",
    }

    const dynamicKeys =
      transformedPDFExportData.length > 0
        ? Object.keys(transformedPDFExportData[0]).filter(
            (key) => key !== "date"
          )
        : []
    const formattedPreviousFromDate = dayjs(
      comparisonPeriod.previousFromDate
    ).format(dateFormat)
    const formattedPreviousToDate = dayjs(
      comparisonPeriod.previousToDate
    ).format(dateFormat)
    const formattedCurrentFromDate = dayjs(dateRangeData.fromDate).format(
      dateFormat
    )
    const formattedCurrentToDate = dayjs(dateRangeData.toDate).format(
      dateFormat
    )
    const dateRange =
      dynamicKeys.length > 0 &&
      transformedPDFExportData[0][dynamicKeys[0]]?.previous
        ? `Previous: ${formattedPreviousFromDate} - ${formattedPreviousToDate} Current: ${formattedCurrentFromDate} - ${formattedCurrentToDate}`
        : ` ${formattedCurrentFromDate} - ${formattedCurrentToDate}`

    useEffect(() => {
      let totalHeight = PAGE_PADDING
      const newPages = []
      let currentPageRows = []
      const PAGE_CONTENT_HEIGHT = PAGE_HEIGHT - 50

      for (const row of transformedPDFExportData) {
        currentPageRows.push(row)
      }

      if (currentPageRows.length > 0) {
        newPages.push(currentPageRows)
      }

      setPageContent(newPages)
      setIsDataReady(newPages.length > 0 && imgData !== "")
    }, [reportUid, imgData, transformedPDFExportData])

    return (
      <Box
        sx={{
          height: "100%",
          iframe: {
            borderRadius: "8px",
            width: "100%",
            height: "100%",
            border: "none",
          },
        }}
      >
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            gap="10px"
            height="100%"
            width="100%"
          >
            <CircularProgress disableShrink size={45} color="info" />
            <Typography variant="body2" color="inherit">
              Generating PDF. Please wait.
            </Typography>
          </Box>
        ) : error ? (
          <Message
            message="Something went wrong while generating the PDF."
            type="error"
          />
        ) : isDataReady ? (
          <PDFViewer>
            <Document>
              {pageContent.map((pageRows, pageIndex) => (
                <Page
                  size="A4"
                  orientation={orientation}
                  style={styles.page}
                  key={pageIndex}
                >
                  <Image src={Logo} alt="Logo" style={styles.header} />
                  <View style={styles.title}>
                    <Text>{title}</Text>
                  </View>
                  <View style={styles.description}>
                    <Text>{`${
                      description ? description : ""
                    } (${dateRange})`}</Text>
                  </View>
                  {imgData && <Image src={imgData} style={styles.chartImage} />}
                  <View style={styles.summaryText}>
                    {reportUid === "D393EA0B-E46F-4283-AD52-709B9B60CE3C" ? (
                      ""
                    ) : (
                      <Text>Current: Cur | Previous: Pre | % Change : %</Text>
                    )}
                  </View>
                  <View style={styles.table}>
                    {/* Table Headers */}
                    <View style={{ ...styles.tableHeader, fontsize: 15 }} fixed>
                      <Text
                        key={`date-header`}
                        style={{ ...styles.tableCell, flex: 0.272 }}
                      >
                        {headerType === "ID" ? "Name" : "Date"}
                      </Text>
                      {dynamicKeys.map((key) => (
                        <Text key={`${key}-header`} style={styles.tableCell}>
                          {key.charAt(0).toUpperCase() + key.slice(1)}
                        </Text>
                      ))}
                    </View>

                    {transformedPDFExportData[0][dynamicKeys[0]]?.previous && (
                      <View
                        style={{
                          ...styles.tableHeader,
                          fontsize: 8,
                        }}
                        fixed
                      >
                        <Text style={styles.tableCell}></Text>
                        {dynamicKeys.map((key) => (
                          <>
                            <Text
                              key={`${key}-current`}
                              style={styles.tableCell}
                            >
                              {reportUid ===
                              "D393EA0B-E46F-4283-AD52-709B9B60CE3C"
                                ? "Actual"
                                : "Cur"}
                            </Text>
                            <Text
                              key={`${key}-previous`}
                              style={styles.tableCell}
                            >
                              {reportUid ===
                              "D393EA0B-E46F-4283-AD52-709B9B60CE3C"
                                ? "Forecasted"
                                : "Pre"}
                            </Text>
                            <Text
                              key={`${key}-change`}
                              style={styles.tableCell}
                            >
                              %
                            </Text>
                          </>
                        ))}
                      </View>
                    )}

                    {/* Table Rows */}
                    {transformedPDFExportData.map((row, index) => (
                      <View
                        key={index}
                        style={{
                          ...styles.tableRow,
                          borderBottomWidth:
                            index === pageRows.length - 1 ? 0 : 1,
                          overflow: "hidden",
                        }}
                      >
                        <Text
                          style={{
                            ...styles.tableCell,
                            flex: pageRows[0][dynamicKeys[0]]?.previous
                              ? 1.1
                              : 0.272,
                          }}
                          wrap={false}
                        >
                          {row.date}
                        </Text>
                        {dynamicKeys.map((key) => (
                          <>
                            <Text
                              key={`${index}-${key}-current`}
                              style={{ ...styles.tableCell, wrap: false }}
                            >
                              {row[key]?.current
                                ? row[key].current.replace(/^-+/, "")
                                : ""}
                            </Text>

                            {row[key]?.previous && (
                              <Text
                                key={`${index}-${key}-previous`}
                                style={styles.tableCell}
                              >
                                {row[key]?.previous}
                              </Text>
                            )}
                            {row[key]?.changePercent && (
                              <Text
                                key={`${index}-${key}-change`}
                                style={[
                                  styles.tableCell,
                                  {
                                    color:
                                      parseFloat(row[key]?.changePercent) < 0
                                        ? "red"
                                        : parseFloat(
                                            row[key]?.changePercent
                                          ) === 0 ||
                                          row[key]?.changePercent === "-"
                                        ? "black"
                                        : "green",
                                    wrap: false,
                                  },
                                ]}
                              >
                                {row[key]?.changePercent}
                              </Text>
                            )}
                          </>
                        ))}
                      </View>
                    ))}
                  </View>
                  <View style={styles.footer} fixed>
                    <Text style={styles.footer.generatedOn}>
                      Generated on: {generatedOn}
                    </Text>
                    <Text
                      style={styles.footer.pageNo}
                      render={({ pageNumber, totalPages }) =>
                        `Page ${pageNumber} of ${totalPages}`
                      }
                    />
                  </View>
                </Page>
              ))}
              <Page size="A4" orientation={orientation} style={styles.page}>
                <Image src={Logo} alt="Logo" style={styles.header} />
                {Object.entries(parsedAiResponse).map(([key, value]) => (
                  <View key={key}>
                    <Text style={styles.section.titles}>{key}</Text>
                    <View style={styles.section[key]}>
                      <Text>{value}</Text>
                    </View>
                  </View>
                ))}
                <View style={styles.footer} fixed>
                  <Text style={styles.footer.generatedOn}>
                    Generated on: {generatedOn}
                  </Text>
                  <Text
                    style={styles.footer.pageNo}
                    render={({ pageNumber, totalPages }) =>
                      `Page ${pageNumber} of ${totalPages}`
                    }
                  />
                </View>
              </Page>
            </Document>
          </PDFViewer>
        ) : (
          <Typography variant="body2" color="inherit">
            Loading data for PDF...
          </Typography>
        )}
      </Box>
    )
  }
)

export default PDFPreview
