import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import VerifiedIcon from "@mui/icons-material/Verified"
import NewReleasesIcon from "@mui/icons-material/NewReleases"
import { calculateAggregate } from "./aggregateFunctions"
import { getFormattedValue } from "../../utils/utils"
import ArrowRise from "../../assets/svgs/ArrowRise"
import ArrowFall from "../../assets/svgs/ArrowFall"
import { getDateFormatFromLocalStorage } from "../../utils/dateUtils"
import dayjs from "dayjs"

const supportedIcons = {
  VerifiedIcon: (sx = { fill: "#23BF6F" }) => (
    <VerifiedIcon height={15} width={15} sx={sx} />
  ),
  NewReleasesIcon: (sx = { fill: "#EC2D30" }) => (
    <NewReleasesIcon height={15} width={15} sx={sx} />
  ),
  Pipe: (sx = { fill: "#F19004" }) => (
    <Box
      sx={{
        height: 19,
        width: 5,
        borderRadius: 3,
        mt: "1px",
        backgroundColor: sx?.fill,
      }}
    />
  ),
}

const dateFormat = getDateFormatFromLocalStorage()
const MacroMetrics = ({ data = [], config = [], reportUid }) => {
  if (!Array.isArray(config)) {
    console.error("Invalid config array", config)
    return null // Exit early if config is not an array
  }

  const macroMetrics = config
    .map((metricConfig) => {
      // Ensure metricConfig is not null or undefined
      if (!metricConfig || typeof metricConfig !== "object") {
        console.warn("Invalid metricConfig", metricConfig)
        return null // Skip invalid metricConfig
      }

      // Ensure the necessary properties exist in metricConfig
      const targetField = metricConfig?.targetField
      const aggregateFunc = metricConfig?.aggregateFunc

      if (!targetField || !aggregateFunc) {
        console.warn(
          "Missing targetField or aggregateFunc in metricConfig",
          metricConfig
        )
        return null // Skip invalid config items
      }

      let aggregateValue = calculateAggregate(
        data,
        targetField,
        aggregateFunc
      )?.result

      // Handle cases where aggregateValue might be null or undefined
      let formattedAggregateValue = getFormattedValue(
        aggregateValue,
        metricConfig.format || "Currency"
      )

      if (metricConfig.format === "Percentage") {
        formattedAggregateValue =
          aggregateValue > 0 ? (
            <>
              {formattedAggregateValue}
              <ArrowRise />
            </>
          ) : (
            <>
              {formattedAggregateValue}
              <ArrowFall />
            </>
          )
      }

      // Fill in the aggregate value for specified calculate fields
      let textRows = ["firstRow", "secondRow"]
      textRows.forEach((row) => {
        if (
          !metricConfig[row] ||
          metricConfig[row] === null ||
          metricConfig[row]?.operation === null
        )
          return

        if (metricConfig[row]?.operation === "aggregate") {
          metricConfig[row].value = formattedAggregateValue
        } else if (metricConfig[row]?.operation === "templateString") {
          let dataPoint = calculateAggregate(data, targetField, aggregateFunc)

          const templateString = metricConfig[row]?.value || ""
          let updatedValue = templateString

          Object.entries(dataPoint?.relatedObjects || {}).forEach(
            ([key, value]) => {
              updatedValue = updatedValue.replace(
                new RegExp(`{${key}}`, "g"),
                value
              )
            }
          )

          metricConfig[row].value = updatedValue
        }
      })

      // Fill in the icon
      metricConfig.icon = supportedIcons[metricConfig.icon]
        ? supportedIcons[metricConfig.icon]({
            fill: metricConfig.color,
          })
        : null

      return metricConfig
    })
    .filter(Boolean) // Filter out any null entries

  if (macroMetrics.length === 0) {
    console.warn("No valid macro metrics found in the config.")
  }

  return (
    <Box display="flex" flexDirection="row" gap={2.5}>
      {macroMetrics.map((macroMetric, index) => (
        <Box key={index} display="flex" flexDirection="row" gap={1}>
          {macroMetric.icon}
          <Stack display="flex" flexDirection="column">
            {macroMetric.firstRow?.value && (
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "700",
                  color: macroMetric.color,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {macroMetric.firstRow?.value}
              </Typography>
            )}

            {macroMetric.secondRow?.value && (
              <Typography
                color="#A6A6A6"
                sx={{
                  fontSize: "12px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {reportUid === "FF13F014-D110-4E82-A68C-61A95AEFED3B" ||
                reportUid === "08856963-D5CC-4B39-962E-EDBE77E0C3F3" ||
                data[0]?.reportType === "Calendar"
                  ? typeof macroMetric.secondRow?.value === "string" &&
                    macroMetric.secondRow?.value.includes("to")
                    ? macroMetric.secondRow?.value
                        .split(" to ")
                        .map((date) => dayjs(date).format(dateFormat))
                        .join(" to ")
                    : dayjs(macroMetric.secondRow?.value).format(dateFormat)
                  : macroMetric.secondRow?.value}
              </Typography>
            )}

            {macroMetric.thirdRow?.value && (
              <Typography
                color="#A6A6A6"
                sx={{
                  fontSize: "12px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {reportUid !== "C3C052D5-73B7-4BCF-8CEF-F52B0823DAA8" &&
                  reportUid !== "7AEA1930-BFAE-450D-962D-1B315020F0F6" &&
                  reportUid !== "7D277C9B-9535-4F45-8B5F-27C1E8B389FE" &&
                  macroMetric.thirdRow?.value}
              </Typography>
            )}
          </Stack>
        </Box>
      ))}
    </Box>
  )
}

export default MacroMetrics
