import React, { useState, useEffect } from "react"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import { useSelector, useDispatch } from "react-redux"
import CircularProgress from "@mui/material/CircularProgress"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import { useTheme } from "../../../contexts/theme"
import { sunburstData, sunburstParameters } from "../../../constants/chartsData"
import InfoToolTip from "../../InfoToolTip"
import InfoLogoSvg from "../../../assets/svgs/InfoLogoSvg"
import { postData } from "../../../api/api"
import ViewBy from "../../ViewBy"
import Analytica from "../../Analytica"
import DataTable from "./DataTable"

const TableChart = ({
  route,
  setChartData,
  comparisonPeriod,
  title,
  description,
  postUrl,
  reportUid,
  parameters,
  dateRangeData = { fromDate: "", toDate: "" },
  data,
}) => {
  const extractedParameters = extractKeyValuePairs(parameters)
  const [viewBy, setViewBy] = useState("Day")
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const dispatch = useDispatch()
  const { theme } = useTheme()
  const [combinedState, setCombinedState] = useState({
    comparisonPeriod: comparisonPeriod,
    dateRangeData: dateRangeData,
  })
  useEffect(() => {
    setCombinedState({
      comparisonPeriod,
      dateRangeData,
    })
  }, [comparisonPeriod, dateRangeData])

  const fetchData = async () => {
    try {
      // const response = await postData(postUrl, {
      //   entityDb: localStorage.getItem("entityDb"),
      //  fromDate: combinedState.dateRangeData.fromDate,
      // toDate: combinedState.dateRangeData.toDate,
      //   reportInstanceId: reportUid,
      // });
      const response = false

      if (!!response === false || Object.keys(response).length === 0) {
        setError("Inadequate data points to generate a report")
        dispatch(setChartData({ reportUid, response: sunburstData }))
      } else {
        dispatch(setChartData({ reportUid, response }))
      }
    } catch (error) {
      setError("Inadequate data points to generate a report")
      dispatch(setChartData({ reportUid, response: sunburstData }))
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    setChartData({
      reportUid,
      viewBy: viewBy || "Day",
    })
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reportUid,
    combinedState.comparisonPeriod.previousFromDate,
    combinedState.comparisonPeriod.previousToDate,
    combinedState.dateRangeData.fromDate,
    combinedState.dateRangeData.toDate,
  ])

  const response = useSelector((state) => {
    const selectedReport = state[route]?.reports?.find(
      (report) => report.report_uid === reportUid
    )
    return selectedReport ? selectedReport.reportData : null
  })

  const svgColor = theme.palette.accentSecondary

  return (
    <>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <div className="sales">{title}</div>
          <InfoToolTip title={description}>
            <span style={{ height: "16px", cursor: "pointer" }}>
              <InfoLogoSvg svgColor={svgColor} />
            </span>
          </InfoToolTip>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={"2px"}
        >
          {extractedParameters.view_by_period && (
            <ViewBy
              route={route}
              parameters={
                extractedParameters && extractedParameters.view_by_period
              }
              viewBy={viewBy}
              setViewBy={setViewBy}
              reportUid={reportUid}
              dateRangeData={dateRangeData}
            />
          )}
          <Box display="flex">
            {extractedParameters.focus_mode && (
              <Analytica description={description} />
            )}
          </Box>
        </Box>
      </Box>

      <Box minHeight="320px" sx={{ overflowX: "auto" }}>
        {loading ? (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            minHeight="320px"
            width="100%"
          >
            <CircularProgress disableShrink size={45} color="info" />
            <Typography variant="body2" color="inherit">
              Fetching data, please wait...
            </Typography>
          </Box>
        ) : error ? (
          <DataTable data={data.data} />
        ) : (
          <DataTable chartConfig={extractedParameters} data={data.data} />
        )}
      </Box>
    </>
  )
}

export default TableChart
