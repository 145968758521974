import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"

import RouteTitle from "../../components/RouteTitle"
import CustomPaper from "../../components/CustomPaper"
import { useTheme } from "../../contexts/theme"
import { useSelector } from "react-redux"
import SimpleTable from "./SimpleTable"
import DatePicker from "../../components/DatePicker"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Backdrop from "@mui/material/Backdrop"

import { useNavigate } from "react-router-dom"
import { useEffect } from "react"
import KPICard from "../../components/KPICard_re"
import Logo from "../../assets/svgs/Logo"
import GroupBarContainer from "../../components/charts/GroupBar/GroupBarContainer"
import { setChartData } from "../../redux/slices/businessSynopsisSlice"

const BusinessSynopsis = () => {
  const navigate = useNavigate()
  const { theme } = useTheme()
  const comparisonPeriod = useSelector((state) => state.period.selectedValue)
  const dateRangeData = useSelector((state) => state.calendar)

  useEffect(() => {
    if (localStorage.getItem("dataStatus")?.trim() === "Ready") {
      navigate("/businesssynopsis")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRangeData])

  const ProductWastage = [
    {
      "Product Name": "Tuna Sandwich",
      Reason: "Overcooking",
      "Quantity Wasted": 30,
    },
    {
      "Product Name": "CFS Breakfast",
      Reason: "Improper Storage",
      "Quantity Wasted": 10,
    },
    {
      "Product Name": "Egg Benedict",
      Reason: "Expired Ingredients",
      "Quantity Wasted": 15,
    },
    {
      "Product Name": "Toast",
      Reason: "Burnt",
      "Quantity Wasted": 25,
    },
    {
      "Product Name": "BLT",
      Reason: "Incorrect Orders",
      "Quantity Wasted": 10,
    },
  ]
  const ProductReturns = [
    {
      "Product Name": "Egg Benedict",
      "No. of Returns": 30,
      Reason: "Overcooking",
      "Affected Customers": "John Doe, Jane Smith",
    },
    {
      "Product Name": "Irish Skillet",
      "No. of Returns": 10,
      Reason: "Improper Storage",
      "Affected Customers": "Emily Clark, Michael Brown",
    },
    {
      "Product Name": "Patty Melt",
      "No. of Returns": 15,
      Reason: "Expired Ingredients",
      "Affected Customers": "Sarah Johnson, Michael Brown",
    },
    {
      "Product Name": "Salad",
      "No. of Returns": 25,
      Reason: "Burnt",
      "Affected Customers": "Jane Smith, Sarah Johnson",
    },
    {
      "Product Name": "Breakfast Tacos",
      "No. of Returns": 10,
      Reason: "Incorrect Orders",
      "Affected Customers": "John Doe, Emily Clark",
    },
  ]
  const UnusualProductsdiscount = [
    {
      "Product Name": "Hot Turkey",
      "No. of Returns": 30,
      Reason: "Overcooking",
      "Affected Customers": "John Doe, Jane Smith",
    },
    {
      "Product Name": "Reuben",
      "No. of Returns": 10,
      Reason: "Improper Storage",
      "Affected Customers": "Emily Clark, Michael Brown",
    },
    {
      "Product Name": "Strawberry Limonade",
      "No. of Returns": 15,
      Reason: "Expired Ingredients",
      "Affected Customers": "Sarah Johnson, Michael Brown",
    },
    {
      "Product Name": "Patty Melt",
      "No. of Returns": 25,
      Reason: "Burnt",
      "Affected Customers": "Jane Smith, Sarah Johnson",
    },
    {
      "Product Name": "Side Links",
      "No. of Returns": 10,
      Reason: "Incorrect Orders",
      "Affected Customers": "John Doe, Emily Clark",
    },
  ]
  const UnusualDayswithReducedorZeroSales = [
    {
      Reason: "Hurricane Beryl",
      "Sales Impact": "Zero Sales",
      Date: "12 Aug - 13 Aug, 2024",
    },
    {
      Reason: "Power Outage",
      "Sales Impact": "Reduced Sales (50% drop)",
      Date: "23 Mar, 2024",
    },
    {
      Reason: "Local Holiday (Festival)",
      "Sales Impact": "Zero Sales",
      Date: "23 Apr, 2024",
    },
    {
      Reason: "Staff Strike",
      "Sales Impact": "Reduced Sales (70% drop)",
      Date: "5 Jun, 2024",
    },
    {
      Reason: "Christmas Day (Closure)",
      "Sales Impact": "Zero Sales",
      Date: "25 Dec, 2023",
    },
  ]
  const PremiumCustomerswithReducedActivity = [
    {
      "Customer Name": "Adam Johnson",
      "Reason for Decline": "Found alternatives",
      Percentage: "-43.01%",
    },
    {
      "Customer Name": "Lisa Williams",
      "Reason for Decline": "Pricing concerns",
      Percentage: "-32.01%",
    },
    {
      "Customer Name": "Kevin Martin",
      "Reason for Decline": "Dissatisfied experience",
      Percentage: "-30.01%",
    },
    {
      "Customer Name": "Sophia Davis",
      "Reason for Decline": "Location change",
      Percentage: "-24.01%",
    },
    {
      "Customer Name": "Brian Taylor",
      "Reason for Decline": "Reduced needs",
      Percentage: "-16.56%",
    },
  ]
  const LoyalCustomerswithReducedActivity = [
    {
      "Customer Name": "Jenna Nguyen",
      "Reason for Decline": "Shifted preferences",
      Percentage: "-54.01%",
    },
    {
      "Customer Name": "Jane Smith",
      "Reason for Decline": "Seasonal changes",
      Percentage: "-49.54%",
    },
    {
      "Customer Name": "Emily Clark",
      "Reason for Decline": "Competitive offers",
      Percentage: "-42.64%",
    },
    {
      "Customer Name": "Michael Brown",
      "Reason for Decline": "Vacation/absence",
      Percentage: "-34.79%",
    },
    {
      "Customer Name": "Sarah Johnson",
      "Reason for Decline": "Financial reasons",
      Percentage: "-24.75%",
    },
  ]
  const FrequentlyBoughtTogether = [
    {
      Parings: "2 Egg Combo + Coffee + Side Bacon",
      Freq: { value: "+80" },
      Percentage: "+18.05%",
      // Price: "$2,400",
    },
    {
      Parings: "Blueberry Bliss Pancakes + Coffee",
      Freq: { value: "-70" },
      Percentage: "-11.01%",
      //Price: "$1,800",
    },
    {
      Parings: "Chef Salad + 2 Egg Combo",
      Freq: { value: "+90" },
      Percentage: "+45.05%",
      //Price: "$1,350",
    },
    {
      Parings: "Spaghetti Bolognese + Bowl of Soup",
      Freq: { value: "-35" },
      Percentage: "-11.01%",
      //Price: "$1,700",
    },
    {
      Parings: "Waffle + 2 PC Broiled Cod",
      Freq: { value: "+80" },
      Percentage: "+53.05%",
      //Price: "$1,600",
    },
  ]
  const data = [
    {
      color: "#50CD89",
      customer: "Jenna Nguyen",
      favoriteFood: "Cappuccino Muffin",
      mealDayPreference: "Friday",
      visitFreq: "4x/month",
      // age: "+25%",
    },
    {
      color: "#50CD89",
      customer: "Erika Sanchez",
      favoriteFood: "Caesar Salad Lemonade",
      mealDayPreference: "Saturday",
      visitFreq: "3x/month",
      //age: "+25%",
    },
    {
      color: "#3183E3",
      customer: "Devan Scott",
      favoriteFood: "Grilled Chicken Garlic Bread",
      mealDayPreference: "Wednesday",
      visitFreq: "5x/month",
      //age: "-20%",
    },
    {
      color: "#F2994A",
      customer: "Tiffany Bryan",
      favoriteFood: "Cheeseburger French Fries",
      mealDayPreference: "Monday",
      visitFreq: "2x/month",
      // age: "-15%",
    },
    {
      color: "#595959",
      customer: "Michael Patrick",
      favoriteFood: "Spaghetti Bolognese",
      mealDayPreference: "Friday",
      visitFreq: "1x/month",
      //age: "-%10",
    },
  ]
  const headerColor = theme.palette.mode === "dark" ? "#acacac" : "#747474"
  const columnStyles = {}
  const rowStyle = {}
  const handleCreateCampgain = () => {
    navigate("/submenu/marketing/createcampaign")
  }

  useEffect(() => {
    document.body.style.overflow = "hidden"

    return () => {
      document.body.style.overflow = "auto"
    }
  }, [])

  return (
    <Box position="relative">
      {/* overlay */}
      <Box
        sx={{
          position: "absolute",
          height: "100%",
          width: "100%",
          top: 0,
          zIndex: 6,
        }}
      >
        <Backdrop
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            backgroundColor: "#000000B0",
            backdropFilter: "blur(4px)",
          }}
          open={true}
        >
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            justifyContent="center"
            height="100vh"
          >
            <Typography variant="h5" color="white" fontWeight="fontWeightBold">
              We're setting things up for you, hang tight!
            </Typography>
            <Logo width="177" height="80" />
          </Box>
        </Backdrop>
      </Box>

      <RouteTitle>
        <Stack sx={{ maxWidth: { md: "1250px" } }} gap="3px">
          {localStorage.getItem("fname") && (
            <Typography
              variant="subtitle1"
              color="inherit"
              fontWeight="fontWeightMedium"
            >
              {`Good to see you again, ${localStorage.getItem("fname")}!`}
            </Typography>
          )}

          <Typography
            variant="body2"
            color="inherit"
            fontWeight="fontWeightLight"
            sx={{ fontSize: "0.8rem", opacity: 0.9 }}
          >
            {localStorage.getItem("fname") &&
              ` Name: ${localStorage.getItem("fname")}`}{" "}
            &nbsp;
            <span style={{ opacity: 0.6 }}>|</span> Address: 1000 Main st,
            Houston, TX&nbsp;
            <span style={{ opacity: 0.6 }}>|</span> Phone: +1 111 111 1111&nbsp;
            <span style={{ opacity: 0.6 }}>|</span> Location: Houston&nbsp;
            <span style={{ opacity: 0.6 }}>|</span> Site: Houston
          </Typography>
        </Stack>

        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          maxWidth="auto"
          gap={2}
        >
          <DatePicker />
        </Box>
      </RouteTitle>
      <Stack padding="15px 30px 15px 30px" gap={2}>
        <Box>
          <Typography
            variant="h6"
            color="inherit"
            mb={2}
            fontWeight="fontWeightSemiBold"
          >
            Actionable Insights & Analysis
          </Typography>
          <Stack gap={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomPaper
                  customStyles={{
                    border:
                      theme.palette.mode === "dark" ? "2px solid #0C4126" : "",
                    background:
                      theme.palette.mode === "dark"
                        ? "linear-gradient(120deg, #23BF6F -28.02%, #0D2266 41.73%)"
                        : "linear-gradient(120deg, #23BF6F -28.02%, #E9EDFC 41.73%)",
                  }}
                  innerCustomStyles={{
                    border: "none",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="inherit"
                    mb={2}
                    fontWeight="fontWeightSemiBold"
                  >
                    Positive Trends
                  </Typography>
                  <Grid container spacing={2}>
                    {[
                      {
                        reportName: "Gross Sales",
                        extApiResponse: {
                          reportDataCurrent: [
                            { value: 297392.17 },
                          ],
                          reportDataPrevious: [
                            { value: 291621.3 },
                          ],
                          changePercentage: 1.98,
                        },
                      },
                      {
                        reportName: "Transactions",
                        extApiResponse: {
                          reportDataCurrent: [
                            { value: 8493 },
                          ],
                          reportDataPrevious: [
                            { value: 8348 },
                          ],
                          changePercentage: 1.73,
                        },
                      },
                      {
                        reportName: "Average Ticket",
                        extApiResponse: {
                          reportDataCurrent: [
                            { value: 35.02 },
                          ],
                          reportDataPrevious: [
                            { value: 34.93 },
                          ],
                          changePercentage: 0.26,
                        },
                      },
                      {
                        reportName: "Repeat Customers",
                        extApiResponse: {
                          reportDataCurrent: [
                            { value: 847 },
                          ],
                          reportDataPrevious: [
                            { value: 655 },
                          ],
                          changePercentage: 29.31,
                        },
                      },
                      {
                        reportName: "Product Sold",
                        extApiResponse: {
                          reportDataCurrent: [
                            { value: 265 },
                          ],
                          reportDataPrevious: [
                            { value: 257 },
                          ],
                          changePercentage: 3.12,
                        },
                      },
                    ].map((report, index) => (
                      <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
                        <CustomPaper
                          key={index}
                          customStyles={{ height: "100%" }}
                          watermarkBg={true}
                          CustomDrawerComponent={true}
                        >
                          <KPICard
                            title={report.reportName}
                            description={report.reportDescription}
                            postUrl="businessSynopsis/data"
                            route="businessSynopsis"
                            setChartData={setChartData}
                            reportUid="external"
                            configurations={[
                              {
                                parameterType: "LABELS",
                                reportParameters: [
                                  {
                                    parameterName: "meta_info",
                                    parameterValue: '{"format":"Currency"}',
                                  },
                                ],
                              },
                            ]}
                            extApiResponse={report.extApiResponse}
                            extLoading={false}
                            extError={null}
                            extComparisonPeriod={true}
                          />
                        </CustomPaper>
                      </Grid>
                    ))}
                  </Grid>
                </CustomPaper>
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomPaper
                  customStyles={{
                    border:
                      theme.palette.mode === "dark" ? "2px solid #460607" : "",
                    background:
                      theme.palette.mode === "dark"
                        ? "linear-gradient(120deg, #EE000C -28.02%, #0E256D 41.73%)"
                        : "linear-gradient(120deg, #EE000C -28.02%, #E9EDFC 41.73%)",
                  }}
                  innerCustomStyles={{
                    border: "none",
                  }}
                >
                  <Typography
                    variant="body1"
                    color="inherit"
                    mb={2}
                    fontWeight="fontWeightSemiBold"
                  >
                    Negative Trends
                  </Typography>
                  <Grid container spacing={2}>
                    {[
                      {
                        reportName: "New Customers",
                        extApiResponse: {
                          reportDataCurrent: [
                            { value: 417 },
                          ],
                          reportDataPrevious: [
                            { value: 910 },
                          ],
                          changePercentage: -54.16,
                        },
                      },
                      {
                        reportName: "Avg Sales/Employee",
                        extApiResponse: {
                          reportDataCurrent: [
                            { value: 12391.34 },
                          ],
                          reportDataPrevious: [
                            { value: 12679.19 },
                          ],
                          changePercentage: -2.27,
                        },
                        format: "Currency",
                      },
                      {
                        reportName: "Feedback Rating",
                        extApiResponse: {
                          reportDataCurrent: [
                            { value: 4 },
                          ],
                          reportDataPrevious: [
                            { value: 10 },
                          ],
                          changePercentage: -60,
                        },
                        star: true,
                      },
                      {
                        reportName: "Profit / Loss Percent",
                        extApiResponse: {
                          reportDataCurrent: [
                            { value: 74.32 },
                          ],
                          reportDataPrevious: [
                            { value: 79.46 },
                          ],
                          changePercentage: -6.43,
                        },
                      },
                      {
                        reportName: "One-Time Customers",
                        extApiResponse: {
                          reportDataCurrent: [
                            { value: 51 },
                          ],
                          reportDataPrevious: [
                            { value: 154 },
                          ],
                          changePercentage: -66.23,
                        },
                      },
                    ].map((report, index) => (
                      <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
                        <CustomPaper
                          key={index}
                          customStyles={{ height: "100%" }}
                          watermarkBg={true}
                          CustomDrawerComponent={true}
                        >
                          <KPICard
                            title={report.reportName}
                            description={report.reportDescription}
                            postUrl="businessSynopsis/data"
                            route="businessSynopsis"
                            setChartData={setChartData}
                            reportUid="external"
                            configurations={[
                              {
                                parameterType: "LABELS",
                                reportParameters: [
                                  {
                                    parameterName: "meta_info",
                                    parameterValue: '{"format":"Currency"}',
                                  },
                                ],
                              },
                            ]}
                            extApiResponse={report.extApiResponse}
                            extLoading={false}
                            extError={null}
                            extComparisonPeriod={true}
                          />
                        </CustomPaper>
                      </Grid>
                    ))}
                  </Grid>
                </CustomPaper>
              </Grid>
            </Grid>
          </Stack>
        </Box>
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            gap={1}
            alignItems="center"
            mb={2}
          >
            <Box
              height="12px"
              width="12px"
              borderRadius="4px"
              bgcolor="#FCBF49"
            />
            <Typography
              variant="h6"
              color="inherit"
              fontWeight="fontWeightSemiBold"
            >
              Analysis – Patterns
            </Typography>
          </Box>
          <Stack gap={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <CustomPaper
                  customStyles={{
                    borderLeft: "2px solid #FCBF49",
                    height: "100%",
                  }}
                  innerCustomStyles={{
                    border: 0,
                  }}
                >
                  <Typography
                    variant="body1"
                    color="inherit"
                    mb={2}
                    fontWeight="fontWeightSemiBold"
                  >
                    Frequently Bought Together
                  </Typography>
                  <CustomPaper
                    customStyles={{
                      p: 0,
                      background:
                        theme.palette.mode === "dark" ? "#071339" : "#fafafa",
                      border: theme.palette.mode === "dark" ? 0 : 1,
                      borderColor: "#e6e6e6",
                      boxShadow: "unset",
                    }}
                  >
                    <SimpleTable
                      data={FrequentlyBoughtTogether}
                      columnStyles={columnStyles}
                      rowStyle={rowStyle}
                    />
                  </CustomPaper>
                  <Box
                    mt={1}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Button
                      variant="text"
                      sx={{
                        fontSize: "0.8rem",
                        fontWeight: 700,
                        color: theme.palette.accent,
                        cursor: "pointer",
                        p: 0,
                        textTransform: "none",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      + View More
                    </Button>
                  </Box>
                </CustomPaper>
              </Grid>
              <Grid item xs={12} md={7}>
                <CustomPaper
                  customStyles={{
                    overflow: "visible",
                    borderLeft: "2px solid #FCBF49",
                    height: "100%",
                  }}
                  innerCustomStyles={{
                    border: 0,
                  }}
                >
                  <Typography
                    variant="body1"
                    color="inherit"
                    fontWeight="fontWeightSemiBold"
                  >
                    Customers Visiting Pattern
                  </Typography>
                  <CustomPaper
                    customStyles={{
                      p: 0,
                      overflow: "visible",
                      boxShadow: "",
                    }}
                  >
                    <GroupBarContainer
                      route="sales"
                      title=""
                      description=""
                      reportUid="static-groupbar"
                      showLegend={false}
                      setChartData={setChartData}
                      comparisonPeriod={comparisonPeriod}
                      postUrl="sales/data"
                      dateRangeData={dateRangeData}
                    />
                  </CustomPaper>
                  {/* <Box
                    mt={-1}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Button
                      variant="text"
                      sx={{
                        fontSize: "0.8rem",
                        fontWeight: 700,
                        color: theme.palette.accent,
                        cursor: "pointer",
                        p: 0,
                        textTransform: "none",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      + View More
                    </Button>
                  </Box> */}
                </CustomPaper>
              </Grid>
            </Grid>
            <CustomPaper
              customStyles={{
                overflow: "visible",
                borderLeft: "2px solid #FCBF49",
              }}
              innerCustomStyles={{
                border: 0,
              }}
            >
              <Typography
                variant="body1"
                color="inherit"
                mb={2}
                fontWeight="fontWeightSemiBold"
              >
                Top loyal customers and their fav foods
              </Typography>
              <CustomPaper
                customStyles={{
                  p: 0,
                  background:
                    theme.palette.mode === "dark" ? "#071339" : "#fafafa",
                  border: theme.palette.mode === "dark" ? 0 : 1,
                  borderColor: "#e6e6e6",
                  boxShadow: "unset",
                }}
              >
                <TableContainer>
                  <Box
                    width="100%"
                    display="flex"
                    flexDirection="row"
                    gap="4px"
                    sx={{ p: 1 }}
                  >
                    <Box
                      height="4px"
                      flex={0.6}
                      sx={{ borderRadius: "3px", background: "#50CD89" }}
                    />
                    <Box
                      height="4px"
                      flex={0.175}
                      sx={{ borderRadius: "3px", background: "#3183E3" }}
                    />
                    <Box
                      height="4px"
                      flex={0.175}
                      sx={{ borderRadius: "3px", background: "#F2994A" }}
                    />
                    <Box
                      height="4px"
                      flex={0.05}
                      sx={{ borderRadius: "3px", background: "#595959" }}
                    />
                  </Box>
                  <Table
                    aria-label="customer table"
                    sx={{ borderCollapse: "collapse" }}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            padding: "0.6rem 0.7rem",
                            ...columnStyles,
                          }}
                        />
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            padding: "0.6rem 0.7rem",
                            ...columnStyles,
                          }}
                        >
                          <Typography fontSize="0.78rem" color={headerColor}>
                            Customers
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            padding: "0.6rem 0.7rem",
                            ...columnStyles,
                          }}
                        >
                          <Typography fontSize="0.78rem" color={headerColor}>
                            Favourate Food
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            padding: "0.6rem 0.7rem",
                            ...columnStyles,
                          }}
                        >
                          <Typography fontSize="0.78rem" color={headerColor}>
                            Visit Day Preference
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "none",
                            padding: "0.6rem 0.7rem",
                            ...columnStyles,
                          }}
                        >
                          <Typography fontSize="0.78rem" color={headerColor}>
                            Visit Freq
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            sx={{ border: "none", padding: "0.6rem 0.7rem" }}
                          >
                            <Box
                              height="12px"
                              width="4px"
                              borderRadius="2px"
                              bgcolor={row.color}
                            />
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "none",
                              padding: "0.6rem 0.7rem",
                              fontWeight: "fontWeightSemiBold",
                            }}
                          >
                            {row.customer}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "none",
                              padding: "0.6rem 0.7rem",
                              fontWeight: "fontWeightSemiBold",
                            }}
                          >
                            {row.favoriteFood}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "none",
                              padding: "0.6rem 0.7rem",
                              fontWeight: "fontWeightSemiBold",
                            }}
                          >
                            {row.mealDayPreference}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "none",
                              padding: "0.6rem 0.7rem",
                              fontWeight: "fontWeightSemiBold",
                            }}
                          >
                            {row.visitFreq}
                          </TableCell>
                          <TableCell
                            sx={{
                              border: "none",
                              padding: "0.6rem 0.7rem",
                              fontWeight: "fontWeightSemiBold",
                            }}
                          >
                            {row.age}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CustomPaper>
              <Box
                mt={1}
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
              >
                <Button
                  variant="text"
                  sx={{
                    fontSize: "0.8rem",
                    fontWeight: 700,
                    color: theme.palette.accent,
                    cursor: "pointer",
                    p: 0,
                    textTransform: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  + View More
                </Button>
              </Box>
            </CustomPaper>
          </Stack>
        </Box>
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            gap={1}
            alignItems="center"
            mb={2}
          >
            <Box
              height="12px"
              width="12px"
              borderRadius="4px"
              bgcolor="#50CD89"
            />
            <Typography
              variant="h6"
              color="inherit"
              fontWeight="fontWeightSemiBold"
            >
              Recommendations
            </Typography>
          </Box>
          <Stack gap={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={7}>
                <CustomPaper
                  customStyles={{
                    overflow: "visible",
                    borderLeft: "2px solid #50CD89",
                    height: "100%",
                  }}
                  innerCustomStyles={{
                    border: 0,
                  }}
                >
                  <Typography
                    variant="body1"
                    color="inherit"
                    mb={2}
                    fontWeight="fontWeightSemiBold"
                  >
                    Suggest Marketing Campaigns
                  </Typography>
                  <Stack gap={2}>
                    <CustomPaper
                      customStyles={{
                        background:
                          theme.palette.mode === "dark" ? "#071339" : "#fafafa",
                        border: theme.palette.mode === "dark" ? 0 : 1,
                        borderColor: "#e6e6e6",
                        boxShadow: "unset",
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        columnGap={1}
                        sx={{
                          ":hover .create_action": {
                            visibility: "visible",
                          },
                        }}
                      >
                        <Box>
                          <Typography
                            variant="body1"
                            color="inherit"
                            fontWeight="fontWeightSemiBold"
                          >
                            Run a targeted campaign on Wednesdays for customers
                            who often order seafood.
                          </Typography>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li>
                              <Typography fontSize="0.8rem" color="inherit">
                                Encourage midweek seafood sales by offering a
                                small discount or special menu items to seafood
                                lovers.
                              </Typography>
                            </li>
                          </ul>
                        </Box>
                        <Button
                          variant="text"
                          className="create_action"
                          sx={{
                            color: theme.palette.accent,
                            cursor: "pointer",
                            p: 0,
                            textTransform: "none",
                            minWidth: "9ch",
                            visibility: "hidden",
                            "&:hover": {
                              textDecoration: "underline",
                            },
                          }}
                          onClick={handleCreateCampgain}
                        >
                          Create ➜
                        </Button>
                      </Box>
                    </CustomPaper>
                    <CustomPaper
                      customStyles={{
                        background:
                          theme.palette.mode === "dark" ? "#071339" : "#fafafa",
                        border: theme.palette.mode === "dark" ? 0 : 1,
                        borderColor: "#e6e6e6",
                        boxShadow: "unset",
                      }}
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        columnGap={1}
                        sx={{
                          ":hover .create_action": {
                            visibility: "visible",
                          },
                        }}
                      >
                        <Box>
                          <Typography
                            variant="body1"
                            color="inherit"
                            fontWeight="fontWeightSemiBold"
                          >
                            Promote desserts on weekends to loyal customers who
                            frequently dine on Fridays and Saturdays.
                          </Typography>
                          <ul style={{ paddingLeft: "20px" }}>
                            <li>
                              <Typography fontSize="0.8rem" color="inherit">
                                Boost weekend sales by promoting desserts, with
                                special deals for loyal customers who dine on
                                weekends.
                              </Typography>
                            </li>
                          </ul>
                        </Box>
                        <Button
                          variant="text"
                          className="create_action"
                          sx={{
                            color: theme.palette.accent,
                            cursor: "pointer",
                            p: 0,
                            textTransform: "none",
                            minWidth: "9ch",
                            visibility: "hidden",
                            "&:hover": {
                              textDecoration: "underline",
                            },
                          }}
                          onClick={handleCreateCampgain}
                        >
                          Create ➜
                        </Button>
                      </Box>
                    </CustomPaper>
                  </Stack>
                  <Box
                    mt={1}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Button
                      variant="text"
                      sx={{
                        fontSize: "0.8rem",
                        fontWeight: 700,
                        color: theme.palette.accent,
                        cursor: "pointer",
                        p: 0,
                        textTransform: "none",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      + View More
                    </Button>
                  </Box>
                </CustomPaper>
              </Grid>
              <Grid item xs={12} md={5}>
                <CustomPaper
                  customStyles={{
                    overflow: "visible",
                    borderLeft: "2px solid #50CD89",
                    height: "100%",
                  }}
                  innerCustomStyles={{
                    border: 0,
                  }}
                >
                  <Typography
                    variant="body1"
                    color="inherit"
                    mb={2}
                    fontWeight="fontWeightSemiBold"
                  >
                    Products for Promotions
                  </Typography>
                  <CustomPaper
                    customStyles={{
                      background:
                        theme.palette.mode === "dark" ? "#071339" : "#fafafa",
                      border: theme.palette.mode === "dark" ? 0 : 1,
                      borderColor: "#e6e6e6",
                      boxShadow: "unset",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      flexWrap="wrap"
                      gap={2}
                    >
                      {["CFS Breakfast", "Waffle", "Irish Skillet", "BLT"].map(
                        (item) => (
                          <Stack
                            key={item}
                            maxWidth="235px"
                            sx={{
                              ":hover .create_action": {
                                visibility: "visible",
                              },
                            }}
                            rowGap={1}
                            alignItems="flex-start"
                          >
                            <Typography
                              color="inherit"
                              fontWeight="fontWeightSemiBold"
                            >
                              {item}
                            </Typography>
                            <Typography fontSize="0.8rem" color="inherit">
                              Sales down by 40% due to High price
                            </Typography>
                            <Button
                              variant="text"
                              className="create_action"
                              sx={{
                                color: theme.palette.accent,
                                cursor: "pointer",
                                p: 0,
                                textTransform: "none",
                                minWidth: "9ch",
                                visibility: "hidden",
                                "&:hover": {
                                  textDecoration: "underline",
                                },
                              }}
                              onClick={handleCreateCampgain}
                            >
                              + Create Campaign
                            </Button>
                          </Stack>
                        )
                      )}
                    </Box>
                  </CustomPaper>
                  <Box
                    mt={1}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Button
                      variant="text"
                      sx={{
                        fontSize: "0.8rem",
                        fontWeight: 700,
                        color: theme.palette.accent,
                        cursor: "pointer",
                        p: 0,
                        textTransform: "none",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      + View More
                    </Button>
                  </Box>
                </CustomPaper>
              </Grid>
            </Grid>
          </Stack>
        </Box>

        <Box>
          <Box
            display="flex"
            flexDirection="row"
            gap={1}
            alignItems="center"
            mb={2}
          >
            <Box
              height="12px"
              width="12px"
              borderRadius="4px"
              bgcolor="#FF0000"
            />
            <Typography
              variant="h6"
              color="inherit"
              fontWeight="fontWeightSemiBold"
            >
              Anomalies
            </Typography>
          </Box>

          <Stack gap={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={5}>
                <CustomPaper
                  customStyles={{
                    borderLeft: "2px solid #FF0000",
                    height: "100%",
                  }}
                  innerCustomStyles={{
                    border: 0,
                  }}
                >
                  <Typography
                    variant="body1"
                    color="inherit"
                    mb={2}
                    fontWeight="fontWeightSemiBold"
                  >
                    Product Wastage
                  </Typography>
                  <CustomPaper
                    customStyles={{
                      p: 0,
                      background:
                        theme.palette.mode === "dark" ? "#071339" : "#fafafa",
                      border: theme.palette.mode === "dark" ? 0 : 1,
                      borderColor: "#e6e6e6",
                      boxShadow: "unset",
                    }}
                  >
                    <SimpleTable
                      data={ProductWastage}
                      columnStyles={columnStyles}
                      rowStyle={rowStyle}
                    />
                  </CustomPaper>

                  <Box
                    mt={1}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Button
                      variant="text"
                      sx={{
                        fontSize: "0.8rem",
                        fontWeight: 700,
                        color: theme.palette.accent,
                        cursor: "pointer",
                        p: 0,
                        textTransform: "none",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      + View More
                    </Button>
                  </Box>
                </CustomPaper>
              </Grid>
              <Grid item xs={12} md={7}>
                <CustomPaper
                  customStyles={{
                    borderLeft: "2px solid #FF0000",
                    height: "100%",
                  }}
                  innerCustomStyles={{
                    border: 0,
                  }}
                >
                  <Typography
                    variant="body1"
                    color="inherit"
                    mb={2}
                    fontWeight="fontWeightSemiBold"
                  >
                    Product Returns
                  </Typography>
                  <CustomPaper
                    customStyles={{
                      p: 0,
                      background:
                        theme.palette.mode === "dark" ? "#071339" : "#fafafa",
                      border: theme.palette.mode === "dark" ? 0 : 1,
                      borderColor: "#e6e6e6",
                      boxShadow: "unset",
                    }}
                  >
                    <SimpleTable
                      data={ProductReturns}
                      columnStyles={columnStyles}
                      rowStyle={rowStyle}
                    />
                  </CustomPaper>
                  <Box
                    mt={1}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Button
                      variant="text"
                      sx={{
                        fontSize: "0.8rem",
                        fontWeight: 700,
                        color: theme.palette.accent,
                        cursor: "pointer",
                        p: 0,
                        textTransform: "none",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      + View More
                    </Button>
                  </Box>
                </CustomPaper>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <CustomPaper
                  customStyles={{
                    borderLeft: "2px solid #FF0000",
                    height: "100%",
                  }}
                  innerCustomStyles={{
                    border: 0,
                  }}
                >
                  <Typography
                    variant="body1"
                    color="inherit"
                    mb={2}
                    fontWeight="fontWeightSemiBold"
                  >
                    Unusual Products discount
                  </Typography>
                  <CustomPaper
                    customStyles={{
                      p: 0,
                      background:
                        theme.palette.mode === "dark" ? "#071339" : "#fafafa",
                      border: theme.palette.mode === "dark" ? 0 : 1,
                      borderColor: "#e6e6e6",
                      boxShadow: "unset",
                    }}
                  >
                    <SimpleTable
                      data={UnusualProductsdiscount}
                      columnStyles={columnStyles}
                      rowStyle={rowStyle}
                    />
                  </CustomPaper>
                  <Box
                    mt={1}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Button
                      variant="text"
                      sx={{
                        fontSize: "0.8rem",
                        fontWeight: 700,
                        color: theme.palette.accent,
                        cursor: "pointer",
                        p: 0,
                        textTransform: "none",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      + View More
                    </Button>
                  </Box>
                </CustomPaper>
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomPaper
                  customStyles={{
                    borderLeft: "2px solid #FF0000",
                    height: "100%",
                  }}
                  innerCustomStyles={{
                    border: 0,
                  }}
                >
                  <Typography
                    variant="body1"
                    color="inherit"
                    mb={2}
                    fontWeight="fontWeightSemiBold"
                  >
                    Unusual Days with Reduced or Zero Sales
                  </Typography>
                  <CustomPaper
                    customStyles={{
                      p: 0,

                      background:
                        theme.palette.mode === "dark" ? "#071339" : "#fafafa",
                      border: theme.palette.mode === "dark" ? 0 : 1,
                      borderColor: "#e6e6e6",
                      boxShadow: "unset",
                    }}
                  >
                    <SimpleTable
                      data={UnusualDayswithReducedorZeroSales}
                      columnStyles={columnStyles}
                      rowStyle={rowStyle}
                    />
                  </CustomPaper>
                  <Box
                    mt={1}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Button
                      variant="text"
                      sx={{
                        fontSize: "0.8rem",
                        fontWeight: 700,
                        color: theme.palette.accent,
                        cursor: "pointer",
                        p: 0,
                        textTransform: "none",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      + View More
                    </Button>
                  </Box>
                </CustomPaper>
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomPaper
                  customStyles={{
                    borderLeft: "2px solid #FF0000",
                    height: "100%",
                  }}
                  innerCustomStyles={{
                    border: 0,
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="body1"
                      color="inherit"
                      mb={2}
                      fontWeight="fontWeightSemiBold"
                    >
                      Loyal Customers with Reduced Activity
                    </Typography>
                    <Typography
                      variant="body1"
                      mb={2}
                      sx={{
                        maxHeight: "20px",
                        transform: "rotate(25.5deg)",
                        position: "relative",
                        minWidth: "160px",
                        background:
                          "linear-gradient(97.13deg, #7BC887 20.11%, #186D25 94.09%)",
                        left: 60,
                        bottom: 7,
                        fontSize: "0.8rem",
                        textAlign: "center",
                        color: "#FFF",
                      }}
                    >
                      Loyal
                    </Typography>
                  </Box>
                  <CustomPaper
                    customStyles={{
                      p: 0,
                      background:
                        theme.palette.mode === "dark" ? "#071339" : "#fafafa",
                      border: theme.palette.mode === "dark" ? 0 : 1,
                      borderColor: "#e6e6e6",
                      boxShadow: "unset",
                    }}
                  >
                    <SimpleTable
                      data={LoyalCustomerswithReducedActivity}
                      columnStyles={columnStyles}
                      rowStyle={rowStyle}
                    />
                  </CustomPaper>
                  <Box
                    mt={1}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Button
                      variant="text"
                      sx={{
                        fontSize: "0.8rem",
                        fontWeight: 700,
                        color: theme.palette.accent,
                        cursor: "pointer",
                        p: 0,
                        textTransform: "none",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      + View More
                    </Button>
                  </Box>
                </CustomPaper>
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomPaper
                  customStyles={{
                    borderLeft: "2px solid #FF0000",
                    height: "100%",
                  }}
                  innerCustomStyles={{
                    border: 0,
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Typography
                      variant="body1"
                      color="inherit"
                      mb={2}
                      fontWeight="fontWeightSemiBold"
                    >
                      Premium Customers with Reduced Activity
                    </Typography>
                    <Typography
                      variant="body1"
                      mb={2}
                      sx={{
                        maxHeight: "20px",
                        transform: "rotate(25.5deg)",
                        position: "relative",
                        minWidth: "160px",
                        background:
                          "linear-gradient(97.13deg, #B7992F 20.11%, #BF8F33 94.09%)",
                        left: 60,
                        bottom: 7,
                        fontSize: "0.8rem",
                        textAlign: "center",
                        color: "white",
                      }}
                    >
                      Premium
                    </Typography>
                  </Box>
                  <CustomPaper
                    customStyles={{
                      p: 0,
                      background:
                        theme.palette.mode === "dark" ? "#071339" : "#fafafa",
                      border: theme.palette.mode === "dark" ? 0 : 1,
                      borderColor: "#e6e6e6",
                      boxShadow: "unset",
                    }}
                  >
                    <SimpleTable
                      data={PremiumCustomerswithReducedActivity}
                      columnStyles={columnStyles}
                      rowStyle={rowStyle}
                    />
                  </CustomPaper>
                  <Box
                    mt={1}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    <Button
                      variant="text"
                      sx={{
                        fontSize: "0.8rem",
                        fontWeight: 700,
                        color: theme.palette.accent,
                        cursor: "pointer",
                        p: 0,
                        textTransform: "none",
                        "&:hover": {
                          textDecoration: "underline",
                        },
                      }}
                    >
                      + View More
                    </Button>
                  </Box>
                </CustomPaper>
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Stack>
    </Box>
  )
}

export default BusinessSynopsis
