import { useState } from "react"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import Grid from "@mui/material/Grid"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { useNavigate } from "react-router-dom"
import RouteTitle from "../../components/RouteTitle"
import CustomPaper from "../../components/CustomPaper"
import KPICard from "../../components/KPICard_re"
import Table from "../../components/datagrid/Table"
import { useTheme } from "../../contexts/theme"
import BlurEffect from "../../components/BlurEffect"

const Management = () => {
  const navigate = useNavigate()

  const orderDetails = [
    {
      title: "Total Customers",
      currentValue: 784.8,
      previousValue: 368.3,
      format: "",
      star: false,
      changePercentage: 113.31, // (784.8 - 368.3) / 368.3 * 100
    },
    {
      title: "Total Employees",
      currentValue: 2063.0,
      previousValue: 117.5,
      format: "",
      star: false,
      changePercentage: 1656.60, // (2063.0 - 117.5) / 117.5 * 100
    },
    {
      title: "Total Profit",
      currentValue: 72.1,
      previousValue: 22.9,
      format: "Currency",
      star: false,
      changePercentage: 214.84, // (72.1 - 22.9) / 22.9 * 100
    },
    {
      title: "Total Sale",
      currentValue: 23.33,
      previousValue: 12.22,
      format: "Currency",
      star: false,
      changePercentage: 90.89, // (23.33 - 12.22) / 12.22 * 100
    },
    {
      title: "Emp Turnover per Year",
      currentValue: 19.2,
      previousValue: 15.6,
      format: "",
      star: false,
      changePercentage: 23.08, // (19.2 - 15.6) / 15.6 * 100
    },
    {
      title: "Revenue per Employee",
      currentValue: 20.0,
      previousValue: 89.76,
      format: "Currency",
      star: false,
      changePercentage: -77.74, // (20.0 - 89.76) / 89.76 * 100
    },
  ]

  const [activeTab, setActiveTab] = useState(0)

  const { theme } = useTheme()

  const palette = theme.palette

  const tabStyles = {
    textTransform: "none",
    color: "inherit",
    "&:hover": {
      color: palette.accent,
    },
    "&.Mui-selected": {
      color: palette.accent,
    },
  }

  const handleTabChange = (event, newActiveTab) => {
    setActiveTab(newActiveTab)
  }

  const handleCreateUser = () => {
    navigate("/management/users/create")
  }

  const handleUserRowClick = () => {
    navigate("/management/users/user/1")
  }

  const handleCreateGroup = () => {
    navigate("/management/groups/create")
  }

  const handleGroupRowClick = () => {
    navigate("/management/groups/group/1")
  }

  const renderTableContent = (activeTab) => {
    const tableProps = [
      {
        title: "Users",
        onRowClick: handleUserRowClick,
        showButton: handleCreateUser,
        showDropdown: true,
        dropdownOptions: ["Option 1", "Option 2", "Option 3"],
        buttonName: "+ Add User",
      },
      {
        title: "Groups",
        onRowClick: handleGroupRowClick,
        showButton: handleCreateGroup,
        buttonName: "+ Create Group",
      },
    ][activeTab]

    return <Table {...tableProps} />
  }

  return (
    <>
      <BlurEffect showBlur={true} />
      <RouteTitle title="Management" />
      <Stack padding="15px 30px 15px 30px" gap="20px">
        <CustomPaper
          customStyles={{
            backgroundColor: palette.mode === "dark" ? "#000" : "#E9EDFC",
          }}
          watermarkBg={true}
        >
          <Typography variant="subtitle2" color="inherit" mb="10px">
            Order Details of 2023
          </Typography>
          <Grid container spacing={2} justifyContent="space-evenly">
            {orderDetails.map((orderDetail, index) => (
              <Grid item xs={12} sm={6} md={4} lg={2} key={index}>
                <CustomPaper width="100%" watermarkBg={true}>
                  <KPICard
                    title={orderDetail.title}
                    description=""
                    postUrl="management/data"
                    route="management"
                    setChartData={null}
                    reportUid="external"
                    configurations={null}
                    extApiResponse={{
                      "reportDataCurrent": [
                        {
                          "value": orderDetail.currentValue
                        }
                      ],
                      "reportDataPrevious": [
                        {
                          "value": orderDetail.previousValue
                        }
                      ],
                      changePercentage: orderDetail.changePercentage
                    }}
                    extLoading={false}
                    extError={null}
                    extComparisonPeriod={true}
                  />
                </CustomPaper>
              </Grid>
            ))}
          </Grid>
        </CustomPaper>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="management tabs"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: palette.accent,
            },
          }}
        >
          <Tab label="User Management" sx={tabStyles} />
          <Tab label="Group Management" sx={tabStyles} />
        </Tabs>
        {renderTableContent(activeTab)}
      </Stack>
    </>
  )
}

export default Management
