import Box from "@mui/system/Box"
import Stack from "@mui/system/Stack"
import Divider from "@mui/material/Divider"
import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import { useNavigate, useParams } from "react-router"
import CustomPaper from "../../components/CustomPaper"
import { useTheme } from "../../contexts/theme"
import CustomBreadcrumbs from "../../components/CustomBreadcrumbs"
import { setChartData } from "../../redux/slices/customerSlice"
import { useSelector } from "react-redux"
// import { useLocation } from "react-router-dom"
import { CustomerBarChartParameters } from "../../constants/CustomerBarChartParameters"
import ArrowRise from "../../assets/svgs/ArrowRise"
import ProgressBarContainer from "../../components/charts/progressBar/ProgressBarContainer"
import CalendarContainer from "../../components/charts/calendar/CalendarContainer"
import { useEffect, useState } from "react"
import { postData } from "../../api/api"
import Message from "../../components/Message"
import CompareBy from "../../components/CompareBy"
import DatePicker from "../../components/DatePicker"

const CustomerDetails = () => {
  // const { theme } = useTheme()

  // Can get id from route - useParams() or from useNavigate's params - useLocation()
  // const location = useLocation()
  // const { id } = location.state || {}
  let { id } = useParams()

  const [personalDetailsLoading, setPersonalDetailsLoading] = useState(false)
  const [personalDetailsError, setPersonalDetailsError] = useState(null)
  const [personalDetails, setPersonalDetails] = useState([])
  const dateRangeData = useSelector((state) => state.calendar)

  const fetchPersonalDetails = async (id) => {
    setPersonalDetailsLoading(true)
    try {
      let fetchedPersonalDetails = await postData("/customer/customerDetail", {
        entityDb: localStorage.getItem("entityDb"),
        customerId: id,
      })

      fetchedPersonalDetails = Object.entries(fetchedPersonalDetails)

      setPersonalDetails(fetchedPersonalDetails)
    } catch (error) {
      setPersonalDetailsError(error.message)
      console.log(error)
    } finally {
      setPersonalDetailsLoading(false)
    }
  }

  const [transactionalDetailsLoading, setTransactionalDetailsLoading] =
    useState(false)
  const [transactionalDetailsError, setTransactionalDetailsError] =
    useState(null)
  const [transactionalDetails, setTransactionalDetails] = useState([])

  const fetchTransactionalDetails = async (id) => {
    setTransactionalDetailsLoading(true)
    try {
      let fetchedTransactionalDetails = await postData(
        "/customer/transactionalInsights",
        {
          entityDb: localStorage.getItem("entityDb"),
          customerId: id,
        }
      )

      fetchedTransactionalDetails = Object.entries(fetchedTransactionalDetails)

      setTransactionalDetails(fetchedTransactionalDetails)
    } catch (error) {
      console.log(error)
      setTransactionalDetailsError(error.message)
    } finally {
      setTransactionalDetailsLoading(false)
    }
  }

  const [topProductsLoading, setTopProductsLoading] = useState(false)
  const [topProductsError, setTopProductsError] = useState(null)
  const [topProducts, setTopProducts] = useState([])

  const fetchTopProducts = async (id) => {
    setTopProductsLoading(true)
    try {
      let fetchedTopProducts = await postData("/customer/top10products", {
        entityDb: localStorage.getItem("entityDb"),
        customerId: id,
      })
      setTopProducts(fetchedTopProducts)
    } catch (error) {
      console.log(error)
      setTopProductsError(error.message)
    } finally {
      setTopProductsLoading(false)
    }
  }

  const [historicalVisitLoading, setHistoricalVisitLoading] = useState(false)
  const [historicalVisitError, setHistoricalVisitError] = useState(null)
  const [historicalVisitData, setHistoricalVisitData] = useState([])

  const fetchHistoricalVisitData = async (id) => {
    setHistoricalVisitLoading(true)
    try {
      let fechedHistoricalVisitData = await postData(
        "/customer/historicalVisits",
        {
          entityDb: localStorage.getItem("entityDb"),
          customerId: id,
          fromDate: "20220101",
          toDate: "20241231",
        }
      )
      setHistoricalVisitData(fechedHistoricalVisitData)
    } catch (error) {
      console.log(error)
      setHistoricalVisitError(error.message)
    } finally {
      setHistoricalVisitLoading(false)
    }
  }

  useEffect(() => window.scrollTo(0, 0), [])

  useEffect(() => {
    fetchPersonalDetails(id)
    fetchTransactionalDetails(id)
    fetchHistoricalVisitData(id)
    fetchTopProducts(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dateRangeData])

  // const ThemeMode = theme.palette.mode
  const navigate = useNavigate()

  const comparisonPeriod = useSelector((state) => state.period.selectedValue)

  // let item
  // const [open, setOpen] = useState(false)

  // const deleteRecord = (id = 0) => {
  //   setOpen(false)
  // }

  // const handleDelete = (event, id) => {
  //   event.stopPropagation()
  //   setOpen(true)
  // }

  // const handleEdit = () => {
  //   navigate(``)
  // }
  // const handleToggle = (value) => {
  //   setSelectedOption(value)
  // }
  // const [selectedOption, setSelectedOption] = useState("revenue")

  // const personalInfo = [
  //   {
  //     title: "Left Column",
  //     data: [
  //       { label: "Name", value: item?.title || "Dianne Russle" },
  //       {
  //         label: "Apartment / Suite Number",
  //         value: item?.aptSuitNo || "Apt 4B",
  //       },
  //       {
  //         label: "Last Visited On",
  //         value: item?.lastVisitedOn || "16th March 2024",
  //         style: { color: "rgba(80, 205, 137, 1)" },
  //       },
  //     ],
  //   },
  //   {
  //     title: "Center Column",
  //     data: [
  //       { label: "Phone Number", value: item?.phoneNumber || "97399321" },
  //       { label: "City", value: item?.city || "Springfield" },
  //       {
  //         label: "Total No. of items bought",
  //         value: item?.totalNoOfItemsBought || "37",
  //       },
  //     ],
  //   },
  //   {
  //     title: "Center2 Column",
  //     data: [
  //       {
  //         label: "Email Address",
  //         value: item?.email || "abc.qqqq@livelytics.ai",
  //       },
  //       { label: "State", value: item?.state || "IL" },
  //     ],
  //   },
  //   {
  //     title: "Right Column",
  //     data: [
  //       {
  //         label: "Street Address",
  //         value: item?.streetAddress || "123 Main Street",
  //       },
  //       { label: "Zip Code", value: item?.zip || "440012" },
  //     ],
  //   },
  // ]

  // const dataSections = [
  //   {
  //     label: "Overall Rating",
  //     value: item?.rating || "4.3",
  //     icon: (
  //       <svg
  //         width="28"
  //         height="26"
  //         viewBox="0 0 28 26"
  //         fill="none"
  //         xmlns="http://www.w3.org/2000/svg"
  //         style={{ marginTop: "5px" }}
  //       >
  //         <path
  //           d="M13.0489 0.927047C13.3483 0.00573683 14.6517 0.00573993 14.9511 0.927051L17.3677 8.36475C17.5016 8.77677 17.8855 9.05573 18.3188 9.05573H26.1392C27.1079 9.05573 27.5107 10.2953 26.727 10.8647L20.4001 15.4615C20.0496 15.7161 19.903 16.1675 20.0369 16.5795L22.4535 24.0172C22.7529 24.9385 21.6984 25.7047 20.9147 25.1353L14.5878 20.5385C14.2373 20.2839 13.7627 20.2839 13.4122 20.5385L7.08533 25.1353C6.30162 25.7047 5.24714 24.9385 5.54649 24.0172L7.96315 16.5795C8.09702 16.1675 7.95036 15.7161 7.59987 15.4615L1.27299 10.8647C0.489277 10.2953 0.892056 9.05573 1.86078 9.05573H9.68123C10.1145 9.05573 10.4984 8.77677 10.6323 8.36475L13.0489 0.927047Z"
  //           fill="#F2CD4A"
  //         />
  //       </svg>
  //     ),
  //   },
  //   {
  //     label: "Amount Spent",
  //     value: `${item?.amountSpent || "$3,0546.43"}`,
  //     extra: (
  //       <>
  //         <span
  //           style={{
  //             marginLeft: "10px",
  //             fontSize: "18px",
  //             color:
  //               ThemeMode === "dark"
  //                 ? "rgba(255, 255, 255, 1)"
  //                 : "rgba(0, 0, 0, 1)",
  //           }}
  //         >
  //           {item?.rate || "+11.01%"}
  //         </span>
  //         <ArrowRise />
  //       </>
  //     ),
  //   },
  //   {
  //     label: "Average Tickets",
  //     value: item?.mostBoughtItem || "$489",
  //   },
  //   {
  //     label: "Total Visits",
  //     value: item?.totalVisits || "26",
  //   },
  //   {
  //     label: "Visit Frequency",
  //     value: item?.totalSales || "$18.90",
  //   },
  //   {
  //     label: "Order Type",
  //     value: item?.averageSales || "Takeout",
  //   },
  //   {
  //     label: "Most Order Item",
  //     value: item?.allTimeSpend || "$1.56",
  //   },
  //   {
  //     label: "Most Ordered Combos",
  //     value: item?.allTimeAverageSpend || "$34.56",
  //   },
  //   {
  //     label: "Sentiment",
  //     value: item?.allTimeVisits || "positive",
  //   },
  //   {
  //     label: "CLTV",
  //     value: item?.allTimeVisits || "26",
  //   },
  //   {
  //     label: "Last Visit Date",
  //     value: item?.allTimeVisits || "26-Jan",
  //   },
  //   {
  //     label: "Preferred Days of Visit",
  //     value: item?.allTimeVisits || "26",
  //   },
  //   // {
  //   //   label: "Comments",
  //   //   value:
  //   //     item?.comment ||
  //   //     (item?.title === "Charles Ross"
  //   //       ? "The dining experience was exceptional!..."
  //   //       : item?.title === "Anthony Perry"
  //   //       ? "Outstanding service and delicious food..."
  //   //       : item?.title === "Brian Jenkins"
  //   //       ? "The food was good, and the service was friendly..."
  //   //       : "The Service was impeccable. The staff was attentive, friendly..."),
  //   // },
  // ]

  return (
    <Box position="relative">
      {/* <DeleteModal
        open={open}
        onCancel={() => setOpen(false)}
        onDelete={() => deleteRecord()}
      /> */}
      {/* <BlurEffect showBlur={false} /> */}
      <Box padding="30px 30px 15px 30px">
        <CustomBreadcrumbs
          breadcrumbItems={[
            {
              text: "All Customers",
              // isActive: false,
              clickHandler: () => {
                navigate("/customers/allcustomers")
              },
            },
            {
              text: "Customers Details",
              isActive: true,
            },
          ]}
        />

        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          paddingY="15px"
        >
          <Typography component="div" variant="subtitle1">
            Customers Details
          </Typography>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
            maxWidth="auto"
            gap={2}
          >
            <CompareBy dateRangeData={dateRangeData} />
            <DatePicker />
          </Box>
        </Box>

        <CustomPaper
          className="user-grid-content1 grid-col-span-4 "
          customStyles={{
            mb: "20px",
          }}
        >
          <Box>
            {/* Personal Information */}
            <Typography
              component="div"
              variant="subtitle2"
              sx={{
                textAlign: "left",
                pb: "10px",
              }}
            >
              Personal Information
            </Typography>
            <Divider
              style={{
                width: "100%",
                border: "1px solid rgba(9, 23, 67, 1)",
              }}
            />

            {/* Personal Information Details */}
            {personalDetailsLoading ? (
              <Message type="loading" />
            ) : personalDetailsError ? (
              <Message message={personalDetailsError} type="error" />
            ) : (
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                gap="45px"
                mt={2}
              >
                {personalDetails?.map(([key, value]) => (
                  <Stack
                    key={key}
                    rowGap="5px"
                    minWidth={{
                      sx: "200px",
                      sm: "200px",
                      md: "200px",
                      lg: "280px",
                    }}
                  >
                    <Typography variant="subtitle2" sx={{ opacity: 0.7 }}>
                      {key}
                    </Typography>
                    <Typography
                      color="inherit"
                      sx={{
                        fontSize: "18px",
                      }}
                    >
                      {value || "No records"}
                    </Typography>
                  </Stack>
                ))}
              </Box>
            )}
            {/* <Stack
              direction="row"
              justifyContent="space-between"
              className="custom-stack"
            >
              {personalInfo.map((column, columnIndex) => (
                <Stack
                  key={columnIndex}
                  className="personal-info-column"
                  style={{ textAlign: "left" }}
                >
                  {column.data.map((field, fieldIndex) => (
                    <>
                      <Typography
                        component="div"
                        variant="subtitle2"
                        sx={{ opacity: 0.7,, mt: 2 }}
                      >
                        {field.label}
                      </Typography>
                      <Typography
                        component="div"
                        sx={{
                          mt: 0.5,
                          fontSize: "18px",
                          ...field.style, // Apply custom styles if provided
                        }}
                      >
                        {field.value}
                      </Typography>
                    </>
                  ))}
                </Stack>
              ))}
            </Stack> */}
          </Box>
        </CustomPaper>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6} sx={{ height: "100%" }}>
            <CustomPaper customStyles={{ background: "#1b4cf00a" }}>
              <Typography sx={{ mb: 1 }}>Transactional Insights</Typography>
              <Grid container spacing={2}>
                {transactionalDetailsLoading ? (
                  <Message type="loading" />
                ) : transactionalDetailsError ? (
                  <Message message={transactionalDetailsError} type="error" />
                ) : (
                  transactionalDetails?.map(([key, value]) => (
                    <Grid item xs={12} md={6} sx={{ height: "100%" }}>
                      <CustomPaper customStyles={{ height: "100%" }}>
                        <Stack
                          key={key}
                          rowGap="5px"
                          minWidth={{
                            sx: "200px",
                            sm: "200px",
                            md: "200px",
                            lg: "280px",
                          }}
                        >
                          <Typography
                            className="KPICardhoverEffect"
                            variant="subtitle2"
                            sx={{ opacity: 0.7 }}
                          >
                            {key}
                          </Typography>
                          {[
                            "Visit Frequency",
                            "Monthly Spend",
                            "Average Ticket Size Spend",
                          ].includes(key) ? (
                            <Typography
                              className="KPICardhoverEffect"
                              color={
                                {
                                  "-1": "#FF0000",
                                  1: "#50CD89",
                                }[value] || "inherit"
                              }
                              sx={{
                                fontSize: "18px",
                              }}
                            >
                              {{
                                "-1": "Decreased",
                                1: "Increased",
                                0: "Same",
                              }[value] || value}
                            </Typography>
                          ) : (
                            <Typography
                              className="KPICardhoverEffect"
                              color="inherit"
                              sx={{
                                fontSize: "18px",
                              }}
                            >
                              {value || "No records"}
                            </Typography>
                          )}
                        </Stack>
                      </CustomPaper>
                    </Grid>
                  ))
                )}

                {/* {dataSections.map((section, index) => (
                  <Grid key={index} item xs={12} md={6} sx={{ height: "100%" }}>
                    <CustomPaper customStyles={{ height: "100%" }}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          color:
                            ThemeMode === "dark"
                              ? "rgba(179, 179, 179, 1)"
                              : "rgba(115, 115, 115, 1)",
                          textAlign: "left",
                        }}
                      >
                        {section.label}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            color:
                              ThemeMode === "dark"
                                ? "rgba(255, 255, 255, 1)"
                                : "rgba(0, 0, 0, 1)",
                            textAlign: "left",
                            fontSize: "1.3rem",
                          }}
                        >
                          {section.value}
                        </Typography>
                        {section.icon && section.icon}
                        {section.extra && section.extra}
                      </Box>
                    </CustomPaper>
                  </Grid>
                ))} */}
              </Grid>
            </CustomPaper>
          </Grid>

          <Grid item xs={12} md={6} sx={{ height: "100%" }}>
            <CustomPaper
              innerCustomStyles={{
                border: 0,
              }}
            >
              <ProgressBarContainer
                title="Top 10 products bought"
                description="Top 10 products bought by this customer."
                route="inventory"
                setChartData={setChartData}
                // comparisonPeriod={comparisonPeriod}
                postUrl="inventory/data"
                reportUid="external"
                extApiResponse={topProducts}
                extLoading={topProductsLoading}
                extError={topProductsError}
                configurations={[
                  {
                    parameterType: "POINTS",
                    reportParameters: [
                      {
                        parameterName: "fieldMap",
                        parameterValue: {
                          name: "Product Name",
                          key: "Order Count",
                        },
                      },
                      {
                        parameterName: "enableNavigation",
                        parameterValue: "false",
                      },
                      {
                        parameterName: "showBubble",
                        parameterValue: "true",
                      },
                      {
                        parameterName: "showTooltipPrevious",
                        parameterValue: "false",
                      },
                      {
                        parameterName: "tooltip_data",
                        parameterValue: [
                          { name: "Product Name", format: false, color: "" },
                          { name: "Order Count", format: "Number", color: "" },
                        ],
                      },
                    ],
                  },
                ]}
              />
            </CustomPaper>
          </Grid>
        </Grid>

        <CustomPaper key={1} customStyles={{ overflow: "visible", mt: "1rem" }}>
          <CalendarContainer
            route="customerDetails"
            setChartData={setChartData}
            title="Historical Visits"
            description="Historical Visits"
            postUrl="customer/data"
            reportUid="external"
            extApiResponse={historicalVisitData}
            extLoading={historicalVisitLoading}
            extError={historicalVisitError}
          />
        </CustomPaper>
      </Box>
    </Box>
  )
}

export default CustomerDetails
