import { useState, useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import extractKeyValuePairs from "../../../utils/extractKeyValuePairs"
import { LineChartData, LineChartParameters } from "./constant/configFallback"
import { Stack } from "@mui/material"
import { generateCacheKey } from "../../../utils/utils"
import HeaderSection from "./components/widget/HeaderSection"
import Message from "../../Message"
import BodySection from "./components/widget/BodySection"
import FooterSection from "./components/widget/FooterSection"

const LineChartContainer = ({
  title,
  description,
  route,
  setChartData,
  postUrl,
  reportUid,
  configurations,
}) => {
  const chartRef = useRef(null)

  const dateRangeData = useSelector((state) => state.calendar)
  const comparisonPeriod = useSelector((state) => state.period.selectedValue)
  let transformedConfigs = extractKeyValuePairs(configurations) || {}

  let reportState =
    useSelector((state) => {
      return state[route]?.reports?.find(
        (report) => report.report_uid === reportUid
      )
    }) || {}

  const cacheKey = generateCacheKey(
    reportUid,
    dateRangeData.fromDate,
    dateRangeData.toDate,
    comparisonPeriod.previousFromDate,
    comparisonPeriod.previousToDate,
    reportState.viewBy
  )

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  return (
    <>
      <Stack spacing={1} ref={chartRef}>
        <HeaderSection
          route={route}
          reportUid={reportUid}
          title={title}
          description={description}
          setChartData={setChartData}
          children={null}
          config={transformedConfigs}
          reportStateViewBy={reportState.viewBy}
          dateRangeData={dateRangeData}
          parentLoading={loading}
          parentError={error}
          transformedChartData={reportState.transformedChartData}
          apiResponse={reportState.apiResponse}
          chartRef={chartRef}
          pdfFields={transformedConfigs.pdfFields}
        />

        {loading ? (
          <Message type="loading" />
        ) : error ? (
          <Message message={error} type="error" />
        ) : (
          <>
            <BodySection config={LineChartParameters} data={LineChartData} />
            <FooterSection
              // legendsData={LegendData(reportState)}
              title={title}
              apiResponse={reportState.apiResponse}
            />
          </>
        )}
      </Stack>
    </>
  )
}

export default LineChartContainer
